import React, { useMemo } from 'react'
import { Navigate, Outlet, Location, useLocation } from 'react-router-dom'

import {
  H5PContextProvider,
  SupportContextProvider,
  FeedbackContextProvider,
  DoctorateContextProvider,
  CreateCourseContextProvider,
  QuizContextProvider,
} from '@/context'
import {
  adminPaths,
  studentPaths,
  proctorPaths,
  affiliateOwnerPaths,
} from '@/utils'
import { useAuth } from '@/hooks'

export const PermissionWrapper: React.FC = () => {
  const { me, isAdmin, isSchoolOwner, isProctor } = useAuth()
  const location: Location = useLocation()

  const paths = useMemo(() => {
    return isAdmin
      ? adminPaths
      : isSchoolOwner
        ? affiliateOwnerPaths
        : isProctor
          ? proctorPaths
          : !!me && !isAdmin && !isSchoolOwner
            ? studentPaths
            : []
  }, [isAdmin, me, isSchoolOwner, isProctor])

  if (me?.role === undefined) return null

  if (!paths.includes(location.pathname)) {
    return <Navigate to={paths[0]} state={{ from: location }} replace />
  }

  return (
    <SupportContextProvider>
      <DoctorateContextProvider>
        <FeedbackContextProvider>
          <H5PContextProvider>
            <QuizContextProvider>
              <CreateCourseContextProvider>
                <Outlet />
              </CreateCourseContextProvider>
            </QuizContextProvider>
          </H5PContextProvider>
        </FeedbackContextProvider>
      </DoctorateContextProvider>
    </SupportContextProvider>
  )
}
