import React from 'react'

import { useCourse } from '@/hooks'

import { PlanPreviewSchema } from '../shared'

export const CoursePreviewPage: React.FC = () => {
  const { course } = useCourse()

  return <PlanPreviewSchema plan={course} />
}
