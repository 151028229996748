import React from 'react'

import { IPlan, TFunc } from '@/types'

export const CourseRow: React.FC<IPlan & { startCourse: TFunc }> = ({
  _id,
  title,
  price,
  description,
  startCourse,
}) => {
  return (
    <tr onClick={startCourse} className="cursor-pointer hover:bg-gray-100">
      <td className="py-2 px-4 border-b text-start">{_id}</td>
      <td className="py-2 px-4 border-b text-start">{title}</td>
      <td className="py-2 px-4 border-b text-start">{description}</td>
      <td className="py-2 px-4 border-b text-start">${price}</td>
    </tr>
  )
}
