import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { IAffiliateBrand, Templates } from '@/types'
import { editTemplatePath } from '@/utils'
import { RadioButton } from '@/components'
import { useChooseTemplate } from '@/apis'
import { useToast } from '@/hooks'

import { TemplatePreview } from './template'
import { templates } from '../constants'

interface ITemplateTabsProps {
  brand?: IAffiliateBrand
}

export const TemplateTabs: React.FC<ITemplateTabsProps> = ({ brand }) => {
  const navigate = useNavigate()
  const { addToast } = useToast()
  const [template, setTemplate] = useState<Templates>(
    Templates.DEFAULT_TEMPLATE,
  )

  useEffect(() => {
    if (brand) {
      setTemplate(brand.defaultBrand)
    }
  }, [brand])

  const { mutateAsync: chooseTemplate } = useChooseTemplate()

  const onChooseTemplate = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const response = await chooseTemplate({
          template: e.target.value as Templates,
        })
        if (response.data) {
          setTemplate(response.data.defaultBrand)
          addToast('success', `Default template updated`)
        }
      } catch (error) {
        // @ts-ignore
        addToast('error', error?.message ?? 'Something went wrong')
      }
    },
    [addToast, chooseTemplate],
  )

  return (
    <div className="bg-white p-8 rounded-md shadow w-full">
      <div className="flex mb-4 template-container">
        <RadioButton
          label="Template"
          value={template}
          options={templates.map(template => ({
            value: template.value,
            label: (
              <TemplatePreview
                {...template}
                onEdit={() =>
                  navigate(`${editTemplatePath}?slug=${template.value}`)
                }
              />
            ),
          }))}
          onChange={onChooseTemplate}
        />
      </div>
    </div>
  )
}
