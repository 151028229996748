import React, { useMemo, useEffect, useCallback, useState, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SubmitHandler } from 'react-hook-form'

import { Templates, IAffiliateBrand, TEditTemplateSchema } from '@/types'
import { useMyBrand, useUpdateAffiliateBrand } from '@/apis'
import { Button, FieldArray, Form } from '@/components'
import { getFile, templateSchema } from '@/utils'
import { useToast, useHookForm } from '@/hooks'

import { formatSections, formatSocials, Section } from './utils'
import { sectionTypesData } from './constants'
import { EditHeroSection } from './components'

export const EditTemplatePage: React.FC = () => {
  const mounted = useRef(false)
  const isLoaded = useRef(false)
  const navigate = useNavigate()
  const { addToast } = useToast()
  const [affiliateBrand, setAffiliateBrand] = useState<IAffiliateBrand>()

  const onBrandSuccess = useCallback((_data: IAffiliateBrand) => {
    setAffiliateBrand(_data)
  }, [])

  const [getBrand] = useMyBrand(onBrandSuccess)

  const { mutateAsync: updateBrand } = useUpdateAffiliateBrand()

  useEffect(() => {
    if (!isLoaded.current) {
      isLoaded.current = true
      getBrand()
    }
  }, [getBrand])

  const [searchParams] = useSearchParams()

  const slug = searchParams.get('slug') as Templates | null

  const {
    handler: {
      reset,
      control,
      trigger,
      register,
      setValue,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(templateSchema)

  useEffect(() => {
    if (
      !slug ||
      (slug !== Templates.DEFAULT_TEMPLATE && slug !== Templates.TEMPLATE_1)
    ) {
      navigate(-1)
    }
  }, [navigate, slug])

  useEffect(() => {
    return () => {
      mounted.current = false
      isLoaded.current = false
    }
  }, [])

  const sectionTypes = useMemo(
    () => (slug ? sectionTypesData[slug] : []),
    [slug],
  )

  const template = useMemo(
    () => affiliateBrand?.templates?.find(_v => _v.name === slug),
    [affiliateBrand, slug],
  )

  useEffect(() => {
    if (!template) return
    if (!mounted.current) {
      mounted.current = true
      reset({
        id: template?._id,
        name: template?.name,
        title: template?.title,
        description: template?.description,
        image: {
          file: undefined,
          path: template?.image ? getFile(template.image) : undefined,
        },
        socials: formatSocials(template),
        mainVideo: template?.mainVideo ?? '',
        sections: formatSections(sectionTypes, template),
      })
    }
  }, [reset, template, sectionTypes])

  const onUpload = useCallback(
    (field: string) => (file: File, path?: string) => {
      // @ts-ignore
      setValue(`${field}.file`, file)
      // @ts-ignore
      setValue(`${field}.path`, path)
      // @ts-ignore
      trigger(`${field}`)
    },
    [setValue, trigger],
  )

  const onSubmit: SubmitHandler<TEditTemplateSchema> = useCallback(
    async data => {
      const formData = new FormData()

      formData.append('template[id]', data.id)
      formData.append('template[name]', data.name)
      formData.append('template[title]', data.title)
      formData.append('template[description]', data.description)

      formData.append('template[mainVideo]', data.mainVideo ?? '')

      if (data?.image?.file) {
        formData.append('template[image]', data.image.file as File)
      }

      if (!!affiliateBrand) {
        formData.append('logo', affiliateBrand.logo)
        formData.append('fontFamily', affiliateBrand.fontFamily)
        formData.append('defaultBrand', affiliateBrand.defaultBrand)
        formData.append('color', JSON.stringify(affiliateBrand?.color))
      }

      data?.socials?.forEach((social, index) => {
        formData.append(`template[socials][${index}][type]`, social.type)
        formData.append(`template[socials][${index}][link]`, social?.link ?? '')
      })

      data?.sections?.forEach((section, index) => {
        if (section?.type) {
          formData.append(`template[sections][${index}][type]`, section.type)
        }
        if (section?.image?.file) {
          formData.append(
            `template[sections][${index}][image]`,
            // @ts-ignore
            section.image.file,
          )
        }
        if (section.title) {
          formData.append(`template[sections][${index}][title]`, section.title)
        }
        if (section.description) {
          formData.append(
            `template[sections][${index}][description]`,
            section.description,
          )
        }

        if (section.videoURL) {
          formData.append(
            `template[sections][${index}][videoURL]`,
            section.videoURL,
          )
        }
        section?.blocks?.forEach((block, _index) => {
          if (block.title) {
            formData.append(
              `template[sections][${index}][blocks][${_index}][title]`,
              block.title,
            )
          }
          if (block.description) {
            formData.append(
              `template[sections][${index}][blocks][${_index}][description]`,
              block.description,
            )
          }
          if (block?.image?.file) {
            formData.append(
              `template[sections][${index}][blocks][${_index}][image]`,
              block.image.file as File,
            )
          }

          if (!!block?.block?.title) {
            formData.append(
              `template[sections][${index}][blocks][${_index}][block][title]`,
              block.block.title,
            )
          }
          if (!!block?.block?.description) {
            formData.append(
              `template[sections][${index}][blocks][${_index}][block][description]`,
              block.block.description,
            )
          }
        })
      })

      try {
        const response = await updateBrand(formData)

        if (response.data.success) {
          addToast('success', 'The brand was updated successfully.')
          navigate(-1)
        }
      } catch (error) {
        // @ts-ignore
        addToast('error', error?.message ?? 'Something went wrong')
      }
    },
    [addToast, affiliateBrand, navigate, updateBrand],
  )

  return (
    <div className="p-4 w-full">
      <Button
        onClick={() => navigate(-1)}
        className="text-gray-500 hover:text-gray-800 mb-4 inline-flex items-center"
      >
        <i className="fi fi-br-arrow-left h-5 mr-3"></i>
        Back
      </Button>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-3xl font-semibold mb-4">Edit Template</h2>
      </div>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white p-8 rounded-md shadow w-full"
      >
        <EditHeroSection
          errors={errors}
          // @ts-ignore
          control={control}
          sectionIndex={0}
          // @ts-ignore
          register={register}
          onUpload={onUpload}
          // @ts-ignore
          setValue={setValue}
          // @ts-ignore
          getValues={getValues}
        />
        <FieldArray
          name="sections"
          control={control}
          children={_fields => (
            <>
              {_fields.map((field, index) => {
                const Component = Section(getValues(`sections.${index}.type`))
                if (!Component) return null
                return (
                  <div className="mt-5" key={field.id}>
                    <Component
                      errors={errors}
                      // @ts-ignore
                      control={control}
                      // @ts-ignore
                      register={register}
                      onUpload={onUpload}
                      // @ts-ignore
                      setValue={setValue}
                      sectionIndex={index}
                      // @ts-ignore
                      getValues={getValues}
                    />
                  </div>
                )
              })}
            </>
          )}
        />
        <Button
          type="submit"
          className="mt-5 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
        >
          Save
        </Button>
      </Form>
    </div>
  )
}
