import React, { useMemo } from 'react'

import { getFile, ourMission } from '@/utils'
import { SectionTypes } from '@/types'
import { useBrand } from '@/hooks'

import { ourMissionPage } from './constants'

export const DefaultOurMissionSection: React.FC = () => {
  const { template } = useBrand()

  const ourMissionSection = useMemo(
    () => template?.sections?.find(_v => _v.type === SectionTypes.OUR_MISSION),
    [template?.sections],
  )

  const { title, description, blocks, image } = useMemo(
    () => ({
      title: ourMissionSection?.title ?? ourMissionPage.title,
      description:
        ourMissionSection?.description ?? ourMissionPage?.description,
      blocks: ourMissionSection?.blocks ?? ourMissionPage.blocks,
      image: getFile(ourMissionSection?.image) ?? ourMissionPage.image,
    }),
    [ourMissionSection],
  )

  return (
    <section className="every-learner" id={ourMission}>
      <div className="w-full container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div>
            <img
              src={image}
              alt="Student learning with headphones"
              className="rounded-lg shadow-lg object-cover w-full h-auto"
            />
          </div>
          <div>
            <span className="inline-block bg-[#EAF0F5] text-[#193450] text-[16px] font-bold px-3 py-1 rounded-full">
              Our mission
            </span>
            <h1 className="mt-4 text-[48px] md:text-4xl font-bold text-gray-900">
              {title}
            </h1>
            <p className="mt-4 text-[#454F5B]">{description}</p>
            <div className="mt-6 flex space-x-4">
              {blocks?.map((_block, _idx) => (
                <div className="w-1/2" key={_idx}>
                  <h2 className="text-3xl font-bold text-[#3168A0]">
                    {_block.title}
                  </h2>
                  <p className="mt-2 text-[#212B36]">{_block.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
