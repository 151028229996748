import React, { useCallback, useRef, useState } from 'react'
import { CheckIcon, PencilIcon } from '@heroicons/react/outline'
import { SubmitHandler } from 'react-hook-form'

import { ITask, TUpdateMeetingLinkSchema } from '@/types'
import { Button, Form, TextInput } from '@/components'
import { useUpdateMeetingLink } from '@/apis'
import { meetingLinkForm } from '@/utils'
import { useHookForm } from '@/hooks'

export const Link: React.FC<ITask> = task => {
  const [editing, setEditing] = useState(false)
  const prevLink = useRef<string | undefined>(task.meetingLink)

  const { mutateAsync: updateLink } = useUpdateMeetingLink()

  const {
    handler: {
      register,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(meetingLinkForm, {
    defaultValues: {
      meetingLink: task.meetingLink,
    },
  })

  const onToggle = useCallback(() => {
    setEditing(prev => !prev)
  }, [])

  const onSubmit: SubmitHandler<TUpdateMeetingLinkSchema> = useCallback(
    async payload => {
      if (prevLink.current === payload.meetingLink) {
        onToggle()
        return
      }
      prevLink.current = payload.meetingLink
      const response = await updateLink({
        id: task._id,
        ...payload,
      })
      if (response.data.success) {
        onToggle()
      }
    },
    [onToggle, task._id, updateLink],
  )

  return (
    // @ts-ignore
    <Form onSubmit={handleSubmit(onSubmit)} className="flex items-center gap-2">
      {editing ? (
        <TextInput
          readOnly={!editing}
          disabled={!editing}
          containerClassName="m-0"
          placeholder="Meeting link"
          {...register('meetingLink')}
          error={errors.meetingLink?.message}
        />
      ) : (
        <a
          href={getValues('meetingLink')}
          className="text-indigo-500 font-bold"
        >
          {getValues('meetingLink') ?? '-'}
        </a>
      )}
      {!editing ? (
        <PencilIcon className="h-6 w-6" onClick={onToggle} />
      ) : (
        <Button type="submit">
          <CheckIcon className="h-6 w-6" />
        </Button>
      )}
    </Form>
  )
}
