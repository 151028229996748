import React, { useCallback, useEffect, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'

import {
  IOption,
  IFontFamily,
  IAffiliateBrand,
  TCreateAffiliateBrandFormSchema,
} from '@/types'
import { useFontFamilies, useUpdateAffiliateBrand } from '@/apis'
import { Button, Form, Select, TextInput } from '@/components'
import { createAffiliateBrandSchema, getFile } from '@/utils'
import { useHookForm, useToast } from '@/hooks'

interface IUpdateBrandFormProps extends IAffiliateBrand {}

export const UpdateBrandForm: React.FC<IUpdateBrandFormProps> = ({
  logo,
  color,
  fontFamily,
}) => {
  const { addToast } = useToast()
  const [fonts, setFonts] = useState<IFontFamily[]>([])
  const [logoImg, setLogo] = useState<string | null>()

  const onFontsSuccess = useCallback((_data: IFontFamily[]) => {
    setFonts(_data)
  }, [])

  const [getFonts] = useFontFamilies(onFontsSuccess)

  useEffect(() => {
    getFonts()
  }, [getFonts])

  useEffect(() => {
    setLogo(getFile(logo))
  }, [logo])

  const {
    handler: {
      trigger,
      register,
      setValue,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(createAffiliateBrandSchema, {
    defaultValues: {
      logo,
      color,
      fontFamily,
    },
  })

  const { mutateAsync: updateAffiliateBrand } = useUpdateAffiliateBrand()

  const onSubmitBrand: SubmitHandler<TCreateAffiliateBrandFormSchema> =
    useCallback(
      async data => {
        const formData = new FormData()

        // @ts-ignore
        formData.append('logo', data.logo)
        formData.append('fontFamily', data.fontFamily)
        formData.append('color', JSON.stringify(data.color))

        try {
          const response = await updateAffiliateBrand(formData)

          if (response.data.success) {
            addToast('success', 'The brand was updated successfully.')
          }
        } catch (error) {
          // @ts-ignore
          addToast('error', error.message ?? 'Something went wrong')
        }
      },
      [updateAffiliateBrand, addToast],
    )

  const handleImageUpload = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      fieldName: keyof TCreateAffiliateBrandFormSchema,
    ) => {
      const file = e.target.files?.[0]
      const reader = new FileReader()

      reader.onloadend = () => {
        const base64String = reader.result
        if (fieldName === 'logo') {
          // @ts-ignore
          setLogo(base64String)
        }
        setValue(fieldName, file)
      }

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    [setValue],
  )

  return (
    <Form
      onSubmit={handleSubmit(onSubmitBrand)}
      className="bg-white p-8 rounded-md shadow w-full"
    >
      <div className="flex items-center justify-center mb-4">
        <label
          htmlFor="logo"
          style={{ minHeight: 140.16, maxWidth: '100%' }}
          // eslint-disable-next-line max-len
          className="flex flex-col items-center justify-center w-full px-4 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
          {logoImg ? (
            <img src={logoImg} style={{ height: '100%', objectFit: 'cover' }} />
          ) : (
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  // eslint-disable-next-line max-len
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload Logo</span>
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                SVG, PNG or JPG
              </p>
            </div>
          )}
          <input
            id="logo"
            type="file"
            accept=".svg, .png, .jpg, .jpeg"
            className="hidden"
            onChange={e => {
              handleImageUpload(e, 'logo')
            }}
          />
        </label>
      </div>
      <Select
        label="Font Family"
        options={(fonts ?? []).map(_v => ({
          value: _v.family,
          label: _v.family,
        }))}
        value={getValues('fontFamily')}
        onChange={(option: IOption<string>) => {
          setValue('fontFamily', option.value)
          trigger('fontFamily')
        }}
        className="w-full flex-col"
        placeHolder="Enter the font family"
      />
      <div className="mb-4">
        <h6 className="font-bold track mt-4 mb-4">Color</h6>
        <div className="flex space-x-4 pl-4">
          <TextInput
            type="color"
            label="Title Color"
            value={getValues('color.title')}
            className="mt-1 p-0 w-full border rounded-md"
            placeholder="Enter the title color"
            error={errors.color?.title?.message}
            {...register('color.title', {
              onChange: () => {
                trigger('color.title')
              },
            })}
          />
          <TextInput
            type="color"
            label="Description Color"
            value={getValues('color.description')}
            className="mt-1 p-0 w-full border rounded-md"
            placeholder="Enter the description color"
            error={errors.color?.description?.message}
            {...register('color.description', {
              onChange: () => {
                trigger('color.description')
              },
            })}
          />
          <TextInput
            type="color"
            label="Menu Item Color"
            value={getValues('color.menuItem')}
            className="mt-1 p-0 w-full border rounded-md"
            placeholder="Enter the menu item color"
            error={errors.color?.menuItem?.message}
            {...register('color.menuItem', {
              onChange: () => {
                trigger('color.menuItem')
              },
            })}
          />
          <TextInput
            type="color"
            label="Active Button Color"
            value={getValues('color.activeButton')}
            className="mt-1 p-0 w-full border rounded-md"
            placeholder="Enter the active button color"
            error={errors.color?.activeButton?.message}
            {...register('color.activeButton', {
              onChange: () => {
                trigger('color.activeButton')
              },
            })}
          />
        </div>
      </div>
      <Button
        type="submit"
        className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
      >
        Save
      </Button>
    </Form>
  )
}
