import React from 'react'

import { Button, Modal } from '@/components'
import { TFunc } from '@/types'

import './styles.scss'

interface IConfirmationModalProps {
  description?: string
  buttonText?: string
  onClose: TFunc
  onConfirm: TFunc
}

export const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
  description,
  buttonText,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal canClose isOpen onClose={onClose}>
      <div className="confirmation-modal">
        <p className="mt-1 text-sm text-gray-500">{description}</p>
        <div className="flex w-full justify-end">
          <Button type="button" onClick={onConfirm} className="btn btn-danger">
            {buttonText}
          </Button>
          <Button type="button" onClick={onClose} className="btn btn-outline">
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}
