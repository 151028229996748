import {
  IResponse,
  ISubmissionForm,
  IDoctoratesResponse,
  IDocumentsUploadsResponse,
} from '@/types'

import { useLazyGet, usePost } from '../utils/wrapper'
import endpoints from '../endpoints'

export interface ICreateRequestPayload
  extends Omit<
    ISubmissionForm,
    'certificatePath' | 'paymentDetails' | 'accepted' | 'declined' | '_id'
  > {}

export const useGetDoctorateRequests = (
  onSuccess: (_data: IDoctoratesResponse) => void,
) => {
  return useLazyGet(endpoints.doctorateRequests, undefined, {
    onSuccess,
  })
}

export const useCreateDoctorateRequest = () => {
  return usePost<ICreateRequestPayload, IResponse>(
    endpoints.createDoctorateRequest,
  )
}

export const useUpdateDoctorateRequest = () => {
  return usePost<
    Partial<
      Pick<ISubmissionForm, 'accepted' | 'declined' | 'paymentDetails'>
    > & { id: string },
    IResponse
  >(endpoints.updateDoctorateRequest)
}

export const useUploadDocuments = () => {
  return usePost<FormData, IDocumentsUploadsResponse>(endpoints.uploadDocuments)
}
