import React, { useCallback, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import moment from 'moment'

import {
  Form,
  Button,
  TextArea,
  TextInput,
  DataTable,
  RadioButton,
} from '@/components'
import {
  Reference,
  EducationalBackground,
  THonoraryDoctorateSchema,
} from '@/types'
import { DoctorateContextProvider } from '@/context'
import { useDoctorates, useHookForm } from '@/hooks'
import { honoraryDoctorateForm } from '@/utils'

import { AddEducation, AddReference } from './components'

const DoctorateSubmission: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isAddEducation, setIsAddEducation] = useState(false)
  const [documentsList, setDocuments] = useState<File[]>([])
  const [references, setReferences] = useState<Reference[]>([])
  const { onUploadDocuments, onCreateDoctorateRequest } = useDoctorates()
  const [educations, setEducations] = useState<EducationalBackground[]>([])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onCloseAddEducation = useCallback(() => {
    setIsAddEducation(false)
  }, [])

  const onSaveEducation = useCallback((education?: EducationalBackground) => {
    if (!education) return
    setEducations(prev => [...prev, education])
  }, [])

  const onSave = useCallback((reference?: Reference) => {
    if (!reference) return
    setReferences(prev => [...prev, reference])
  }, [])

  const {
    handler: {
      reset,
      trigger,
      register,
      setValue,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(honoraryDoctorateForm)

  const onCheckValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore
      setValue(e.target.name, e.target.value)
      // @ts-ignore
      trigger(e.target.name)
    },
    [setValue, trigger],
  )

  const onSubmit: SubmitHandler<THonoraryDoctorateSchema> = useCallback(
    async data => {
      let supportingDocuments: string[] = []
      if (documentsList.length) {
        supportingDocuments = await onUploadDocuments(documentsList)
      }

      await onCreateDoctorateRequest({
        references,
        supportingDocuments,
        paymentEmail: data.paymentEmail,
        personalDetails: {
          name: data.name,
          email: data.email,
          phone: data.phone,
          gender: data.gender,
          dateOfBirth: new Date(data.dob),
        },
        educationalBackground: educations,
        detailedProposal: data.detailedProposal,
        professionalBackground: data.professionalBackground.split('|') || [],
      })

      reset({
        name: '',
        email: '',
        phone: '',
        gender: '',
        dob: undefined,
        paymentEmail: '',
        detailedProposal: '',
        professionalBackground: '',
      })
      setDocuments([])
      setReferences([])
      setEducations([])
    },
    [
      reset,
      educations,
      references,
      documentsList,
      onUploadDocuments,
      onCreateDoctorateRequest,
    ],
  )

  const onPickImage = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setDocuments(prev => [file, ...prev.filter(_v => _v.name !== file.name)])
    }
  }, [])

  return (
    <>
      <div className="container mx-auto py-8">
        <div className="bg-white p-8 rounded-md shadow w-full">
          <h2 className="text-2xl font-semibold mb-4">Honorary Doctorate</h2>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              label="Name"
              value={getValues('name')}
              error={errors.name?.message}
              {...register('name', {
                onChange: () => {
                  trigger('name')
                },
              })}
            />
            <TextInput
              label="Date of Birth"
              type="date"
              value={moment(getValues('dob')).format('YYYY-MM-DD')}
              error={errors.dob?.message}
              {...register('dob', {
                onChange: () => {
                  trigger('dob')
                },
              })}
            />
            <RadioButton
              label="Gender"
              options={[
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
              ]}
              value={getValues('gender')}
              error={errors.gender?.message}
              {...register('gender')}
              onChange={onCheckValue}
            />
            <TextInput
              label="Email"
              error={errors.email?.message}
              {...register('email', {
                onChange: () => {
                  trigger('email')
                },
              })}
            />
            <TextInput
              label="Phone"
              error={errors.phone?.message}
              {...register('phone', {
                onChange: () => {
                  trigger('phone')
                },
              })}
            />
            <TextInput
              label="Payment email"
              error={errors.paymentEmail?.message}
              {...register('paymentEmail', {
                onChange: () => {
                  trigger('paymentEmail')
                },
              })}
            />
            <TextArea
              label="Detailed proposal"
              className="mt-1 p-2 w-full border rounded-md"
              error={errors.detailedProposal?.message}
              {...register('detailedProposal', {
                onChange: () => {
                  trigger('detailedProposal')
                },
              })}
            />
            <div className="mb-4">
              <label className="block text-sm font-medium text-black mb-4">
                References
              </label>
              {!!references.length && (
                <DataTable
                  data={references}
                  headers={[
                    { label: 'Name', key: 'name' },
                    { label: 'Email', key: 'email' },
                    { label: 'Phone', key: 'phone' },
                  ]}
                >
                  {data =>
                    data.map((item, idx) => (
                      <tr
                        key={idx}
                        className="hover:bg-gray-100 cursor-pointer"
                      >
                        <td className="py-2 px-4 border-b text-start">
                          {item.name}
                        </td>
                        <td className="py-2 px-4 border-b text-start">
                          {item.contactDetails.email}
                        </td>
                        <td className="py-2 px-4 border-b text-start">
                          {item.contactDetails.phone}
                        </td>
                      </tr>
                    ))
                  }
                </DataTable>
              )}
              <div
                onClick={() => setIsOpen(true)}
                className="cursor-pointer w-full h-20 border rounded border-dashed flex items-center justify-center"
              >
                <i className="fi fi-br-add h-4"></i>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-black mb-4">
                Educational Background
              </label>
              {!!educations.length && (
                <DataTable
                  data={educations}
                  headers={[
                    { label: 'Degree', key: 'degree' },
                    { label: 'Institution', key: 'institution' },
                    { label: 'Field Of Study', key: 'fieldOfStudy' },
                    { label: 'Graduation year', key: 'graduationYear' },
                  ]}
                >
                  {data =>
                    data.map((item, idx) => (
                      <tr
                        key={idx}
                        className="hover:bg-gray-100 cursor-pointer"
                      >
                        <td className="py-2 px-4 border-b text-start">
                          {item.degree}
                        </td>
                        <td className="py-2 px-4 border-b text-start">
                          {item.institution}
                        </td>
                        <td className="py-2 px-4 border-b text-start">
                          {item.fieldOfStudy}
                        </td>
                        <td className="py-2 px-4 border-b text-start">
                          {item.graduationYear}
                        </td>
                      </tr>
                    ))
                  }
                </DataTable>
              )}
              <div
                onClick={() => setIsAddEducation(true)}
                className="cursor-pointer w-full h-20 border rounded border-dashed flex items-center justify-center"
              >
                <i className="fi fi-br-add h-4"></i>
              </div>
            </div>
            <TextArea
              label="Professional Background"
              placeholder="Please separate your skills with |."
              className="mt-1 p-2 w-full border rounded-md"
              error={errors.professionalBackground?.message}
              {...register('professionalBackground', {
                onChange: () => {
                  trigger('professionalBackground')
                },
              })}
            />
            <div className="mb-4">
              <input
                id="files"
                type="file"
                className="hidden"
                onChange={onPickImage}
                accept=".jpg, .jpeg, .pdf, .doc, .docx"
              />
              <label className="cursor-pointer">
                <p className="block text-sm font-medium text-black mb-4">
                  Documents
                </p>
                {!!documentsList.length && (
                  <DataTable
                    data={documentsList}
                    headers={[
                      { label: 'Preview', key: 'preview' },
                      { label: 'File Name', key: 'fileName' },
                      { label: 'File Type', key: 'fileType' },
                    ]}
                  >
                    {data =>
                      data.map((item, idx) => (
                        <tr
                          key={idx}
                          className="hover:bg-gray-100 cursor-pointer"
                        >
                          <td className="py-2 px-4 border-b text-start">
                            <img
                              alt=""
                              className="w-10 h-10"
                              src={URL.createObjectURL(item)}
                            />
                          </td>
                          <td className="py-2 px-4 border-b text-start">
                            {item.name}
                          </td>
                          <td className="py-2 px-4 border-b text-start">
                            {item.type}
                          </td>
                        </tr>
                      ))
                    }
                  </DataTable>
                )}
                <label
                  htmlFor="files"
                  className="cursor-pointer w-full h-20 border rounded border-dashed flex items-center justify-center"
                >
                  <i className="fi fi-br-add h-4"></i>
                </label>
              </label>
              <p className="text-xs text-gray-500 mt-2">Upload All Documents</p>
            </div>
            <Button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
            >
              Submit
            </Button>
          </Form>
        </div>
      </div>
      <AddReference isOpen={isOpen} onClose={onClose} onSave={onSave} />
      <AddEducation
        isOpen={isAddEducation}
        onSave={onSaveEducation}
        onClose={onCloseAddEducation}
      />
    </>
  )
}

export const DoctorateSubmissionPage = () => (
  <DoctorateContextProvider>
    <DoctorateSubmission />
  </DoctorateContextProvider>
)
