import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react'
import { Outlet } from 'react-router-dom'

import {
  IPlan,
  IPlanResponse,
  IStartedProgress,
  IStartedItemsResponse,
} from '@/types'
import {
  useQuizzes,
  useStudents,
  useMyQuizzes,
  useCreateQuiz,
  useUpdateQuiz,
} from '@/apis'
import { useAuth } from '@/hooks'

interface IQuizContext {
  quiz?: IPlan
  quizzes: IPlan[]
  onGetTests: () => void
  onGetStudents: () => void
  myTests: IStartedProgress[]
  onEdit: (_data?: IPlan) => void
  onSubmitQuiz: (_payload: IPlan, _isCreating: boolean) => Promise<void>
}

export const QuizContext = createContext<IQuizContext>({
  quizzes: [],
  myTests: [],
  onEdit: () => null,
  onGetTests: () => null,
  onGetStudents: () => null,
  onSubmitQuiz: async () => undefined,
})

export const QuizContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { isAdmin } = useAuth()
  const [quiz, setQuiz] = useState<IPlan>()
  const [quizzes, setQuizzes] = useState<IPlan[]>([])
  const [students, setStudents] = useState<any[]>([])
  const [myTests, setMyTests] = useState<IStartedProgress[]>([])

  const onMyTestsSuccess = useCallback((_data: IStartedItemsResponse) => {
    setMyTests(_data.data)
  }, [])

  const onQuizzesSuccess = useCallback((_data: IPlanResponse) => {
    setQuizzes(_data.data)
  }, [])

  const onStudentsSuccess = useCallback((_data: any) => {
    setStudents(_data?.data || [])
  }, [])

  const [getTests] = useQuizzes(onQuizzesSuccess)
  const [getMyTests] = useMyQuizzes(onMyTestsSuccess)
  const [getStudents] = useStudents(onStudentsSuccess)
  const { mutateAsync: createQuiz } = useCreateQuiz()
  const { mutateAsync: updateQuiz } = useUpdateQuiz()

  const onGetTests = useCallback(() => {
    if (!isAdmin) {
      getMyTests()
    }
    getTests()
  }, [getMyTests, getTests, isAdmin])

  useEffect(() => {
    if (!isAdmin) {
      getMyTests()
    }
  }, [getMyTests, isAdmin])

  const onEdit = useCallback((quiz?: IPlan) => {
    setQuiz(quiz)
  }, [])

  const onGetStudents = useCallback(() => {
    getStudents()
  }, [getStudents])

  const onSubmitQuiz = useCallback(
    async (payload: IPlan, isCreating: boolean) => {
      const request = isCreating ? createQuiz : updateQuiz
      const response = await request(payload)
      if (response.data.success) {
        onGetTests()
      }
      setQuiz(undefined)
    },
    [createQuiz, onGetTests, updateQuiz],
  )

  const value = useMemo(
    () => ({
      quiz,
      onEdit,
      quizzes,
      students,
      myTests,
      onGetTests,
      onSubmitQuiz,
      onGetStudents,
    }),
    [
      quiz,
      onEdit,
      quizzes,
      myTests,
      students,
      onGetTests,
      onSubmitQuiz,
      onGetStudents,
    ],
  )
  return (
    <QuizContext.Provider value={value}>
      {children ?? <Outlet />}
    </QuizContext.Provider>
  )
}
