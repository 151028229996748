import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { getFile, signUpPath } from '@/utils'
import { SectionTypes } from '@/types'
import { Button } from '@/components'
import { useBrand } from '@/hooks'

export const AdvanceSection: React.FC = () => {
  const navigate = useNavigate()
  const { template, color } = useBrand()

  const advance = useMemo(
    () => template?.sections?.find(_v => _v.type === SectionTypes.ADVANCE),
    [template?.sections],
  )

  if (!advance) return null

  return (
    <section className="advance-cards">
      <div className="container">
        <div className="row">
          {advance?.blocks?.map((block, idx) => (
            <div className="col-lg-6" key={idx}>
              <div className="card shadow mb-5">
                <img src={getFile(block.image)} alt="" />
                <h3
                  className="card-title"
                  style={{
                    color: color?.title,
                  }}
                >
                  {block.title}
                </h3>
                <p
                  className="card-text"
                  style={{
                    color: color?.description,
                  }}
                >
                  {block.description}
                </p>
                <Button
                  type="button"
                  className="btn custom-btn mini gold"
                  onClick={() => navigate(signUpPath)}
                  style={{
                    backgroundColor: color?.activeButton,
                  }}
                >
                  Get Started
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
