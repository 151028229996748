import React, { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'

import { Button, Form, TextInput } from '@/components'
import { useAuth, useHookForm } from '@/hooks'
import { resetFromSchema } from '@/utils'
import { TResetSchema } from '@/types'

export const ResetPassPage: React.FC = () => {
  const { onReset } = useAuth()
  const {
    handler: {
      trigger,
      register,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(resetFromSchema)

  const onSubmit: SubmitHandler<TResetSchema> = useCallback(
    data => {
      onReset(data)
    },
    [onReset],
  )
  return (
    <div className="form">
      <h2>Reset Password</h2>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-center">
          <div className="w-1/2 lg:w-1/5">
            <TextInput
              type="password"
              label="Password"
              error={errors.password?.message}
              {...register('password', {
                onChange: () => {
                  trigger('password')
                },
              })}
            />
            <TextInput
              type="password"
              label="Password confirmation"
              error={errors.confirmPassword?.message}
              {...register('confirmPassword', {
                onChange: () => {
                  trigger('confirmPassword')
                },
              })}
            />
          </div>
        </div>
        <Button
          type="submit"
          disabled={!getValues('password')}
          className="text-white font-bold bg-red-500 cursor-pointer p-2 rounded hover:bg-red-700"
        >
          Submit
        </Button>
      </Form>
    </div>
  )
}

export * from './wrapper'
