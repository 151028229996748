import React, { useMemo } from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import { Chart as ChartJS, defaults } from 'chart.js/auto'
import { Doughnut, Bar } from 'react-chartjs-2'
import moment from 'moment'

import { DataTable, WithPaginator } from '@/components'
import { PaymentSchemaTypes } from '@/types'
import { usePercentagesInfo } from '@/apis'
import { makeBarStats } from '@/utils'
import { usePayment } from '@/hooks'

import { colors, headers } from '../constant'
import { PaymentRow } from './paymentRow'

defaults.maintainAspectRatio = false
defaults.responsive = true
defaults.plugins.title.display = true

export const PaymentsTab: React.FC = () => {
  const { history, stats, totalItems, pageMeta, onPageMetaChange } =
    usePayment()

  const { data: percentagesInfo } = usePercentagesInfo()

  const labels = useMemo(() => moment.months(), [])

  const barData = useMemo(() => {
    const data: Record<PaymentSchemaTypes, (number | undefined)[]> = {
      [PaymentSchemaTypes.QUIZ]: makeBarStats(
        stats.find(_v => _v.type === PaymentSchemaTypes.QUIZ)?.data,
      ),
      [PaymentSchemaTypes.COURSE]: makeBarStats(
        stats.find(_v => _v.type === PaymentSchemaTypes.COURSE)?.data,
      ),
      [PaymentSchemaTypes.AFFILIATE]: makeBarStats(
        stats.find(_v => _v.type === PaymentSchemaTypes.AFFILIATE)?.data,
      ),
      [PaymentSchemaTypes.SUBMISSION]: makeBarStats(
        stats.find(_v => _v.type === PaymentSchemaTypes.SUBMISSION)?.data,
      ),
    }
    return {
      labels,
      datasets: Object.values(PaymentSchemaTypes).map(value => ({
        label: value,
        data: data[value],
        backgroundColor: [colors[value]],
      })),
    }
  }, [stats, labels])

  const doughnutData = useMemo(() => {
    return {
      labels: Object.values(PaymentSchemaTypes).map(_v => _v),
      datasets: [
        {
          data: Object.values(PaymentSchemaTypes).map(
            _v => stats.find(_s => _s.type === _v)?.total,
          ),
          backgroundColor: Object.values(PaymentSchemaTypes).map(
            _v => colors[_v],
          ),
        },
      ],
    }
  }, [stats])
  return (
    <React.Fragment>
      <div className="flex gap-2 p-2">
        {Object.keys(percentagesInfo?.data ?? {}).map(key => (
          <p key={key}>
            <span className="font-bold uppercase">{key}: </span>
            {/* @ts-ignore */}
            {percentagesInfo?.data?.[key]}%
          </p>
        ))}
      </div>
      <div className="flex gap-4 h-96 p-2">
        <div className="w-1/2">
          <Bar data={barData} />
        </div>
        <div className="w-1/2">
          <Doughnut data={doughnutData} />
        </div>
      </div>
      <WithPaginator
        data={history}
        totalItems={totalItems}
        onPageMetaChange={onPageMetaChange}
        initialPageSize={pageMeta.pageSize}
      >
        {pagedData => (
          <>
            <DataTable data={pagedData} headers={headers}>
              {data => {
                return data.map((item, idx) => (
                  <PaymentRow {...item} key={idx} index={idx + 1} />
                ))
              }}
            </DataTable>
          </>
        )}
      </WithPaginator>
    </React.Fragment>
  )
}
