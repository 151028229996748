import React, { useMemo } from 'react'
import { Link, NavLink } from 'react-router-dom'
import classNames from 'classnames'

import {
  testsPath,
  rolesPath,
  supportPath,
  feedbackPath,
  usersPath,
  documentsPath,
  affiliatesPath,
  myAffiliatePath,
  proctorTasksPath,
  manageCoursePath,
  consultationsPath,
  paymentHistoryPath,
  proctorProfilePath,
  proctorCalendarPath,
  proctorTasksHistoryPath,
  honoraryDoctoralListPath,
  myTestsPath,
  myCoursePath,
  coursePath,
  profilePath,
  referralPath,
  signInPath,
  signUpPath,
  manageQuizPath,
  contactsFormPath,
  proctorsTasksPath,
} from '@/utils'
import { useAuth, useSupport } from '@/hooks'

import mainLogo from '@/assets/images/logo.svg'
import { IRole } from '@/types'

const adminLinks = [
  {
    icon: 'test',
    label: 'Tests',
    path: manageQuizPath,
  },
  {
    icon: 'e-learning',
    path: manageCoursePath,
    label: 'Course Management',
  },
  {
    icon: 'users-alt',
    label: 'Users',
    path: usersPath,
  },
  {
    icon: 'document',
    label: 'Documents',
    path: documentsPath,
  },
  {
    icon: 'life-ring',
    key: 'unreadCounts',
    path: supportPath,
    label: 'Support',
  },
  {
    icon: 'portrait',
    path: feedbackPath,
    label: 'Feedbacks',
  },
  {
    key: 'requests',
    icon: 'stethoscope',
    label: 'Honorary Doctorate',
    path: honoraryDoctoralListPath,
  },
  {
    icon: 'portrait',
    label: 'Roles',
    path: rolesPath,
  },
  {
    icon: 'form',
    label: 'Contact forms',
    path: contactsFormPath,
  },
]

export const sidebarLinks = (role?: IRole, isAuthenticated?: boolean) => {
  if (role === IRole.Student) {
    return [
      ...(isAuthenticated
        ? [
            {
              icon: 'books',
              label: 'My Tests',
              path: myTestsPath,
            },
            {
              icon: 'test',
              label: 'Tests',
              path: testsPath,
            },
            {
              icon: 'books',
              path: myCoursePath,
              label: 'My Courses',
            },
            {
              path: coursePath,
              label: 'Courses',
              icon: 'e-learning',
            },
            {
              icon: 'document',
              path: documentsPath,
              label: 'My Documents',
            },
            {
              icon: 'settings',
              path: profilePath,
              label: 'My Profile',
            },
            {
              icon: 'people',
              label: 'Referral',
              path: referralPath,
            },
            {
              label: 'Support',
              icon: 'life-ring',
              path: supportPath,
            },
            {
              icon: 'bank',
              label: 'Finance',
              path: paymentHistoryPath,
            },
            // {
            //   path: me?.subscription ? createAffiliateFormPath : schoolPricingPath,
            //   label: 'School',
            // },
          ]
        : [
            {
              label: 'Sign In',
              path: signInPath,
            },
            {
              label: 'Sign Up',
              path: signUpPath,
            },
          ]),
    ]
  } else if (role === IRole.Proctor) {
    return [
      {
        label: 'Profile',
        icon: 'settings',
        path: proctorProfilePath,
      },
      {
        icon: 'calendar',
        label: 'Calendar',
        path: proctorCalendarPath,
      },
      {
        icon: 'list',
        label: 'Tasks',
        path: proctorsTasksPath,
      },
      {
        icon: 'test',
        label: 'My Tasks',
        path: proctorTasksPath,
      },
      {
        icon: 'books',
        label: 'Tasks history',
        path: proctorTasksHistoryPath,
      },
    ]
  } else if (role === IRole.Teacher) {
    return [
      // {
      //   icon: 'test',
      //   label: 'Tests',
      //   path: manageQuizPath,
      // },
      // {
      //   icon: 'e-learning',
      //   path: manageCoursePath,
      //   label: 'Course Management',
      // },
      {
        icon: 'life-ring',
        key: 'unreadCounts',
        path: supportPath,
        label: 'Support',
      },
    ]
  }

  return [
    ...(role === IRole.Admin
      ? [
          ...adminLinks,
          {
            icon: 'laptop-medical',
            label: 'New school applications',
            path: consultationsPath,
          },
          {
            icon: 'school',
            label: 'Schools',
            path: affiliatesPath,
          },
        ]
      : role === IRole.AffiliateOwner
        ? [
            ...adminLinks,
            {
              icon: 'school',
              label: 'My School',
              path: myAffiliatePath,
            },
          ]
        : []),
    {
      label: 'Finance',
      path: paymentHistoryPath,
      icon: 'bank',
    },
  ]
}

export const Sidebar: React.FC = () => {
  const { chats } = useSupport()
  const { me, requests } = useAuth()

  const items = useMemo(() => sidebarLinks(me?.role, !!me), [me])

  return (
    <aside className="bg-zinc-50 text-black min-h-screen p-4 hidden md:block">
      <Link to={items[0].path} className="mb-2 logo-img">
        <img src={mainLogo} alt="" />
      </Link>
      <ul>
        {items.map((item, idx) => (
          <li className="mb-2" key={idx}>
            <NavLink
              to={item.path}
              className={({ isActive }) =>
                classNames(
                  'block text-black font-bold py-2 px-4 rounded hover:bg-green-300 flex items-center justify-between',
                  {
                    'bg-zinc-100': !isActive,
                    'bg-green-300': isActive,
                  },
                )
              }
            >
              <div>
                <i className={`fi fi-br-${item.icon} mr-4 h-5`}></i>
                {item.label}
              </div>
              {/* @ts-ignore */}
              {((item?.key === 'unreadCounts' &&
                !!chats.find(_v => _v.unreadCounts > 0)) ||
                // @ts-ignore
                (item?.key === 'requests' && (requests || 0) > 0)) && (
                <span className="inline-flex items-center justify-center w-2 h-2 ms-2 text-xs font-semibold bg-red-500 rounded-full"></span>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </aside>
  )
}
