import React, { useEffect } from 'react'

import { useCourse, useH5P } from '@/hooks'
import { TProgress } from '@/types'

import { ManagePlanSchema } from '../shared'

export const ManageCoursePage: React.FC = () => {
  const { onEdit } = useCourse()
  const { courses, getCourses } = useH5P()

  useEffect(() => {
    getCourses()
  }, [getCourses])

  return (
    <ManagePlanSchema
      plans={courses}
      onEdit={onEdit}
      onRefresh={getCourses}
      type={TProgress.COURSE}
    />
  )
}
