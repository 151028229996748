import React, { useMemo, useState, useCallback, createContext } from 'react'
import { Outlet } from 'react-router-dom'

import { IInviteeData, IInviteesResponse } from '@/types'
import { useInvitees } from '@/apis'

interface IReferralContext {
  invitees: IInviteeData[]
  onGetInvitees: () => void
}

export const ReferralContext = createContext<IReferralContext>({
  invitees: [],
  onGetInvitees: () => null,
})

export const ReferralContextProvider: React.FC = () => {
  const [invitees, setInvitees] = useState<IInviteeData[]>([])

  const onInviteesSuccess = useCallback((_data: IInviteesResponse) => {
    setInvitees(_data.data)
  }, [])

  const [getInvitees] = useInvitees(onInviteesSuccess)

  const onGetInvitees = useCallback(() => {
    getInvitees()
  }, [getInvitees])

  const value = useMemo(
    () => ({
      invitees,
      onGetInvitees,
    }),
    [invitees, onGetInvitees],
  )
  return (
    <ReferralContext.Provider value={value}>
      <Outlet />
    </ReferralContext.Provider>
  )
}
