import {
  IIncome,
  IResponse,
  IPaymentMethod,
  IPercentagesResponse,
  IPaymentStatsResponse,
  IPaymentMethodPayload,
  IPaymentHistoryPayload,
  IPayment,
} from '@/types'

import {
  useGet,
  usePost,
  useLazyGet,
  usePaginatedRequest,
} from '../utils/wrapper'
import endpoints from '../endpoints'

export const useGetPaymentHistory = (
  enabled?: boolean,
  refreshKey?: string,
  params?: IPaymentHistoryPayload,
) => {
  const res = usePaginatedRequest<IPayment>({
    params: {
      queryParams: Object.keys(params ?? {})
        // @ts-ignore
        .filter(key => !!params[key])
        .reduce(
          // @ts-ignore
          (acc, cur) => [...acc, { [cur]: params?.[cur] }],
          [] as Record<string, any>[],
        ),
    },
    enabled,
    refreshKey,
    path: endpoints.paymentHistory,
  })
  return res
}

export const usePaymentStats = (
  onSuccess: (_data: IPaymentStatsResponse) => void,
) => {
  return useLazyGet<undefined, IPaymentStatsResponse>(
    endpoints.paymentStats,
    undefined,
    {
      onSuccess,
    },
  )
}

export const useIncomes = (enabled: boolean, refreshKey?: string) => {
  const res = usePaginatedRequest<IIncome>({
    enabled,
    refreshKey,
    path: endpoints.incomes,
  })
  return res
}

export const usePercentagesInfo = () => {
  return useGet<unknown, IPercentagesResponse>(endpoints.percentages, undefined)
}

export const usePaymentMethods = (
  onSuccess?: (_data: IPaymentMethod[]) => void,
) => {
  return useLazyGet(endpoints.paymentMethods, undefined, { onSuccess })
}

export const useMakeDefaultMethod = () => {
  return usePost<IPaymentMethodPayload, IResponse>(
    endpoints.makeDefaultMethod,
    undefined,
  )
}

export const useGenerateAccountLink = () => {
  return usePost<unknown, string>(endpoints.generateAccountLink, undefined)
}
