export default {
  me: 'api/me',
  reset: 'reset',
  signIn: 'signIn',
  signUp: 'signUp',
  forgot: 'forgot',
  getDiploma: 'api/diploma',
  h5pEdit: 'h5p/:contentId/edit',
  createEnrollment: 'api/enrollment-create',
  chargeAccount: 'api/create-checkout-session',
  startCourse: 'api/start-course',
  h5pContents: 'h5p',
  playH5p: 'h5p/:contentId/play',
  courses: 'api/courses',
  createCourse: 'api/create-course',
  updateCourse: 'api/update-course',
  courseById: 'api/course/:courseId',
  boughtCourses: 'api/bought-courses',
  documents: 'api/documents',
  myQuizzes: 'api/my-quizzes',
  quizzes: 'api/quizzes',
  invitees: 'api/invitees',
  subscriptions: 'api/subscriptions',
  subscribe: 'api/subscribe',
  affiliates: 'api/affiliates',
  staff: 'api/staff',
  fonts: 'fonts',
  buySubdomain: 'api/buy-subdomain',
  affiliateStudents: 'api/students',
  consultation: 'consultation',
  affiliateBrand: 'brand',
  affiliate: 'affiliate',
  affiliateAction: 'api/affiliate',
  myBrand: 'api/brand',
  addToRole: 'api/add-role',
  consultationByToken: 'consultation/:token',
  processConsultation: 'api/consultation',
  rejectConsultation: 'api/consultation/reject',
  consultations: 'api/consultations',
  users: 'api/users',
  finishCourse: 'api/finish-course',
  uploadAvatar: 'api/avatar',
  chats: 'api/chats',
  messages: 'api/messages/:chatId',
  feedbacks: 'api/feedback-list',
  createFeedback: 'api/create-feedback',
  doctorateRequests: 'api/doctorate-requests',
  createDoctorateRequest: 'api/create-doctorate',
  updateDoctorateRequest: 'api/update-doctorate',
  uploadDocuments: 'api/upload-documents',
  deleteQuiz: 'api/delete-quiz',
  createQuiz: 'api/create-quiz',
  updateQuiz: 'api/update-quiz',
  startQuiz: 'api/start-quiz',
  finishQuiz: 'api/finish-quiz',
  getQuizById: 'api/quiz/:id',
  updateCourseAccessibility: 'api/course-accessibility',
  updateQuizAccessibility: 'api/quiz-accessibility',
  addCourse: 'api/add-course',
  removeCourse: 'api/remove-course',
  addQuiz: 'api/add-quiz',
  removeQuiz: 'api/remove-quiz',
  mergeCourse: 'api/merge-course',
  mergeQuiz: 'api/merge-quiz',
  paymentHistory: 'api/payment-history',
  paymentStats: 'api/payment-stats',
  updateProfile: 'api/update-profile',
  calendars: 'api/calendars',
  createCalendar: 'api/create-calendar',
  updateCalendar: 'api/update-calendar',
  deleteCalendar: 'api/calendar',
  pendingTasks: 'api/pending-tasks',
  processTask: 'api/process-task',
  tasksHistory: 'api/tasks-history',
  brandFromSubdomain: '/affiliate-brand/:subDomain',
  chooseTemplate: 'api/brand/choose-template',
  chooseRole: 'api/choose-role',
  incomes: 'api/incomes',
  percentages: 'api/percentages',
  paymentMethods: 'api/payment-methods',
  makeDefaultMethod: 'api/make-default-method',
  generateAccountLink: 'api/account-link',
  getContactsForm: 'api/contacts-form',
  createContactForm: '/contact-form',
  assignTask: 'api/assign-task',
  tasks: 'api/tasks',
  updateMeeting: 'api/update-meeting',
  rescheduleCourse: 'api/reschedule-course',
  rescheduleQuiz: 'api/reschedule-quiz',
  createDocument: 'api/create-document',
} as const
