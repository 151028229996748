import React, { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'
import moment from 'moment'

import { EducationalBackground, TAddEducationSchema } from '@/types'
import { Button, Form, Modal, TextInput } from '@/components'
import { educationForm } from '@/utils'
import { useHookForm } from '@/hooks'

interface IAddEducationProps {
  isOpen?: boolean
  onClose?: () => void
  onSave?: (_ref?: EducationalBackground) => void
}

export const AddEducation: React.FC<IAddEducationProps> = ({
  onSave,
  onClose,
  isOpen = false,
}) => {
  const {
    handler: {
      reset,
      trigger,
      register,
      getValues,
      clearErrors,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(educationForm)

  const onSubmit: SubmitHandler<TAddEducationSchema> = useCallback(
    data => {
      onSave?.({
        ...data,
        graduationYear: moment(data.graduationYear).get('years'),
      })
      reset({
        degree: '',
        institution: '',
        fieldOfStudy: '',
        graduationYear: undefined,
      })
      onClose?.()
    },
    [onSave, reset, onClose],
  )

  const _onClose = useCallback(() => {
    clearErrors('degree')
    clearErrors('institution')
    clearErrors('fieldOfStudy')
    clearErrors('graduationYear')
    onClose?.()
  }, [clearErrors, onClose])

  return (
    <Modal isOpen={isOpen} onClose={_onClose} canClose>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="w-full">
              <TextInput
                label="Degree"
                error={errors.degree?.message}
                value={getValues('degree')}
                {...register('degree', {
                  onChange: () => {
                    trigger('degree')
                  },
                })}
              />
              <TextInput
                label="Field Of Study"
                error={errors.fieldOfStudy?.message}
                value={getValues('fieldOfStudy')}
                {...register('fieldOfStudy', {
                  onChange: () => {
                    trigger('fieldOfStudy')
                  },
                })}
              />
              <TextInput
                type="date"
                label="Graduation year"
                error={errors.graduationYear?.message}
                value={moment(getValues('graduationYear')).format('YYYY-MM-DD')}
                {...register('graduationYear', {
                  onChange: () => {
                    trigger('graduationYear')
                  },
                })}
              />
              <TextInput
                label="Institution"
                error={errors.institution?.message}
                value={getValues('institution')}
                {...register('institution', {
                  onChange: () => {
                    trigger('institution')
                  },
                })}
              />
            </div>
          </div>
        </div>
        <div className="bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <Button
            type="submit"
            className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
