import React, { useCallback, useRef, useState } from 'react'
import { H5PEditorUI } from '@lumieducation/h5p-react'
import classNames from 'classnames'

import { useH5P } from '@/hooks'
import { ITest } from '@/types'

import { LessonHeader } from './accordionItem'

interface IModuleContentProps {
  index: number
  title: string
  contentId: string
  onDelete?: (_idx: number) => void
  onSave: (_data: Omit<ITest, 'passed'> & { contentIndex: number }) => void
}

export const ModuleContent: React.FC<IModuleContentProps> = ({
  index,
  title,
  onSave,
  onDelete,
  contentId,
}) => {
  const h5pEditor = useRef<H5PEditorUI>(null)
  const [editing, setEditing] = useState(false)

  const toggleEdit = useCallback(() => {
    setEditing(prev => !prev)
  }, [])

  const _onSave = useCallback(
    (contentIndex: number) => async () => {
      try {
        const returnData = await h5pEditor.current?.save()
        if (returnData) {
          onSave({
            contentIndex,
            id: returnData?.contentId,
            title: returnData?.metadata.title,
          })
          setEditing(false)
        }
      } catch (error) {
        //
      }
    },
    [onSave],
  )

  const _onDelete = useCallback(
    (idx: number) => () => {
      onDelete?.(idx)
    },
    [onDelete],
  )

  return (
    <div className="accordion-content px-5 pt-0 overflow-hidden">
      <div className="accordion-item bg-zinc-50 p-2 rounded mb-2">
        <LessonHeader
          name={title}
          type="Module"
          editing={editing}
          onEdit={toggleEdit}
          contentId={contentId}
          onSave={_onSave(index)}
          onDelete={_onDelete(index)}
        />
        {(contentId === 'new' || editing) && (
          <Editor ref={h5pEditor} contentId={contentId} />
        )}
      </div>
    </div>
  )
}

export const Editor = React.forwardRef<H5PEditorUI, { contentId: string }>(
  ({ contentId }, ref) => {
    const { contentService } = useH5P()

    return (
      <div className={classNames('accordion-content pt-0 overflow-hidden')}>
        <div className="accordion-item bg-zinc-50 p-2 rounded mb-2">
          <p className="leading-6 font-light text-justify">
            <H5PEditorUI
              ref={ref}
              contentId={contentId}
              saveContentCallback={contentService.save}
              loadContentCallback={contentService.getEdit}
            />
          </p>
        </div>
      </div>
    )
  },
)
