import React, { useCallback, useState } from 'react'

import { Button, DataTable, WithPaginator } from '@/components'
import { IAvailableZone } from '@/types'
import { useCalendars } from '@/apis'
import { useAuth } from '@/hooks'

import { CalendarForm, CalendarRow } from './components'

const headers = [
  { label: 'From', key: 'from' },
  { label: 'To', key: 'to' },
  { label: 'Location', key: 'location' },
  { label: 'Actions', key: 'actions', className: 'flex justify-center' },
]

export const ProctorCalendarPage: React.FC = () => {
  const { me } = useAuth()
  const [refreshKey, setKey] = useState(`${Date.now()}`)
  const [creatingZone, setCreatingZone] = useState(false)
  const [selectedZone, setSelectedZone] = useState<IAvailableZone | null>(null)

  const onEdit = useCallback((zone: IAvailableZone) => {
    setSelectedZone(zone)
  }, [])

  const onClose = useCallback(() => {
    setSelectedZone(null)
    setCreatingZone(false)
  }, [])

  const onCreate = useCallback(() => {
    setCreatingZone(true)
  }, [])

  const { data, total, pageMeta, onPageMetaChange } = useCalendars(
    !!me?._id,
    refreshKey,
  )

  const onRefresh = useCallback(() => {
    setKey(`${Date.now()}`)
  }, [])

  return (
    <div className="p-4 w-full">
      <div className="flex justify-end items-center mb-4">
        <Button
          onClick={onCreate}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          New Calendar
        </Button>
      </div>
      {(!!selectedZone || creatingZone) && (
        <CalendarForm
          onClose={onClose}
          zone={selectedZone}
          onRefresh={onRefresh}
        />
      )}
      <WithPaginator
        data={data}
        totalItems={total}
        initialPageSize={pageMeta.pageSize}
        onPageMetaChange={onPageMetaChange}
      >
        {pagedData => (
          <DataTable data={pagedData} headers={headers}>
            {data => {
              return data.map((item, idx) => (
                <CalendarRow
                  key={idx}
                  {...item}
                  onEdit={onEdit}
                  onRefresh={onRefresh}
                />
              ))
            }}
          </DataTable>
        )}
      </WithPaginator>
    </div>
  )
}
