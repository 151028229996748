import React, { useCallback, useMemo } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { Button, Form, TextArea, TextInput } from '@/components'
import { SectionTypes, TContactSchema } from '@/types'
import { useBrand, useHookForm } from '@/hooks'
import { contactForm, getFile } from '@/utils'
import { useCreateContactForm } from '@/apis'

import { contactUsPage } from './constants'

export const DefaultContactUsSection: React.FC = () => {
  const { template, subDomain } = useBrand()

  const { mutateAsync: createContactForm } = useCreateContactForm()

  const contactUsSection = useMemo(
    () => template?.sections.find(_v => _v.type === SectionTypes.CONTACT_US),
    [template?.sections],
  )

  const { title, description, blocks } = useMemo(
    () => ({
      title: contactUsSection?.title ?? contactUsPage.title,
      blocks: contactUsSection?.blocks
        ? contactUsSection.blocks.map(_v => ({
            ..._v,
            image: getFile(_v.image),
          }))
        : contactUsPage.blocks,
      description: contactUsSection?.description ?? contactUsPage.description,
    }),
    [contactUsSection],
  )

  const {
    handler: {
      reset,
      register,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(contactForm)

  const onSubmit: SubmitHandler<TContactSchema> = useCallback(
    async payload => {
      const response = await createContactForm({
        ...payload,
        subDomain: subDomain ?? undefined,
      })
      if (response.data.success) {
        reset({
          email: '',
          message: '',
          fullName: '',
        })
      }
    },
    [reset, subDomain, createContactForm],
  )

  return (
    <section className="get-in-touch">
      <div className="w-full container mx-auto py-12 px-4">
        <div className="mb-8">
          <span className="inline-block bg-[#EAF0F5] text-[#193450] text-[16px] font-bold px-3 py-1 rounded-full mb-4">
            Contact us
          </span>
          <h1 className="text-3xl md:text-4xl font-bold text-gray-900">
            {title}
          </h1>
          <p className="mt-4 text-gray-600">{description}</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            {blocks.map((_block, idx) => (
              <div className="mb-6" key={idx}>
                <div className="flex items-start space-x-3">
                  <img src={_block.image} className="pt-1" alt="" />
                  <div className="flex flex-col">
                    <p className="text-[20px] font-bold">{_block.title}</p>
                    <Link
                      to={`${_block.description?.includes('@') ? 'mailto' : 'tel'}:${_block.description}`}
                      className="text-[16px] text-[#454F5B] underline"
                    >
                      {_block.description}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="bg-[#F4F6F8] p-6 rounded-lg">
            <p className="text-[18px] mb-8 text-[#454F5B]">
              Send us your questions by filling in the form.
            </p>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <TextInput
                  label="Full name"
                  containerClassName="mb-4"
                  {...register('fullName')}
                  error={errors.fullName?.message}
                  placeholder="Enter your full name"
                  labelClassName="block text-gray-700 font-medium"
                  className="mt-2 block w-full p-3 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
                <TextInput
                  label="Email"
                  {...register('email')}
                  containerClassName="mb-4"
                  error={errors.email?.message}
                  placeholder="Enter your email"
                  labelClassName="block text-gray-700 font-medium"
                  className="mt-2 block w-full p-3 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <TextArea
                rows={3}
                label="Message"
                {...register('message')}
                error={errors.message?.message}
                placeholder="Enter your message"
                className="mt-2 block w-full p-3 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
              <Button
                type="submit"
                className="w-full bg-[#3168A0] text-white py-3 rounded-md hover:bg-blue-700 transition duration-300"
              >
                Send message
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </section>
  )
}
