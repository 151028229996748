import React from 'react'

import { type IButtonProps } from './types'

export const Button: React.FC<IButtonProps> = ({
  children,
  loading = false,
  disabled = false,
  className,
  ...rest
}) => (
  <button disabled={disabled || loading} {...rest} className={className}>
    {children}
  </button>
)
