import React, { useCallback, useMemo, useRef, useState } from 'react'
import { H5PEditorUI } from '@lumieducation/h5p-react'

import { Button, TextInput } from '@/components'
import { ITest } from '@/types'

import { Editor, ModuleContent } from './module'

interface IAccordionItemProps {
  id?: number
  index?: number
  contentId?: string
  onAdd?: () => void
  name: number | string
  type?: 'Module' | 'Quiz'
  addContentLabel?: string
  onDelete?: (_idx: number) => void
  contents?: Omit<ITest, 'passed'>[]
  onChangeName?: (_name: string) => void
  onSave: (_data: Omit<ITest, 'passed'> & { contentIndex: number }) => void
}

export const AccordionItem: React.FC<IAccordionItemProps> = ({
  id,
  name,
  onAdd,
  index,
  onSave,
  onDelete,
  contents,
  contentId,
  onChangeName,
  type = 'Module',
  addContentLabel,
}) => {
  const h5pEditor = useRef<H5PEditorUI>(null)
  const [editing, setEditing] = useState(false)

  const isModule = useMemo(() => type === 'Module', [type])

  const Header = useMemo(
    () => (isModule ? ModuleHeader : LessonHeader),
    [isModule],
  )

  const _onSave = useCallback(
    (contentIndex: number) => async () => {
      try {
        const returnData = await h5pEditor.current?.save()
        if (returnData) {
          onSave({
            contentIndex,
            id: returnData?.contentId,
            title: returnData?.metadata.title,
          })
          setEditing(false)
        }
      } catch (error) {
        //
      }
    },
    [onSave],
  )

  const onEdit = useCallback(() => {
    setEditing(true)
  }, [])

  return (
    <div id={`${type}${id}`} className="p-4 shadow-sm">
      <div className="px-5 rounded">
        <Header
          name={name}
          type={type}
          onAdd={onAdd}
          onEdit={onEdit}
          editing={editing}
          onChangeName={onChangeName}
          contentId={contentId || ''}
          onSave={_onSave(index || 0)}
          addContentLabel={addContentLabel}
          onDelete={() => onDelete?.(index || 0)}
        />
      </div>
      {(contentId === 'new' || editing) && (
        <Editor ref={h5pEditor} contentId={contentId || 'new'} />
      )}
      {contents?.map((content, idx) => (
        <ModuleContent
          key={idx}
          index={idx}
          onSave={onSave}
          onDelete={onDelete}
          title={content.title}
          contentId={content.id}
        />
      ))}
    </div>
  )
}

export const ModuleHeader: React.FC<Partial<IAccordionItemProps>> = ({
  name,
  onAdd,
  onChangeName,
  addContentLabel,
}) => {
  const _onChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      onChangeName?.(ev.target.value || '')
    },
    [onChangeName],
  )
  return (
    <div className="accordion-header cursor-pointer flex space-x-5 items-center h-16 items-center justify-between transition">
      <div className="flex gap-4">
        <TextInput value={name} onChange={_onChange} />
      </div>
      <Button
        type="button"
        onClick={onAdd}
        className="rounded-full bg-green-400 text-black font-lg px-6 py-2 my-5 ml-9 hover:bg-green-700 hover:text-white"
      >
        {addContentLabel || `Add Lesson`}
      </Button>
    </div>
  )
}

interface ILessonHeaderProps extends Partial<IAccordionItemProps> {
  editing?: boolean
  contentId: string
  onSave: () => void
  onEdit: () => void
  onDelete: () => void
}

export const LessonHeader: React.FC<ILessonHeaderProps> = ({
  name,
  onEdit,
  onSave,
  editing,
  onDelete,
  contentId,
}) => {
  const isNew = useMemo(() => contentId === 'new', [contentId])

  const action = useMemo(
    () => (isNew || editing ? onSave : onEdit),
    [isNew, onSave, onEdit, editing],
  )

  return (
    <div className="accordion-content pt-0 overflow-hidden transition">
      <div className="accordion-header transition flex space-x-5 items-center h-16 items-center justify-between">
        <div className="flex gap-4">
          <h3>{name}</h3>
        </div>
        <div className="flex gap-4">
          <Button
            type="button"
            onClick={action}
            className="rounded-full bg-black text-white font-medium font-lg px-6 py-2"
          >
            {isNew || editing ? 'Save' : 'Edit'}
          </Button>
          <Button
            type="button"
            onClick={onDelete}
            className="rounded-full bg-red-400 text-black font-medium font-lg px-6 py-2 hover:bg-red-600"
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  )
}
