import React from 'react'
import { Navigate, Outlet, Location, useLocation } from 'react-router-dom'

import { signInPath } from '@/utils'
import { useAuth } from '@/hooks'

export const ResetWrapper: React.FC = () => {
  const location: Location = useLocation()
  const { resetToken } = useAuth()

  if (!resetToken) {
    return <Navigate to={signInPath} state={{ from: location }} replace />
  }

  return <Outlet />
}
