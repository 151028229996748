import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { DataTable, WithPaginator } from '@/components'
import {
  IPlan,
  IPageMeta,
  IStartedProgress,
  ISinglePlanResponse,
} from '@/types'
import { useGetCourseById, useStartCourse } from '@/apis'
import { useAuth, useH5P } from '@/hooks'
import { ENV } from '@/constants'

import { CheckoutForm, CourseWidget } from '../shared'

import { CourseRow } from './components'

const headers = [
  { label: 'ID', key: 'id' },
  { label: 'Title', key: 'title' },
  { label: 'Description', key: 'desc' },
  { label: 'Price', key: 'price' },
]

const stripePromise = loadStripe(ENV.VITE_STRIPE_PUB_KEY)

export const CoursesPage: React.FC = () => {
  const { courses, getCourses } = useH5P()
  const { isAdmin, isSchoolOwner, isProctor } = useAuth()
  const [tempTest, setTempTest] = useState<IPlan>()
  const [clientSecret, setClientSecret] = useState<string>()
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    page: 1,
    pageSize: 10,
  })
  const [selectedTest, setSelectedTest] = useState<IStartedProgress>()

  const onCourseSuccess = useCallback((data: ISinglePlanResponse) => {
    setSelectedTest(data.data)
  }, [])

  const { mutateAsync: startCourse } = useStartCourse()
  const [getCourseById] = useGetCourseById(onCourseSuccess)

  useEffect(() => {
    getCourses()
  }, [getCourses])

  const onStartCourse = useCallback(
    async (test?: IPlan) => {
      if (test && !isAdmin && !isSchoolOwner && !isProctor) {
        const response = await startCourse({
          courseId: test?._id,
        })
        if (response.data.clientSecret) {
          setClientSecret(response.data.clientSecret)
        } else if (response.data.data) {
          setSelectedTest(response.data.data)
        }
      }
    },
    [startCourse, isAdmin, isSchoolOwner, isProctor],
  )

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const onClosePaymentForm = useCallback(() => {
    setClientSecret(undefined)
    setSelectedTest(undefined)
  }, [])

  const onClose = useCallback(() => {
    setSelectedTest(undefined)
  }, [])

  const onSelectTest = useCallback(
    (test?: IPlan) => () => {
      if (!isAdmin) {
        onStartCourse(test)
        setTempTest(test)
      }
    },
    [isAdmin, onStartCourse],
  )

  const onPaymentSuccess = useCallback(async () => {
    setClientSecret(undefined)
    if (!tempTest?._id) return
    setTimeout(() => {
      getCourseById({
        pathParams: {
          courseId: tempTest._id,
        },
      })
      setTempTest(undefined)
    }, 500)
  }, [getCourseById, tempTest?._id])

  const pageData = useMemo(() => {
    const firstPageIndex = (pageMeta.page - 1) * (pageMeta.pageSize || 10)
    const lastPageIndex = firstPageIndex + (pageMeta.pageSize || 10)
    return courses.slice(firstPageIndex, lastPageIndex)
  }, [courses, pageMeta.page, pageMeta.pageSize])

  if (!!selectedTest && !isProctor) {
    return (
      <CourseWidget
        onClose={onClose}
        plan={selectedTest}
        onRefresh={getCourses}
      />
    )
  }

  return (
    <div className="p-4 w-full">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-3xl font-semibold mb-4">Courses</h2>
      </div>
      {!!clientSecret && (
        <Elements stripe={stripePromise}>
          <CheckoutForm
            clientSecret={clientSecret}
            onClose={onClosePaymentForm}
            price={tempTest?.price || 0}
            onSuccess={onPaymentSuccess}
          />
        </Elements>
      )}
      <WithPaginator
        data={pageData}
        initialPageSize={10}
        totalItems={courses.length}
        onPageMetaChange={onPageMetaChange}
      >
        {pagedData => (
          <DataTable data={pagedData} headers={headers}>
            {data => {
              return data.map((item, idx) => {
                return (
                  <CourseRow
                    key={idx}
                    {...item}
                    startCourse={onSelectTest(item)}
                  />
                )
              })
            }}
          </DataTable>
        )}
      </WithPaginator>
    </div>
  )
}
