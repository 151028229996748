import React, { useCallback, useMemo, useState } from 'react'
import moment from 'moment'

import { DataTable, WithPaginator } from '@/components'
import { DATE_FORMAT } from '@/constants'
import { useFeedback } from '@/hooks'
import { IPageMeta } from '@/types'

export const FeedbacksPage: React.FC = () => {
  const { feedbacks } = useFeedback()
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    page: 1,
    pageSize: 10,
  })

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const pageData = useMemo(() => {
    const firstPageIndex = (pageMeta.page - 1) * (pageMeta.pageSize || 10)
    const lastPageIndex = firstPageIndex + (pageMeta.pageSize || 10)
    return feedbacks.slice(firstPageIndex, lastPageIndex)
  }, [feedbacks, pageMeta.page, pageMeta.pageSize])

  return (
    <div className="p-4 w-full">
      <WithPaginator
        data={pageData}
        initialPageSize={10}
        totalItems={feedbacks.length}
        onPageMetaChange={onPageMetaChange}
      >
        {pagedData => (
          <DataTable
            data={pagedData}
            headers={[
              { label: 'ID', key: 'id' },
              { label: 'Title', key: 'title' },
              { label: 'Comment', key: 'comment' },
              { label: 'Name', key: 'name' },
              { label: 'Email', key: 'email' },
              { label: 'Created At', key: 'createdAt' },
            ]}
          >
            {data =>
              data.map((item, idx) => (
                <tr className="hover:bg-gray-100" key={idx}>
                  <td className="py-2 px-4 border-b text-start">
                    {item.plan._id}
                  </td>
                  <td className="py-2 px-4 border-b text-start">
                    {item.plan.title}
                  </td>
                  <td className="py-2 px-4 border-b text-start">
                    {item?.comment}
                  </td>
                  <td className="py-2 px-4 border-b text-start">
                    {item.user?.name}
                  </td>
                  <td className="py-2 px-4 border-b text-start">
                    {item.user?.email}
                  </td>
                  <td className="py-2 px-4 border-b text-start">
                    {moment(item.createdAt).format(DATE_FORMAT)}
                  </td>
                </tr>
              ))
            }
          </DataTable>
        )}
      </WithPaginator>
    </div>
  )
}
