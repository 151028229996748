import axios from 'axios'

import { API_ENDPOINT, AUTH_TOKEN_KEY } from '@/constants'
import { getStorageValue } from '@/utils'

export const apiRequest = axios.create({
  baseURL: `${API_ENDPOINT}/`,
})

apiRequest.interceptors.request.use(function (config) {
  const token = getStorageValue(AUTH_TOKEN_KEY)
  const clientDate = new Date()
  const clientTimeZoneOffset = clientDate.getTimezoneOffset()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
    // config.headers['ngrok-skip-browser-warning'] = '69420' // local
  }
  config.headers['x-timezone'] = clientTimeZoneOffset
  return config
})
