import React from 'react'
import classNames from 'classnames'

import { FormError } from '@/components'

import { ITextInputProps } from './types'

export const TextInput = React.forwardRef<HTMLInputElement, ITextInputProps>(
  (
    {
      label,
      error,
      onChange,
      labelClassName,
      containerClassName = 'mb-4',
      className = 'mt-1 p-2 w-full border rounded-md',
      ...rest
    },
    ref,
  ) => {
    return (
      <div className={containerClassName}>
        {!!label && (
          <label
            className={classNames(
              'block text-sm font-medium text-black',
              labelClassName,
            )}
          >
            {label}
          </label>
        )}
        <input
          {...rest}
          ref={ref}
          type={rest.type}
          onChange={onChange}
          className={className}
        />
        <FormError error={error} />
      </div>
    )
  },
)
