import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { getFile, signUpPath, whatWeDo } from '@/utils'
import { SectionTypes } from '@/types'
import { Button } from '@/components'
import { useBrand } from '@/hooks'

import { whatWeDoPage } from './constants'

export const DefaultWhatWeDoSection: React.FC = () => {
  const navigate = useNavigate()
  const { template } = useBrand()

  const whatWeDoSection = useMemo(
    () => template?.sections.find(_v => _v.type === SectionTypes.WHAT_WE_DO),
    [template?.sections],
  )

  const { title, description, image } = useMemo(
    () => ({
      title: whatWeDoSection?.title ?? whatWeDoPage.title,
      image: getFile(whatWeDoSection?.image) ?? whatWeDoPage.image,
      description: whatWeDoSection?.description ?? whatWeDoPage.description,
    }),
    [whatWeDoSection],
  )

  return (
    <section className="education-system" id={whatWeDo}>
      <div className="w-full container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div>
            <span className="inline-block bg-[#EAF0F5] text-[#193450] text-[16px] font-bold px-3 py-1 rounded-full">
              What we do
            </span>
            <h1 className="mt-4 text-[48px] font-bold text-[#161C24]">
              {title}
            </h1>
            <p className="mt-4 text-[#454F5B]">{description}</p>
            <Button
              onClick={() => navigate(signUpPath)}
              className="mt-6 bg-blue-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-700 transition"
            >
              Join now
            </Button>
          </div>
          <div>
            <img
              src={image}
              alt="Students in class"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
