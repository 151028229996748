import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react'
import { io, Socket } from 'socket.io-client'
import { Location, useLocation } from 'react-router-dom'

import { IChat, IChatResponse, IMessage, IMessageResponse } from '@/types'
import { AUTH_TOKEN_KEY, WSS_ENDPOINT } from '@/constants'
import { getStorageValue, supportPath } from '@/utils'
import { useGetChats, useGetMessages } from '@/apis'
import { useAuth } from '@/hooks'

interface ISupportContext {
  chats: IChat[]
  currentChat?: IChat
  messages: IMessage[]
  joinChat: (_chatId: string) => void
  sendMessage: (_message: string) => void
}

export const SupportContext = createContext<ISupportContext>({
  chats: [],
  messages: [],
  currentChat: undefined,
  joinChat: () => undefined,
  sendMessage: () => undefined,
})

export const SupportContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { me, isAdmin, isSchoolOwner } = useAuth()
  const _user = useRef(me)
  const wss = useRef<Socket>()
  const prevChatId = useRef<string>()
  const { pathname }: Location = useLocation()
  const [chats, setChats] = useState<IChat[]>([])
  const [currentChat, setCurrentChat] = useState<IChat>()
  const [messages, setMessages] = useState<IMessage[]>([])

  const canClean = useMemo(() => pathname !== supportPath, [pathname])

  useEffect(() => {
    if (canClean) {
      prevChatId.current = undefined
    }
  }, [canClean])

  useEffect(() => {
    _user.current = me
  }, [me])

  const onChatsSuccess = useCallback((_data: IChatResponse) => {
    setChats(_data.data)
  }, [])

  const onMessagesSuccess = useCallback((_data: IMessageResponse) => {
    setMessages(_data.data)
  }, [])

  const [getChats] = useGetChats(onChatsSuccess)
  const [getMessages] = useGetMessages(onMessagesSuccess)

  const onGetChatMessages = useCallback(
    (chatId: string) => {
      getMessages({
        pathParams: {
          chatId,
        },
      })
    },
    [getMessages],
  )

  const onReceiveChat = useCallback((chat: IChat) => {
    const isOpened = prevChatId.current === chat._id
    setChats(prev => [
      {
        ...chat,
        unreadCounts: isOpened ? 0 : chat.unreadCounts,
      },
      ...prev.filter(_v => _v._id !== chat._id),
    ])
  }, [])

  const onMessage = useCallback((message: IMessage) => {
    const isOpened = prevChatId.current === message.chat._id
    if (isOpened && wss.current) {
      if (_user.current?._id !== message.user._id) {
        wss.current.emit('read', { chatId: message.chat._id })
      }
      setMessages(prev => [...prev, message])
    }
  }, [])

  const joinChat = useCallback(
    (chatId: string, bool = false) => {
      if (!wss.current || !chatId) return
      if (chatId) {
        wss.current.emit('leave', { chatId: prevChatId.current })
        prevChatId.current = chatId
        setMessages([])
        wss.current.emit('join', { chatId })
        onGetChatMessages(chatId)
      }
      if (!bool) {
        setChats(prev => {
          setCurrentChat(prev.find(_v => _v._id === chatId))
          return [...prev]
        })
      }
      setChats(prev => [
        ...prev.map(_v =>
          _v._id === chatId ? { ..._v, unreadCounts: 0 } : _v,
        ),
      ])
    },
    [onGetChatMessages],
  )

  const onReceiveSingleChatForStudent = useCallback(
    async (chat: IChat) => {
      joinChat(chat._id, true)
      setCurrentChat(chat)
    },
    [joinChat],
  )

  useEffect(() => {
    if (wss.current) return
    wss.current = io(WSS_ENDPOINT, {
      query: {
        authToken: `Bearer ${getStorageValue(AUTH_TOKEN_KEY)}`,
      },
    })
    wss.current.connect()
    wss.current.on('connect', () => {
      console.log('connected')
    })
    wss.current.on('singleChat', onReceiveSingleChatForStudent)

    wss.current.on('receiveChat', onReceiveChat)

    wss.current.on('onReceive', onMessage)

    return () => {
      if (!wss.current) return
      wss.current.disconnect()
      wss.current.off('onReceive', onMessage)
      wss.current.off('receiveChat', onReceiveChat)
      wss.current.off('singleChat', onReceiveSingleChatForStudent)
      wss.current = undefined
    }
  }, [onReceiveChat, onMessage, onReceiveSingleChatForStudent])

  useEffect(() => {
    if (!me?._id) return
    if (!isSchoolOwner && !isAdmin && wss.current) {
      wss.current.emit('fetchChat')
    } else if ((isAdmin || isSchoolOwner) && wss.current) {
      getChats()
    }
  }, [me, getChats, isAdmin, isSchoolOwner])

  useEffect(() => {
    // @ts-ignore
    window?.updateUnread?.(
      chats.reduce((_v, current) => _v + current.unreadCounts, 0),
    )
  }, [chats])

  const sendMessage = useCallback((text: string) => {
    if (!prevChatId.current) return
    wss.current?.emit('send', { chatId: prevChatId.current, text })
  }, [])

  const value = useMemo(
    () => ({ chats, messages, currentChat, joinChat, sendMessage }),
    [chats, messages, currentChat, joinChat, sendMessage],
  )

  return (
    <SupportContext.Provider value={value}>{children}</SupportContext.Provider>
  )
}
