import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { IDoctoratesResponse, ISubmissionForm, TFunc } from '@/types'
import {
  useUploadDocuments,
  ICreateRequestPayload,
  useGetDoctorateRequests,
  useUpdateDoctorateRequest,
  useCreateDoctorateRequest,
} from '@/apis'
import { useAuth } from '@/hooks'

interface IPaymentDetails {
  id: string
  amount?: number
  declined?: boolean
  accepted?: boolean
}

interface IDoctorateContext {
  data: {
    nominees: ISubmissionForm[]
    declined: ISubmissionForm[]
    accepted: ISubmissionForm[]
  }
  requestsLoading: boolean
  onGetRequests: () => void
  onUploadDocuments: (_documents: File[]) => Promise<string[]>
  onCreateDoctorateRequest: (_payload: ICreateRequestPayload) => Promise<any>
  onUpdateDoctorateRequest: (_details: IPaymentDetails, _cb?: TFunc) => void
}

export const DoctorateContext = createContext<IDoctorateContext>({
  data: {
    nominees: [],
    declined: [],
    accepted: [],
  },
  requestsLoading: false,
  onGetRequests: () => undefined,
  onUploadDocuments: async () => [],
  onUpdateDoctorateRequest: () => undefined,
  onCreateDoctorateRequest: async () => undefined,
})

const refetch_interval = 60 * 1000

export const DoctorateContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { isAdmin, onUpdateRequests } = useAuth()
  const [data, setData] = useState<{
    nominees: ISubmissionForm[]
    declined: ISubmissionForm[]
    accepted: ISubmissionForm[]
  }>({
    nominees: [],
    declined: [],
    accepted: [],
  })

  const onRequestsSuccess = useCallback(
    (_data: IDoctoratesResponse) => {
      setData(_data.data)
      onUpdateRequests(_data.data.nominees.length)
    },
    [onUpdateRequests],
  )

  const { mutateAsync: onUpdate } = useUpdateDoctorateRequest()
  const { mutateAsync: uploadDocuments } = useUploadDocuments()
  const { mutateAsync: createDoctorateRequest } = useCreateDoctorateRequest()
  const [getRequests, { isLoading: requestsLoading }] =
    useGetDoctorateRequests(onRequestsSuccess)

  const onUploadDocuments = useCallback(
    async (documents: File[]) => {
      const formData = new FormData()
      documents.forEach(_file => {
        formData.append('files', _file)
      })
      const response = await uploadDocuments(formData)
      return response.data.files
    },
    [uploadDocuments],
  )

  const onCreateDoctorateRequest = useCallback(
    async (payload: ICreateRequestPayload) => {
      const response = await createDoctorateRequest({
        ...payload,
      })

      return response.data
    },
    [createDoctorateRequest],
  )

  const onGetRequests = useCallback(() => {
    getRequests()
  }, [getRequests])

  useEffect(() => {
    if (!isAdmin) return
    onGetRequests()
    const interval = setInterval(() => {
      onGetRequests()
    }, refetch_interval)
    return () => {
      clearInterval(interval)
    }
  }, [onGetRequests, isAdmin])

  const onUpdateDoctorateRequest = useCallback(
    async (data: IPaymentDetails, cb?: TFunc) => {
      const { id, amount, accepted, declined } = data
      const paymentDetails: Record<string, boolean | number> = {}
      if (accepted && amount !== undefined) {
        paymentDetails['amount'] = amount
        paymentDetails['accepted'] = accepted
      } else if (declined) {
        paymentDetails['declined'] = declined
      }
      await onUpdate({
        id,
        paymentDetails,
      })
      onGetRequests()
      cb?.()
    },
    [onUpdate, onGetRequests],
  )

  const value = useMemo(
    () => ({
      data,
      onGetRequests,
      requestsLoading,
      onUploadDocuments,
      onCreateDoctorateRequest,
      onUpdateDoctorateRequest,
    }),
    [
      data,
      onGetRequests,
      requestsLoading,
      onUploadDocuments,
      onCreateDoctorateRequest,
      onUpdateDoctorateRequest,
    ],
  )

  return (
    <DoctorateContext.Provider value={value}>
      {children}
    </DoctorateContext.Provider>
  )
}
