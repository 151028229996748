import React, { useCallback, useMemo, useState } from 'react'
import { PlusIcon } from '@heroicons/react/outline'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { IRole, roles } from '@/types'
import { supportPath } from '@/utils'
import { Button } from '@/components'
import { useAuth } from '@/hooks'

import { CreateConsultation } from './createConsultation'
import { MobileMenu } from './mobileMenu'
import { sidebarLinks } from './sidebar'

export const Header: React.FC = () => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const { onSignOut, me, onChooseRole } = useAuth()
  const [unreadCount, setUnreadCount] = useState(0)
  const [isOpenCreating, setIsOpenCreating] = useState(false)

  // @ts-ignore
  window.updateUnread = useCallback((_count: number) => {
    setUnreadCount(_count)
  }, [])

  const items = useMemo(() => sidebarLinks(me?.role, !!me), [me])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const _onCloseCreating = useCallback(() => {
    setIsOpenCreating(false)
  }, [])

  const _onOpenCreating = useCallback(() => {
    setIsOpenCreating(true)
  }, [])

  return (
    <>
      <div className="bg-zinc-50 text-black p-4 flex justify-between">
        <MobileMenu
          items={items}
          onOpen={onOpen}
          isOpen={isOpen}
          onClose={onClose}
        />
        <div className="flex items-center max-w-md w-full mx-left">
          <div className="flex items-center -space-x-4">
            {me?.roles?.map((_v, idx) => (
              <span
                key={idx}
                aria-label={roles[_v]}
                onClick={() => onChooseRole(_v)}
                className={classNames(
                  'flex justify-center items-center text-white bg-gray-500 relative inline-block h-10 w-10',
                  {
                    'cursor-pointer object-center hover:z-10 focus:z-10': true,
                    'rounded-full border-2 object-cover': true,
                    'border-green-500': _v === me.role,
                    'border-gray-600': _v !== me.role,
                  },
                )}
              >
                {roles[_v].slice(0, 1)}
              </span>
            ))}
          </div>
          {/* <div className="mt-1 relative rounded-md shadow-sm">
          <input
            type="text"
            id="search"
            placeholder="Search..."
            className="focus:ring-blue-500 focus:outline-none block w-full sm:text-sm border-gray-300 rounded-md px-4 py-2"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <i className="fi fi-br-search h-5"></i>
          </div>
        </div> */}
        </div>
        <div className="flex items-center px-2">
          {me?.role === IRole.Student && (
            <Button
              onClick={_onOpenCreating}
              className="flex gap-1 items-center"
            >
              <PlusIcon className="w-4 h-4" />
              Create affiliate school
            </Button>
          )}
          <Button
            onClick={() => navigate(supportPath)}
            className="relative p-3 rounded-full focus:outline-none hover:bg-gray-200 flex"
          >
            <i className="fi fi-br-bell h-4"></i>
            {unreadCount > 0 && (
              <div
                className={classNames(
                  // eslint-disable-next-line max-len
                  `absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full -top-1 -end-1 dark:border-gray-900`,
                )}
              >
                {unreadCount > 9 ? '9+' : unreadCount}
              </div>
            )}
          </Button>

          <Button
            onClick={onSignOut}
            className="p-3 rounded-full focus:outline-none hover:bg-gray-200 flex"
          >
            <i className="fi fi-br-exit h-4"></i>
          </Button>

          {/* <div className="md:hidden p-3">
            <Button className="hover:text-white focus:outline-none flex">
              <i className="fi fi-br-menu-dots-vertical h-4"></i>
            </Button>
          </div> */}
          {isOpenCreating && <CreateConsultation onClose={_onCloseCreating} />}
        </div>
      </div>
    </>
  )
}
