import React, { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { TCreateAffiliateAdminFormSchema } from '@/types'
import { Button, Form, TextInput } from '@/components'
import { createAffiliateAdminSchema } from '@/utils'
import { useAdminCreateAffiliate } from '@/apis'
import { useHookForm } from '@/hooks'

export const CreateAffiliatePage: React.FC = () => {
  const navigate = useNavigate()

  const { mutateAsync: createAffiliate } = useAdminCreateAffiliate()

  const {
    handler: {
      register,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(createAffiliateAdminSchema)

  const onSubmit: SubmitHandler<TCreateAffiliateAdminFormSchema> = useCallback(
    async data => {
      const response = await createAffiliate(data)
      if (response.data.success) {
        navigate(-1)
      }
    },
    [createAffiliate, navigate],
  )

  return (
    <div
      className="p-4 w-full h-full overflow-auto"
      style={{ height: 'calc(100% - 72px)' }}
    >
      <div className="w-full bg-white p-8 rounded-md shadow-md">
        <Button
          onClick={() => navigate(-1)}
          className="text-gray-500 hover:text-gray-800 mb-4 inline-flex items-center"
        >
          <i className="fi fi-br-arrow-left h-5 mr-3"></i>
          Back
        </Button>
        <h2 className="text-2xl font-semibold mb-6">Create School</h2>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white p-8 rounded-md shadow w-full"
        >
          <div className="pl-4">
            <TextInput
              label="Name of school"
              type="text"
              placeholder="Enter the name"
              error={errors.name?.message}
              {...register('name')}
            />
            <div className="flex flex-wrap items-center gap-2">
              <TextInput
                label="Domain url"
                type="text"
                placeholder="Enter the domain url"
                error={errors.subDomain?.message}
                {...register('subDomain')}
              />
              <p>.unitylearn.org</p>
            </div>
            <TextInput
              type="email"
              label="School Owner Email"
              placeholder="Enter the email"
              error={errors.email?.message}
              {...register('email')}
            />
          </div>
          <h6 className="font-bold tracki mt-4 mb-4">Company contact info</h6>
          <div className="pl-4">
            <TextInput
              label="Company Name"
              type="text"
              placeholder="Enter the company name"
              error={errors.companyName?.message}
              {...register('companyName')}
            />
            <TextInput
              label="Company Phone Number"
              type="text"
              placeholder="+1 000 0000000"
              error={errors.companyNumber?.message}
              {...register('companyNumber')}
            />
            <TextInput
              label="Postcode"
              type="number"
              placeholder="Enter the postal code"
              error={errors.postCode?.message}
              {...register('postCode')}
            />
            <TextInput
              label="Website URL"
              type="text"
              placeholder="Enter the link"
              error={errors.websiteUrl?.message}
              {...register('websiteUrl')}
            />
          </div>
          <h6 className="font-bold tracki mt-4 mb-4">
            Administrator Information
          </h6>
          <div className="pl-4">
            <TextInput
              label="First Name"
              type="text"
              placeholder="Enter (BRN)"
              error={errors.contact?.firstName?.message}
              {...register('contact.firstName')}
            />
            <TextInput
              label="Second Name"
              type="text"
              placeholder="Enter second name"
              error={errors.contact?.lastName?.message}
              {...register('contact.lastName')}
            />
            <TextInput
              label="Phone Number"
              type="text"
              placeholder="+1 000 0000000"
              error={errors.contact?.phoneNumber?.message}
              {...register('contact.phoneNumber')}
            />
            <TextInput
              label="Email"
              type="email"
              placeholder="Enter the email"
              error={errors.contact?.email?.message}
              {...register('contact.email')}
            />
            <TextInput
              label="Mailing address"
              type="text"
              placeholder="Enter the address"
              error={errors.contact?.mailingAddress?.message}
              {...register('contact.mailingAddress')}
            />
          </div>
          <h6 className="font-bold tracki mt-4 mb-4">Financial information</h6>
          <div className="pl-4">
            <TextInput
              label="Bank name"
              type="text"
              placeholder="Enter the bank name"
              error={errors.bankName?.message}
              {...register('bankName')}
            />
            <TextInput
              label="Billing address"
              type="text"
              placeholder="Enter the address"
              error={errors.bankAddress?.message}
              {...register('bankAddress')}
            />
            <TextInput
              label="Branch address"
              type="text"
              placeholder="Enter the address"
              error={errors.bankBranchAddress?.message}
              {...register('bankBranchAddress')}
            />
          </div>
          <Button
            type="submit"
            className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-700"
          >
            Create School
          </Button>
        </Form>
      </div>
    </div>
  )
}
