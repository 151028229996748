import React from 'react'
import moment from 'moment'

import { IAffiliateDetails } from '@/types'
import { useShareAffiliate } from '@/hooks'
import { DATE_FORMAT } from '@/constants'

export const AffiliateRow: React.FC<IAffiliateDetails> = ({ ...item }) => {
  const { onPreview } = useShareAffiliate()
  return (
    <tr
      onClick={() => onPreview(item)}
      className="cursor-pointer hover:bg-gray-100"
    >
      <td className="py-2 px-4 border-b text-start">{item.name}</td>
      <td className="py-2 px-4 border-b text-start">
        <a
          target="_blank"
          className="text-blue-600"
          href={`https://${item.subDomain}.unitylearn.org`}
        >
          https://{item.subDomain}.unitylearn.org
        </a>
      </td>
      <td className="py-2 px-4 border-b text-start">
        {moment(item.createdAt).format(DATE_FORMAT)}
      </td>
      <td className="py-2 px-4 border-b text-start">
        {item.isActive ? 'Paid' : 'Not paid'}
      </td>
    </tr>
  )
}
