import {
  homePath,
  whatWeDo,
  ourMission,
  testimonials,
  privacyPolicyPath,
  honoraryDoctoratePath,
} from '@/utils'

interface ITab {
  path: string
  element?: string
  isButton?: boolean
  defaultTitle: string
}

export const navItems: ITab[] = [
  {
    path: homePath,
    element: whatWeDo,
    defaultTitle: 'What We Do',
  },
  {
    path: homePath,
    element: ourMission,
    defaultTitle: 'Our Mission',
  },
  {
    path: homePath,
    element: testimonials,
    defaultTitle: 'Testimonials',
  },
  {
    path: honoraryDoctoratePath,
    defaultTitle: 'Honorary Doctorate',
  },
  {
    path: privacyPolicyPath,
    defaultTitle: 'Privacy policy',
  },
]
