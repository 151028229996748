import type {
  IEditorModel,
  IPlayerModel,
  IContentMetadata,
} from '@lumieducation/h5p-server'

import { AUTH_TOKEN_KEY } from '@/constants'
import { getStorageValue } from '@/utils'

export interface IContentListEntry {
  title: string
  contentId: string
  mainLibrary: string
  originalNewKey?: string
}

export interface IContentService {
  delete(_contentId: string): Promise<void>
  getEdit(_contentId: string): Promise<IEditorModel>
  getPlay(
    _contentId: string,
    _contextId?: string,
    _asUserId?: string,
    _readOnlyState?: boolean,
  ): Promise<IPlayerModel>
  list(): Promise<IContentListEntry[]>
  save(
    _contentId: string,
    _requestBody: { library: string; params: any },
  ): Promise<{ contentId: string; metadata: IContentMetadata }>
  generateDownloadLink(_contentId: string): string
}

export class ContentService implements IContentService {
  getToken = () => `Bearer ${getStorageValue(AUTH_TOKEN_KEY) || ''}`
  delete = async (contentId: string): Promise<void> => {
    console.log(`ContentService: deleting ${contentId}...`)
    const result = await fetch(`/h5p/${contentId}`, {
      method: 'delete',
      headers: {
        Authorization: this.getToken(),
      },
    })
    if (!result.ok) {
      throw new Error(
        `Error while deleting content: ${result.status} ${
          result.statusText
        } ${await result.text()}`,
      )
    }
  }

  getEdit = async (contentId: string): Promise<IEditorModel> => {
    console.log(`ContentService: Getting information to edit ${contentId}...`)
    const res = await fetch(`/h5p/${contentId}/edit`, {
      headers: {
        Authorization: this.getToken(),
      },
    })
    if (!res || !res.ok) {
      throw new Error(`${res.status} ${res.statusText}`)
    }
    return res.json()
  }

  getPlay = async (
    contentId: string,
    contextId?: string,
    asUserId?: string,
    readOnlyState?: boolean,
  ): Promise<IPlayerModel> => {
    console.log(
      `ContentService: Getting information to play ${contentId}${
        contextId ? `, contextId ${contextId}` : ''
      }${asUserId ? `, asUserId ${asUserId}` : ''}${
        readOnlyState !== undefined ? `, readOnlyState ${readOnlyState}` : ''
      }...`,
    )

    const query = new URLSearchParams()
    if (contextId) {
      query.append('contextId', contextId)
    }
    if (asUserId) {
      query.append('asUserId', asUserId)
    }
    if (readOnlyState === true) {
      query.append('readOnlyState', 'yes')
    }

    const queryString = query.toString()

    const res = await fetch(
      `/h5p/${contentId}/play${queryString ? `?${queryString}` : ''}`,
      {
        headers: {
          Authorization: this.getToken(),
        },
      },
    )
    if (!res || !res.ok) {
      throw new Error(`${res.status} ${res.statusText}`)
    }
    return res.json()
  }

  list = async (): Promise<IContentListEntry[]> => {
    console.log(`ContentService: Listing content objects`)
    const result = await fetch('/h5p', {
      headers: {
        Authorization: this.getToken(),
      },
    })
    if (result.ok) {
      return result.json()
    }
    throw new Error(
      `Request to REST endpoint returned ${result.status} ${
        result.statusText
      }: ${await result.text()}`,
    )
  }

  save = async (
    contentId: string,
    requestBody: { library: string; params: any },
  ): Promise<{ contentId: string; metadata: IContentMetadata }> => {
    if (contentId) {
      console.log(`ContentService: Saving new content.`)
    } else {
      console.log(`ContentService: Saving content ${contentId}`)
    }

    const body = JSON.stringify(requestBody)

    const res = await fetch(`/h5p/${contentId || ''}`, {
      method: contentId ? 'PATCH' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.getToken(),
      },
      body,
    })

    if (!res || !res.ok) {
      throw new Error(`${res.status} ${res.statusText} - ${await res.text()}`)
    }
    return res.json()
  }

  generateDownloadLink = (contentId: string): string =>
    `/h5p/download/${contentId}`
}
