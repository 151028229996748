import React, { useMemo } from 'react'

import { AppFooter, AppHeader } from '@/layout'
import { Templates } from '@/types'
import { useBrand } from '@/hooks'

import {
  TemplateNPage,
  DefaultTemplate,
  TemplateNPageFooter,
  TemplateNPageHeader,
} from '../Templates'

import { HomeLayout } from './components'

const Headers: Record<Templates, React.FC> = {
  template_1: TemplateNPageHeader,
  default_template: AppHeader,
}

const Footers: Record<Templates, React.FC> = {
  template_1: TemplateNPageFooter,
  default_template: AppFooter,
}

const PagesContent: Record<Templates, React.FC> = {
  template_1: TemplateNPage,
  default_template: DefaultTemplate,
}

export const HomePage: React.FC = () => {
  const { template, isLoading } = useBrand()

  const _template = useMemo(
    () => template?.name ?? Templates.DEFAULT_TEMPLATE,
    [template?.name],
  )

  const Header = Headers[_template]
  const Footer = Footers[_template]
  const Content = PagesContent[_template]
  if (isLoading) {
    return null
  }
  return (
    <HomeLayout Header={Header} Footer={Footer}>
      <Content />
    </HomeLayout>
  )
}
