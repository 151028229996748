import React from 'react'
import classNames from 'classnames'

import { IPageJumpButtonProps } from './types'
import { Button } from '../Button'

const PageJumpButton: React.FC<IPageJumpButtonProps> = ({
  disabled,
  toNext,
  defaultBtn,
  onClick,
}) => {
  if (defaultBtn) {
    return <>{defaultBtn(onClick)}</>
  }

  return (
    <Button
      onClick={onClick}
      className={classNames(
        'text-black py-2 px-4 rounded cursor-pointer hover:bg-green-500',
        {
          disabled,
          'bg-gray-200': !toNext,
          'bg-green-300': toNext,
        },
      )}
    >
      {toNext ? `Next` : 'Previous'}
    </Button>
  )
}

export default PageJumpButton
