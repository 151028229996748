import React from 'react'

import { ConsultationStatuses, IConsultation, TFunc } from '@/types'
import { Button } from '@/components'

export const ConsultationRow: React.FC<
  IConsultation & { onAllow: TFunc; onReject: TFunc }
> = ({ onAllow, onReject, ...item }) => {
  return (
    <tr className="cursor-pointer hover:bg-gray-100">
      <td className="py-2 px-4 border-b text-start">{item.firstName}</td>
      <td className="py-2 px-4 border-b text-start">{item.lastName}</td>
      <td className="py-2 px-4 border-b text-start">{item.email}</td>
      <td className="py-2 px-4 border-b text-start">{item.phoneNumber}</td>
      <td className="py-2 px-4 border-b text-start">
        {item.status === ConsultationStatuses.REJECTED
          ? 'Rejected'
          : item.status === ConsultationStatuses.ALLOWED
            ? 'Allowed'
            : 'Progress'}
      </td>
      <td className="py-2 px-4 border-b text-right">
        {item.status === 0 && (
          <div className="flex flex-nowrap">
            <Button
              type="button"
              onClick={onAllow}
              className="rounded-full bg-green-400 text-white font-medium font-lg mx-2 px-6 py-2 hover:bg-green-500"
            >
              Allow
            </Button>
            <Button
              type="button"
              onClick={onReject}
              className="rounded-full bg-red-400 text-white font-medium font-lg mx-2 px-6 py-2 hover:bg-red-500"
            >
              Reject
            </Button>
          </div>
        )}
      </td>
    </tr>
  )
}
