import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

import { DataTable, WithPaginator } from '@/components'
import { useAffiliateUsers } from '@/apis'
import { IPageMeta, IUser } from '@/types'
import { editPath } from '@/utils'
import { useRoles } from '@/hooks'

import { UserRow } from './components'

const headers = [
  { label: 'Name', key: 'name' },
  { label: 'Email', key: 'email' },
  { label: 'Roles', key: 'roles' },
  { label: 'Action', key: 'action' },
]

export const RolesPage: React.FC = () => {
  const navigate = useNavigate()
  const { onEditRole } = useRoles()
  const [affiliateUsers, setAffiliateUsers] = useState<IUser[]>([])

  const onUsersSuccess = useCallback((_data: IUser[]) => {
    setAffiliateUsers(_data)
  }, [])

  const [getUsers] = useAffiliateUsers(onUsersSuccess)

  useEffect(() => {
    getUsers()
  }, [getUsers])

  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    page: 1,
    pageSize: 10,
  })

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const pageData = useMemo(() => {
    const firstPageIndex = (pageMeta.page - 1) * (pageMeta.pageSize || 10)
    const lastPageIndex = firstPageIndex + (pageMeta.pageSize || 10)
    return affiliateUsers.slice(firstPageIndex, lastPageIndex)
  }, [affiliateUsers, pageMeta.page, pageMeta.pageSize])

  const onEdit = useCallback(
    (proctor: IUser) => () => {
      onEditRole(proctor)
      navigate(editPath)
    },
    [onEditRole, navigate],
  )

  return (
    <div className="p-4 w-full">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-3xl font-semibold mb-4">Roles</h2>
      </div>
      <WithPaginator
        data={pageData}
        initialPageSize={10}
        onPageMetaChange={onPageMetaChange}
        totalItems={affiliateUsers.length}
      >
        {pagedData => (
          <DataTable data={pagedData} headers={headers}>
            {data => {
              return data.map((item, idx) => (
                <UserRow key={idx} {...item} onEdit={onEdit(item)} />
              ))
            }}
          </DataTable>
        )}
      </WithPaginator>
    </div>
  )
}
