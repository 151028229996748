import { FormError } from '@/components'
import classNames from 'classnames'
import React, { useCallback } from 'react'

interface IUploadImageProps {
  error?: string
  inputId?: string
  className?: string
  imagePath?: string
  maxWidth?: string | number
  onSelect: (_file: File, _path?: string) => void
}

export const UploadImage: React.FC<IUploadImageProps> = ({
  error,
  inputId,
  onSelect,
  className,
  imagePath,
  maxWidth = 140.16,
}) => {
  const handleImageUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore
      const file = e.target.files?.[0]
      if (!file) return
      const reader = new FileReader()

      reader.onloadend = () => {
        const base64String = reader.result
        onSelect(file, base64String as string | undefined)
      }

      reader.readAsDataURL(file)
    },
    [onSelect],
  )

  return (
    <div className={classNames('flex items-center justify-center', className)}>
      <label
        htmlFor={inputId}
        style={{ minHeight: 140.16, maxWidth }}
        // eslint-disable-next-line max-len
        className="flex flex-col items-center justify-center w-full px-4 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >
        {imagePath ? (
          <img src={imagePath} style={{ height: '100%', objectFit: 'cover' }} />
        ) : (
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              fill="none"
              viewBox="0 0 20 16"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
            >
              <path
                strokeWidth="2"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                // eslint-disable-next-line max-len
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <span className="font-semibold">Click to upload</span>
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              SVG, PNG or JPG
            </p>
          </div>
        )}
        <input
          type="file"
          id={inputId}
          className="hidden"
          accept=".svg, .png, .jpg, .jpeg"
          onChange={handleImageUpload}
        />
      </label>
      {!!error && <FormError error={error} />}
    </div>
  )
}
