import React from 'react'

import { FormError } from '@/components'

import { ITextAreaProps } from './types'

export const TextArea = React.forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  ({ label, error, className = '', onChange, ...rest }, ref) => {
    return (
      <div className="mb-4">
        {!!label && (
          <label className="block text-sm font-medium text-black">
            {label}
          </label>
        )}
        <textarea
          {...rest}
          ref={ref}
          onChange={onChange}
          className={className}
        />
        <FormError error={error} />
      </div>
    )
  },
)
