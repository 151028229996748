import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

import { Button, DataTable, WithPaginator } from '@/components'
import { IAffiliateDetails, IPageMeta } from '@/types'
import { useAffiliates } from '@/apis'
import { newPath } from '@/utils'

import { AffiliateRow } from './components'

export const AffiliatesPage: React.FC = () => {
  const navigate = useNavigate()
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    page: 1,
    pageSize: 10,
  })
  const [affiliates, setAffiliates] = useState<IAffiliateDetails[]>([])

  const onAffiliatesSuccess = useCallback((_data: IAffiliateDetails[]) => {
    setAffiliates(_data)
  }, [])

  const [getAffiliates] = useAffiliates(onAffiliatesSuccess)

  useEffect(() => {
    getAffiliates()
  }, [getAffiliates])

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const pageData = useMemo(() => {
    const firstPageIndex = (pageMeta.page - 1) * (pageMeta.pageSize || 10)
    const lastPageIndex = firstPageIndex + (pageMeta.pageSize || 10)
    return affiliates.slice(firstPageIndex, lastPageIndex)
  }, [affiliates, pageMeta.page, pageMeta.pageSize])

  const headers = useMemo(() => {
    const _headers = [
      { label: 'School Name', key: 'name' },
      { label: 'Domain', key: 'subDomain' },
      { label: 'Created At', key: 'createdAt' },
      { label: 'Status', key: 'payment' },
    ]

    return _headers
  }, [])

  const onCreate = useCallback(() => {
    navigate(newPath)
  }, [navigate])

  return (
    <div className="p-4 w-full">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-3xl font-semibold mb-4">Schools</h2>
        <Button
          onClick={onCreate}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Create School
        </Button>
      </div>
      <WithPaginator
        data={pageData}
        initialPageSize={10}
        totalItems={affiliates.length}
        onPageMetaChange={onPageMetaChange}
      >
        {pagedData => (
          <DataTable data={pagedData} headers={headers}>
            {data => {
              return data.map((item, idx) => (
                <AffiliateRow key={idx} {...item} />
              ))
            }}
          </DataTable>
        )}
      </WithPaginator>
    </div>
  )
}
