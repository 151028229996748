import React from 'react'

import { useCourse, useH5P } from '@/hooks'
import { TProgress } from '@/types'

import { CreatePlanSchema } from '../shared'

export const CreateCoursePage: React.FC = () => {
  const { course } = useCourse()
  const { onUpdateCourse } = useH5P()

  return (
    <CreatePlanSchema
      plan={course}
      type={TProgress.COURSE}
      onUpdate={onUpdateCourse}
    />
  )
}
