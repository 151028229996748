import {
  IFeedbackPayload,
  IFeedbacksResponse,
  ICreateFeedbackResponse,
} from '@/types'

import { useLazyGet, usePost } from '../utils/wrapper'
import endpoints from '../endpoints'

export const useGetFeedbacks = (
  onSuccess: (_data: IFeedbacksResponse) => void,
) => {
  return useLazyGet(endpoints.feedbacks, undefined, {
    onSuccess,
  })
}

export const useCreateFeedback = () => {
  return usePost<IFeedbackPayload, ICreateFeedbackResponse>(
    endpoints.createFeedback,
    undefined,
  )
}
