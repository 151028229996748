import React from 'react'
import { Outlet } from 'react-router-dom'

import { Header, Sidebar } from './components'

export const MainLayout: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div className="app-pages">
      <div className="flex h-screen w-screen">
        <Sidebar />
        <main className="ml-0 w-full">
          <Header />
          {children || <Outlet />}
        </main>
      </div>
    </div>
  )
}
