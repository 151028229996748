import {
  IUser,
  IResponse,
  IUploadResponse,
  IUpdateProfilePayload,
  IChooseRolePayload,
} from '@/types'

import { useLazyGet, usePost, usePut } from '../utils/wrapper'
import endpoints from '../endpoints'
import { IUserDetails } from '@/context'

export const useGetMe = (onSuccess: (_data: IUser) => void) => {
  return useLazyGet(endpoints.me, undefined, {
    onSuccess,
  })
}

export const useGetUsers = (
  onSuccess: (_data: { data: IUserDetails[] }) => void,
) => {
  return useLazyGet(endpoints.users, undefined, {
    onSuccess,
  })
}

export const useUploadAvatar = () => {
  return usePost<FormData, IUploadResponse>(endpoints.uploadAvatar, undefined)
}

export const useUpdateProfile = () => {
  return usePut<IUpdateProfilePayload, IResponse>(endpoints.updateProfile)
}

export const useChooseRole = () => {
  return usePut<IChooseRolePayload, IUser>(endpoints.chooseRole, undefined)
}

export const useCreateDocument = () => {
  return usePost<FormData, IResponse>(endpoints.createDocument)
}
