import React from 'react'
import { Switch } from '@headlessui/react'

import { ISwitchButtonProps } from './types'
import './styles.scss'

export const SwitchButton: React.FC<ISwitchButtonProps> = ({
  checked,
  disabled,
  onChange,
}) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className="group switch-button"
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className="thumb group-data-[checked]:translate-x-5"
      />
    </Switch>
  )
}
