import React, { useCallback, useRef } from 'react'
import { SubmitHandler } from 'react-hook-form'

import { Button, Form, FormError, Modal, TextArea } from '@/components'
import { useFeedback, useHookForm, useOnClickOutside } from '@/hooks'
import { TFeedbackFromSchema } from '@/types'
import { feedbackForm } from '@/utils'

export const FeedbackForm: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { showFeedbackView, toggleFeedbackView, onCreateFeedback } =
    useFeedback()

  const onClose = useCallback(() => {
    toggleFeedbackView(false, undefined)
  }, [toggleFeedbackView])

  useOnClickOutside(ref, true, onClose)

  const {
    handler: {
      trigger,
      register,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(feedbackForm)

  const onSubmit: SubmitHandler<TFeedbackFromSchema> = useCallback(
    async data => {
      onCreateFeedback(data)
    },
    [onCreateFeedback],
  )

  return (
    <Modal isOpen={showFeedbackView} onClose={onClose}>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="sm:flex flex-col sm:items-start"
        >
          <div className="w-full">
            <TextArea
              rows={4}
              style={{ resize: 'none' }}
              label="Provide Your Feedback"
              className="mt-1 p-2 w-full border rounded-md"
              value={getValues('comment')}
              {...register('comment', {
                onChange: () => {
                  trigger('comment')
                },
              })}
            />
          </div>
          <div className="flex w-full justify-end">
            <Button
              type="submit"
              className="bg-green-300 text-black py-2 px-4 mb-5 rounded cursor-pointer hover:bg-green-500"
            >
              Submit
            </Button>
          </div>
          {!!errors.comment?.message && (
            <FormError error={errors.comment.message} />
          )}
        </Form>
      </div>
    </Modal>
  )
}
