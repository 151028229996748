import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'

import { generateFilePath } from '@/utils'
import { DATE_FORMAT } from '@/constants'
import { useAssignTask } from '@/apis'
import { ITask, TFunc } from '@/types'
import { Button } from '@/components'

export const TaskRow: React.FC<ITask & { onRefresh: TFunc }> = ({
  onRefresh,
  ...props
}) => {
  const [task, setTask] = useState<ITask>()

  const { mutateAsync: assignTask } = useAssignTask()

  useEffect(() => {
    setTask(props)
  }, [props])

  const openFile = useCallback(
    (filePath?: string) => () => {
      if (!filePath) return
      window.open(generateFilePath(filePath) as string, '__blank')
    },
    [],
  )

  const onAssign = useCallback(async () => {
    if (!task) return
    const response = await assignTask({
      id: task?._id,
    })
    if (response.data.success) {
      onRefresh()
    }
  }, [assignTask, onRefresh, task])

  if (!task) return null

  return (
    <tr className="cursor-pointer hover:bg-gray-100">
      <td className="py-2 px-4 border-b text-start">{task?.createdBy?.name}</td>
      <td className="py-2 px-4 border-b text-start">
        {task?.createdBy?.email}
      </td>
      <td className="py-2 px-4 border-b text-start">
        {task?.createdBy?.affiliate?.name ?? '-'}
      </td>
      <td className="py-2 px-4 border-b text-start">
        {task?.path ? (
          <Button
            onClick={openFile(task?.path)}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Result
          </Button>
        ) : (
          '-'
        )}
      </td>
      <td className="py-2 px-4 border-b text-start">
        {moment(task.date).format(DATE_FORMAT)}
      </td>
      <td className="py-2 px-4 flex justify-center">
        <Button
          type="button"
          onClick={onAssign}
          className="rounded-full bg-green-400 text-white font-medium font-lg mx-2 px-6 py-2 hover:bg-green-500"
        >
          Assign me
        </Button>
      </td>
    </tr>
  )
}
