import React, { useCallback } from 'react'
import classNames from 'classnames'

import { IAvatarProps } from './types'

import avatar from '@/assets/images/client-avatar.png'

export const Avatar: React.FC<IAvatarProps> = ({
  inputId,
  onSelect,
  size = 40,
  className,
  imagePath,
  disabled = false,
}) => {
  const handleImageUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore
      const file = e.target.files?.[0]
      if (!file) return
      const reader = new FileReader()

      reader.onloadend = () => {
        const base64String = reader.result
        onSelect(file, base64String as string | undefined)
      }

      reader.readAsDataURL(file)
    },
    [onSelect],
  )

  return (
    <div className={classNames('', className)}>
      <input
        id={inputId}
        type="file"
        accept="image/*"
        className="hidden"
        disabled={disabled}
        onChange={handleImageUpload}
      />
      <label
        htmlFor={inputId}
        className={classNames({
          'cursor-pointer': !disabled,
        })}
      >
        <img
          className={classNames('rounded rounded-full border', {
            [`w-${size}`]: true,
            [`h-${size}`]: true,
          })}
          src={imagePath || avatar}
        />
      </label>
      <p className="text-xs text-gray-500 mt-2">
        Upload a profile picture (JPEG, PNG, or GIF).
      </p>
    </div>
  )
}
