import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'

import { IPlan, CourseFilters } from '@/types'
import { DATE_FORMAT } from '@/constants'
import { useAuth } from '@/hooks'

export const QuizRow: React.FC<
  IPlan & {
    activeFilter?: CourseFilters
    onSelectTest: (_test?: IPlan, _isAdmin?: boolean) => () => void
  }
> = ({ onSelectTest, activeFilter = CourseFilters.AFFILIATE, ...test }) => {
  const { isAdmin, isSchoolOwner } = useAuth()
  const [item, setItem] = useState<IPlan>()
  const prevFilter = useRef<CourseFilters>(CourseFilters.AFFILIATE)

  useEffect(() => {
    if (!item) {
      setItem(test)
    }
  }, [item, test])

  useEffect(() => {
    if (prevFilter.current !== activeFilter) {
      prevFilter.current = activeFilter
      setItem(test)
    }
  }, [activeFilter, test])

  if (!item) return null

  return (
    <tr
      className="cursor-pointer hover:bg-gray-100"
      onClick={() => item.canStart && onSelectTest(item)()}
    >
      <td className="py-2 px-4 text-start">{item._id}</td>
      <td className="py-2 px-4 text-start">{item.title}</td>
      <td className="py-2 px-4 text-start">{item.description}</td>
      <td className="py-2 px-4 text-start">${item.price}</td>
      {(isAdmin || isSchoolOwner) && (
        <td className="py-2 px-4 text-start">
          <span
            className={classNames(
              'inline-block text-white text-sm px-3 py-1 rounded-full capitalize',
              {
                'bg-zinc-300': !item.visible,
                'bg-green-400': item.visible,
              },
            )}
          >
            {item.visible ? 'Published' : 'Draft'}
          </span>
        </td>
      )}
      <td className="py-2 px-4 text-start">
        {moment(item.createdAt).format(DATE_FORMAT)}
      </td>
    </tr>
  )
}
