import React from 'react'

import { DataTable, WithPaginator } from '@/components'
import { useIncomes } from '@/apis'
import { useAuth } from '@/hooks'

import { incomeHeaders } from '../constant'
import { IncomeRow } from './incomeRow'

export const IncomesTab: React.FC = () => {
  const { me } = useAuth()

  const { summary, data, pageMeta, total, onPageMetaChange } = useIncomes(
    !!me?._id,
  )

  return (
    <React.Fragment>
      <WithPaginator
        data={data}
        totalItems={total}
        onPageMetaChange={onPageMetaChange}
        initialPageSize={pageMeta.pageSize}
      >
        {pagedData => (
          <>
            <DataTable
              className="min-h-96"
              data={pagedData}
              headers={incomeHeaders}
              footer={
                <tr>
                  <td className="py-2 px-4 font-bold text-start">Total</td>
                  <td className="py-2 px-4 font-bold text-end" colSpan={5}>
                    ${summary}
                  </td>
                </tr>
              }
            >
              {data => {
                return data.map((item, idx) => (
                  <IncomeRow key={idx} index={idx + 1} {...item} />
                ))
              }}
            </DataTable>
          </>
        )}
      </WithPaginator>
    </React.Fragment>
  )
}
