import { BrowserRouter as Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

import { QueryProvider, queryClient } from '@/apis'
import { AuthContextProvider } from '@/context'
import { AppRoutes } from '@/navigation/routes'

import './App.scss'

function App() {
  return (
    <SnackbarProvider>
      <QueryProvider client={queryClient}>
        <Router>
          <AuthContextProvider>
            <AppRoutes />
          </AuthContextProvider>
        </Router>
      </QueryProvider>
    </SnackbarProvider>
  )
}

export default App
