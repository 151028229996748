import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button, DataTable, WithPaginator } from '@/components'
import { DocumentsContextProvider } from '@/context'
import { useAuth, useDocuments } from '@/hooks'
import { IPageMeta } from '@/types'

import { CreateDocument, DocumentRow } from './components'

const DocumentsView: React.FC = () => {
  const { me, isAdmin, isSchoolOwner } = useAuth()
  const { documents, onGetDocuments } = useDocuments()
  const [openCreating, setOpenCreating] = useState(false)

  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    page: 1,
    pageSize: 10,
  })

  useEffect(() => {
    onGetDocuments()
  }, [onGetDocuments])

  const isStudent = useMemo(
    () => !!me && !isAdmin && !isSchoolOwner,
    [isAdmin, me, isSchoolOwner],
  )

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const pageData = useMemo(() => {
    const firstPageIndex = (pageMeta.page - 1) * (pageMeta.pageSize || 10)
    const lastPageIndex = firstPageIndex + (pageMeta.pageSize || 10)
    return documents.slice(firstPageIndex, lastPageIndex)
  }, [documents, pageMeta.page, pageMeta.pageSize])

  const onClose = useCallback(
    (refresh?: boolean) => {
      setOpenCreating(false)
      if (refresh) {
        onGetDocuments()
      }
    },
    [onGetDocuments],
  )

  return (
    <div className="p-4 w-full">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-3xl font-semibold mb-4">Documents</h2>
        {(isAdmin || isSchoolOwner) && (
          <Button
            onClick={() => setOpenCreating(true)}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Create
          </Button>
        )}
      </div>
      {openCreating && <CreateDocument onClose={onClose} />}
      <WithPaginator
        data={pageData}
        initialPageSize={10}
        totalItems={documents.length}
        onPageMetaChange={onPageMetaChange}
      >
        {pagedData => (
          <DataTable
            data={pagedData}
            headers={[
              { label: 'Meeting link', key: 'link' },
              { label: 'Title', key: 'title' },
              { label: 'Type', key: 'type' },
              { label: 'Path', key: 'path' },
              { label: 'Diploma path', key: 'diplomaPath' },
              { label: 'Date', key: 'date' },
              ...(!isStudent
                ? [
                    { label: 'Name', key: 'name' },
                    { label: 'Email', key: 'email' },
                    { label: 'Actions', key: 'actions' },
                  ]
                : []),
            ]}
          >
            {data =>
              data.map((item, idx) => <DocumentRow key={idx} {...item} />)
            }
          </DataTable>
        )}
      </WithPaginator>
    </div>
  )
}

export const DocumentsPage: React.FC = () => (
  <DocumentsContextProvider>
    <DocumentsView />
  </DocumentsContextProvider>
)
