import React, { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'

import { Button, Form, Modal, TextInput } from '@/components'
import { Reference, TAddReferenceSchema } from '@/types'
import { referenceForm } from '@/utils'
import { useHookForm } from '@/hooks'

interface IAddReferenceProps {
  isOpen?: boolean
  onClose?: () => void
  onSave?: (_ref?: Reference) => void
}

export const AddReference: React.FC<IAddReferenceProps> = ({
  onSave,
  onClose,
  isOpen = false,
}) => {
  const {
    handler: {
      reset,
      trigger,
      register,
      getValues,
      clearErrors,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(referenceForm)

  const onSubmit: SubmitHandler<TAddReferenceSchema> = useCallback(
    data => {
      onSave?.({
        name: data.name,
        contactDetails: {
          phone: data.phone,
          email: data.email,
        },
      })
      reset({ email: '', phone: '', name: '' })
      onClose?.()
    },
    [onSave, reset, onClose],
  )

  const _onClose = useCallback(() => {
    clearErrors('email')
    clearErrors('name')
    clearErrors('phone')
    onClose?.()
  }, [clearErrors, onClose])

  return (
    <Modal isOpen={isOpen} onClose={_onClose} canClose>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="w-full">
              <TextInput
                label="Name"
                error={errors.name?.message}
                value={getValues('name')}
                {...register('name', {
                  onChange: () => {
                    trigger('name')
                  },
                })}
              />
              <TextInput
                label="Email"
                error={errors.email?.message}
                value={getValues('email')}
                {...register('email', {
                  onChange: () => {
                    trigger('email')
                  },
                })}
              />
              <TextInput
                label="Phone"
                error={errors.phone?.message}
                value={getValues('phone')}
                {...register('phone', {
                  onChange: () => {
                    trigger('phone')
                  },
                })}
              />
            </div>
          </div>
        </div>
        <div className="bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <Button
            type="submit"
            className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
          >
            Add Reference
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
