import React, { useEffect, useRef } from 'react'
import { motion, useAnimation } from 'framer-motion'

import { useOnScreen } from '@/hooks'

export const Lazy = ({ children }: { children: React.ReactChild }) => {
  const controls = useAnimation()
  const rootRef = useRef<HTMLDivElement>(null)
  const onScreen = useOnScreen(rootRef)
  useEffect(() => {
    if (onScreen) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: 'easeOut',
        },
      })
    }
  }, [onScreen, controls])
  return (
    <motion.div
      ref={rootRef}
      animate={controls}
      initial={{ opacity: 0, x: -50 }}
    >
      {children}
    </motion.div>
  )
}
