import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'

import { IStartedProgress, IStatus, TFunc } from '@/types'
import { useFinishQuiz, useRescheduleQuiz } from '@/apis'
import { Button } from '@/components'
import { useToast } from '@/hooks'

import { Player } from '../Admin/shared/components'
import { ChooseDate } from './chooseDate'

interface IPlanWidget {
  onClose: TFunc
  isAdmin: boolean
  onRefresh: TFunc
  plan: IStartedProgress
}

export const PlanWidget: React.FC<IPlanWidget> = ({
  plan,
  onClose,
  isAdmin,
  onRefresh,
}) => {
  const { addToast } = useToast()
  const [progress, setProgress] = useState(0)
  const [isActive, setIsActive] = useState(0)
  const [currentProgress, setCurrentProgress] = useState<
    Record<string, number>
  >({})
  const [chooseDate, setChooseDate] = useState(false)

  const onOpenModule = useCallback(
    (id: number) => () => {
      setIsActive(id)
    },
    [],
  )

  const { mutateAsync: finishQuiz, isPending: isFinishLoading } =
    useFinishQuiz()
  const { mutateAsync: rescheduleQuiz } = useRescheduleQuiz()

  const onFinish = useCallback(
    async (date: string) => {
      const request = plan.reschedule ? rescheduleQuiz : finishQuiz
      const response = await request({
        date,
        id: plan.item._id,
      })
      if (response.data.success) {
        onClose()
        addToast('success', 'You have successfully completed your test')
        onRefresh()
      }
    },
    [
      addToast,
      onClose,
      onRefresh,
      finishQuiz,
      plan.item._id,
      rescheduleQuiz,
      plan.reschedule,
    ],
  )

  const onUpdateProgress = useCallback(
    (contentId: string, progress: number) => {
      setCurrentProgress(prev => ({
        ...prev,
        [contentId]: progress,
      }))
    },
    [],
  )

  const onSetProgress = useCallback((progress: number) => {
    setProgress(prev => prev + progress)
  }, [])

  const width = useMemo(() => {
    const _progress =
      plan.percent ??
      (Object.values(currentProgress).reduce((acc, cur) => acc + cur, 0) /
        (progress || 1)) *
        100

    return _progress > 100 ? 100 : _progress
  }, [currentProgress, progress, plan.percent])

  const fakeModules = useMemo(
    () =>
      plan?.item?.modules?.map(_v => {
        return _v.contents.map(content => (
          <Player
            key={content.id}
            contentId={content.id}
            setProgress={onSetProgress}
            onUpdateProgress={onUpdateProgress}
            editable={plan.status !== IStatus.Completed}
          />
        ))
      }),
    [onSetProgress, onUpdateProgress, plan?.item?.modules, plan.status],
  )

  const onCloseDate = useCallback(() => {
    setChooseDate(false)
  }, [])

  return (
    <div className="w-full bg-white p-4">
      {chooseDate && (
        <ChooseDate
          onChoose={onFinish}
          onClose={onCloseDate}
          title={plan.item.title}
        />
      )}
      {isAdmin && (
        <Button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-800 mb-4 inline-flex items-center"
        >
          <i className="fi fi-br-arrow-left h-5 mr-3"></i>
          Back
        </Button>
      )}
      <h2 className="text-2xl font-semibold mb-6 flex items-center justify-center gap-5">
        {plan.item.title}
        {!isAdmin && (
          <>
            <div className="w-1/2 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{
                  width: `${width}%`,
                }}
              ></div>
            </div>
            {width.toFixed(2)}%
          </>
        )}
      </h2>
      <div className="grid grid-rows-1 grid-flow-col gap-4 mb-4">
        <div className="rounded border col-span-9">
          {plan?.item?.modules?.[isActive]?.contents?.map?.(content => (
            <div key={content.id} className="shadow-sm">
              <div className="px-5 rounded">
                <div className="accordion-content pt-0 overflow-hidden transition">
                  <div className="accordion-header transition flex space-x-5 h-16 items-center justify-between">
                    <div className="flex gap-4">
                      <h3>{content.title}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <Player
                contentId={content.id}
                isFinishLoading={isFinishLoading}
                onUpdateProgress={onUpdateProgress}
                editable={plan.status !== IStatus.Completed}
              />
            </div>
          ))}
          <div className="hidden">
            {plan.status !== IStatus.Completed && fakeModules}
          </div>
        </div>
        <div className="rounded border col-span-3">
          <ul className="p-4">
            <li className="text-2xl font-semibold mb-2">Modules</li>
            {plan?.item?.modules?.map((item, idx) => (
              <li className="mb-2" key={idx}>
                <Button
                  disabled={idx === isActive}
                  className={classNames(
                    'w-full text-black font-bold py-2 px-4 rounded hover:bg-green-300 flex items-center justify-between',
                    {
                      'bg-zinc-100': idx !== isActive,
                      'bg-green-300': idx === isActive,
                    },
                  )}
                  onClick={onOpenModule(idx)}
                >
                  <div>{item.name}</div>
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {!isAdmin && (
        <div className="flex">
          {plan.reschedule && (
            <div>
              <Button
                onClick={() => setChooseDate(true)}
                className="bg-green-500 text-white mr-2 px-4 py-2 rounded-md hover:bg-green-600"
              >
                Reschedule
              </Button>
            </div>
          )}
          <div className="w-full flex justify-end">
            <Button
              onClick={onClose}
              className="bg-red-500 text-white mr-2 px-4 py-2 rounded-md hover:bg-red-600"
            >
              Leave
            </Button>
            {plan.status !== IStatus.Completed && (
              <Button
                onClick={() => setChooseDate(true)}
                disabled={width < 100}
                className={classNames(
                  'text-white px-4 py-2 rounded-md hover:bg-green-600',
                  {
                    'bg-green-500 cursor-pointer': width >= 100,
                    'pointer-events-none bg-green-300': width < 100,
                  },
                )}
              >
                Finish
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
