import React from 'react'

import { Button } from '@/components'

interface ITemplatePreviewProps {
  label: string
  image?: string
  onEdit?: () => void
}

export const TemplatePreview: React.FC<ITemplatePreviewProps> = ({
  label,
  image,
  onEdit,
}) => {
  return (
    <div className="templateItem">
      <div className="templateItemContent">
        <p>{label}</p>
        <Button
          type="button"
          onClick={onEdit}
          className="rounded-full bg-green-400 text-white font-medium font-lg mx-2 px-6 py-2 hover:bg-green-500"
        >
          Edit
        </Button>
      </div>
      <img src={image} alt={label} />
    </div>
  )
}
