import classNames from 'classnames'

import { ArgumentType } from '@/types'

import { ITabsProps } from './types'
import './styles.scss'

export const Tabs = <T extends ArgumentType>({
  tabs,
  onChange,
  activeTab,
  className,
  tabClassName,
}: ITabsProps<T>) => {
  return (
    <ul
      className={classNames('tabs-header', {
        className,
      })}
    >
      {tabs.map(({ key, label }, idx) => (
        <li
          key={idx.toString()}
          onClick={() => onChange(key)}
          className={classNames('tab', {
            tabClassName,
            active: activeTab === key,
          })}
        >
          <p>{label}</p>
        </li>
      ))}
    </ul>
  )
}
