import React from 'react'

import { IUser, roles, TFunc } from '@/types'
import { Button } from '@/components'

export const UserRow: React.FC<IUser & { onEdit: TFunc }> = ({
  onEdit,
  ...user
}) => {
  return (
    <tr className="hover:bg-gray-100">
      <td className="py-2 px-4 text-start">{user.name}</td>
      <td className="py-2 px-4 text-start">{user.email}</td>
      <td className="py-2 px-4 text-start">
        <div className="w-3/5 flex flex-wrap gap-2">
          {user.roles.map((role, idx) => (
            <span
              key={idx}
              className="bg-green-500 rounded px-2 py-1 text-white"
            >
              {roles[role]}
            </span>
          ))}
        </div>
      </td>
      <td className="py-2 px-4 text-start">
        <div className="flex flex-nowrap">
          <Button
            type="button"
            onClick={onEdit}
            className="rounded-full bg-green-400 text-white font-medium font-lg mx-2 px-6 py-2 hover:bg-green-500"
          >
            Edit
          </Button>
        </div>
      </td>
    </tr>
  )
}
