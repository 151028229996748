import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
} from 'react'
import { H5PPlayerUI } from '@lumieducation/h5p-react'

import { h5pPlayerProgress } from '@/utils'
import { useAuth, useH5P } from '@/hooks'

interface IPlayerProps {
  contentId: string
  editable?: boolean
  isFinishLoading?: boolean
  setProgress?: (_count: number) => void
  onUpdateProgress?: (_contentId: string, _progress: number) => void
}

export const Player = React.forwardRef<H5PPlayerUI, IPlayerProps>(
  (
    {
      contentId,
      editable = true,
      setProgress,
      onUpdateProgress,
      isFinishLoading,
    },
    ref,
  ) => {
    const { me } = useAuth()
    const { contentService } = useH5P()
    const [currentProgress, setCurrentProgress] = useState(0)
    const h5pPlayerRef = useRef<React.ElementRef<typeof H5PPlayerUI>>(null)

    const onFinish = useCallback(() => {
      const playerInstance = h5pPlayerRef.current?.h5pInstance
      let maxScore = 0
      let score = 0
      // @ts-ignore
      if (playerInstance.getMaxScore) {
        // @ts-ignore
        maxScore = playerInstance.getMaxScore()
        // @ts-ignore
        score = playerInstance.getScore()
        // @ts-ignore
      } else if (playerInstance?.gamePage) {
        // @ts-ignore
        maxScore = playerInstance?.gamePage?.maxQuestions
        // @ts-ignore
        score = playerInstance?.gamePage?.score
      }
      // @ts-ignore
      h5pPlayerRef.current?.h5pObject.setFinished(
        contentId,
        score,
        maxScore,
        Date.now(),
      )
    }, [contentId])

    useEffect(() => {
      if (isFinishLoading) {
        onFinish()
      }
    }, [isFinishLoading, onFinish])

    useEffect(() => {
      if (currentProgress) {
        onUpdateProgress?.(contentId, currentProgress)
      }
    }, [contentId, currentProgress, onUpdateProgress])

    const onInitialized = useCallback(() => {
      const playerInstance = h5pPlayerRef.current?.h5pInstance
      if (playerInstance) {
        h5pPlayerProgress(playerInstance, {
          init: true,
          onGetProgress: setProgress,
          onUpdateProgress: setCurrentProgress,
        })
      }
      const callback = () => {
        if (!playerInstance) return
        h5pPlayerProgress(playerInstance, {
          init: false,
          onUpdateProgress: setCurrentProgress,
        })
      }
      // @ts-ignore
      h5pPlayerRef.current?.h5pInstance?.on?.('interacted', callback)
    }, [setProgress])

    // @ts-ignore
    useImperativeHandle(ref, () => h5pPlayerRef.current, [])

    return (
      <div className="accordion-content px-5 pt-0 overflow-hidden">
        <div className="accordion-item bg-zinc-50 p-2 rounded mb-2">
          <div className="accordion-content pt-0 overflow-hidden">
            <p className="leading-6 font-light text-justify">
              <H5PPlayerUI
                ref={h5pPlayerRef}
                contextId={me?._id}
                contentId={contentId}
                readOnlyState={!editable}
                onInitialized={onInitialized}
                loadContentCallback={contentService.getPlay}
                // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                onxAPIStatement={(statement: any, _context: any, _event) => {
                  if (
                    statement.verb.id ===
                    'http://adlnet.gov/expapi/verbs/interacted'
                  ) {
                    h5pPlayerRef.current?.h5pInstance?.trigger('interacted')
                  }
                  // @ts-ignore
                  // console.log(statement, context, event, 'TODO')
                }}
              />
            </p>
          </div>
        </div>
      </div>
    )
  },
)
