import React, { useCallback, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { Navigate, useNavigate } from 'react-router-dom'
import { omit } from 'lodash'
import moment from 'moment'

import {
  Tabs,
  Form,
  Avatar,
  Button,
  TextArea,
  TextInput,
  RadioButton,
} from '@/components'
import { editProfSchema, generateFilePath, usersPath } from '@/utils'
import { IEnrollmentPayload, TEditProfFormSchema } from '@/types'
import { useAuth, useHookForm, useUsers } from '@/hooks'

export const EditUserPage: React.FC = () => {
  const navigate = useNavigate()
  const { selectedUser } = useUsers()
  const { onUploadAvatar, onUpdateProfile } = useAuth()
  const [activeFilter, setFilter] = useState<'details' | 'profile'>('profile')

  const {
    handler: {
      trigger,
      register,
      setValue,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(editProfSchema, {
    defaultValues: {
      ...(selectedUser?.enrollment || {}),
      email: selectedUser?.email || '',
      name: selectedUser?.name || '',
      photo: {
        file: undefined,
        path: generateFilePath(selectedUser?.avatar),
      },
      dateOfBirth: moment(selectedUser?.enrollment?.dateOfBirth).format(
        'YYYY-MM-DD',
      ),
    },
  })

  const onCheckValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore
      setValue(e.target.name, e.target.value)
      // @ts-ignore
      trigger(e.target.name)
    },
    [setValue, trigger],
  )

  const onSubmit: SubmitHandler<TEditProfFormSchema> = useCallback(
    data => {
      if (!selectedUser?._id) return
      // @ts-ignore
      const _data: IEnrollmentPayload = {
        user: selectedUser?._id,
        ...omit(data, [
          'lastName',
          'contactPhone',
          'firstName',
          'photo',
          'contactEmail',
          'relationship',
        ]),
      }
      if (data?.photo?.file) {
        // @ts-ignore
        onUploadAvatar(data?.photo?.file, selectedUser._id)
      }
      onUpdateProfile(_data, () => navigate(-1))
    },
    [navigate, onUpdateProfile, onUploadAvatar, selectedUser?._id],
  )

  const onUpload = useCallback(
    (field: string) => (file: File, path?: string) => {
      // @ts-ignore
      setValue(`${field}.file`, file)
      // @ts-ignore
      setValue(`${field}.path`, path)
      // @ts-ignore
      trigger(`${field}`)
    },
    [setValue, trigger],
  )

  if (!selectedUser) {
    return <Navigate to={usersPath} />
  }

  return (
    <div className="p-4 w-full overflow-auto">
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white p-8 rounded-md shadow w-full"
      >
        <Button
          type="button"
          onClick={() => navigate(-1)}
          className="text-gray-500 hover:text-gray-800 mb-4 inline-flex items-center"
        >
          <i className="fi fi-br-arrow-left h-5 mr-3"></i>
          Back
        </Button>
        <Tabs
          onChange={setFilter}
          activeTab={activeFilter}
          tabs={[
            { label: 'Profile', key: 'profile' },
            { label: 'Details', key: 'details' },
          ]}
        />
        {activeFilter === 'profile' && (
          <>
            <h2 className="text-2xl font-semibold mb-4">Profile</h2>
            <Avatar
              inputId="avatar"
              className="mb-4"
              onSelect={onUpload('photo')}
              imagePath={getValues('photo.path')}
            />
            <TextInput
              label="Name"
              placeholder="Enter full name"
              error={errors.name?.message}
              {...register('name')}
            />
            <TextInput
              type="date"
              label="Date of Birth"
              error={errors.dateOfBirth?.message}
              {...register('dateOfBirth')}
            />
            <RadioButton
              label="Gender"
              options={[
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
              ]}
              value={getValues('gender')}
              error={errors.gender?.message}
              {...register('gender')}
              onChange={onCheckValue}
            />

            <TextInput
              label="Email"
              type="email"
              disabled={!!selectedUser?.email}
              placeholder="Enter email address"
              error={errors.email?.message}
              {...register('email')}
            />
            <TextInput
              type="tel"
              label="Phone number"
              placeholder="Enter phone number"
              error={errors.phoneNumber?.message}
              {...register('phoneNumber')}
            />
            <TextArea
              rows={3}
              label="Educational Background:"
              className="mt-1 p-2 w-full border rounded-md"
              placeholder="Previous schooling details, if applicable."
              error={errors.educationalBackground?.message}
              {...register('educationalBackground')}
            />
            <TextInput
              type="text"
              label="State"
              placeholder="State"
              error={errors.state?.message}
              {...register('state')}
            />
            <TextInput
              type="text"
              label="City"
              placeholder="City"
              error={errors.city?.message}
              {...register('city')}
            />
            <TextInput
              type="text"
              label="Address"
              placeholder="Address"
              error={errors.address?.message}
              {...register('address')}
            />
            <TextInput
              type="text"
              label="Zip code"
              placeholder="Zip code"
              error={errors.zipCode?.message}
              {...register('zipCode')}
            />
            <TextArea
              rows={3}
              label="Bio"
              className="mt-1 p-2 w-full border rounded-md"
              placeholder="write text"
              error={errors.bio?.message}
              {...register('bio')}
            />
            <Button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
            >
              Save changes
            </Button>
          </>
        )}
        {activeFilter === 'details' && (
          <div className="w-1/4 flex flex-col gap-2 pt-3">
            <TextInput
              disabled
              readOnly
              label="Courses"
              value={selectedUser.courses}
            />
            <TextInput
              disabled
              readOnly
              label="Quizzes"
              value={selectedUser.quizzes}
            />
            <TextInput
              disabled
              readOnly
              label="Payments"
              value={selectedUser.payments}
            />
          </div>
        )}
      </Form>
    </div>
  )
}
