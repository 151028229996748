import React, { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'

import { generateFilePath, updateProfileForm } from '@/utils'
import { useAuth, useHookForm, useToast } from '@/hooks'
import { Button, Form, TextInput } from '@/components'
import { TUpdateProfileFormSchema } from '@/types'
import { useUpdateProfile } from '@/apis'

import avatar from '@/assets/images/client-avatar.png'

export const ProctorProfilePage: React.FC = () => {
  const { addToast } = useToast()
  const { me, onUploadAvatar } = useAuth()

  const {
    handler: {
      trigger,
      register,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(updateProfileForm, {
    defaultValues: {
      name: me?.name,
      email: me?.email,
    },
  })

  const { mutateAsync: updateProfile } = useUpdateProfile()

  const onSubmit: SubmitHandler<TUpdateProfileFormSchema> = useCallback(
    async data => {
      // @ts-ignore
      if (data?.photo?.length) {
        // @ts-ignore
        onUploadAvatar(data.photo?.[0])
      }
      const response = await updateProfile({ name: data.name })
      if (response.data.success) {
        addToast('success', response.data.message)
      }
    },
    [addToast, onUploadAvatar, updateProfile],
  )

  // @ts-ignore
  const photo = getValues('photo')?.[0]

  const photoUrl = photo ? URL.createObjectURL(photo) : null

  return (
    <div className="container mx-auto p-8">
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white p-8 rounded-md shadow w-full"
      >
        <div className="mb-4">
          <input
            id="avatar"
            type="file"
            accept="image/*"
            className="hidden"
            {...register('photo', {
              onChange: () => {
                trigger('photo')
              },
            })}
          />
          <label htmlFor="avatar" className="cursor-pointer">
            <img
              className="rounded w-40 h-40 rounded-full border"
              src={photoUrl || generateFilePath(me?.avatar) || avatar}
            />
          </label>
          <p className="text-xs text-gray-500 mt-2">
            Upload a profile picture (JPEG, PNG, or GIF).
          </p>
        </div>
        <TextInput
          label="Name"
          placeholder="Enter full name"
          error={errors.name?.message}
          {...register('name')}
        />

        <TextInput
          label="Email"
          type="email"
          disabled={!!me?.email}
          placeholder="Enter email address"
          error={errors.email?.message}
          {...register('email')}
        />

        <Button
          type="submit"
          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
        >
          Update
        </Button>
      </Form>
    </div>
  )
}
