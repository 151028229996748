import { FieldValues, useFieldArray } from 'react-hook-form'

import { IFieldArrayProps } from './types'

export const FieldArray = <T extends FieldValues>({
  name,
  control,
  children,
}: IFieldArrayProps<T>) => {
  const { fields, append, prepend, remove, update } = useFieldArray<T>({
    name,
    control,
  })

  return children(fields, update, append, remove, prepend)
}
