import React, { useMemo } from 'react'
import { Navigate, Outlet, Location, useLocation } from 'react-router-dom'

import { homePath, publicPaths } from '@/utils'
import { useAuth } from '@/hooks'

import { sidebarLinks } from '../MainLayout/components'

export const AuthWrapper: React.FC = () => {
  const location: Location = useLocation()
  const { authenticated, meLoading, me } = useAuth()

  const routes = useMemo(
    () => sidebarLinks(me?.role, authenticated),
    [authenticated, me?.role],
  )

  if (authenticated === false) {
    return <Navigate to={homePath} state={{ from: location }} replace />
  }

  if (meLoading) return null

  if (publicPaths.includes(location.pathname) && authenticated) {
    return <Navigate to={routes[0].path} state={{ from: location }} replace />
  }

  return <Outlet />
}
