import React, { useMemo, useState, useCallback, createContext } from 'react'
import { Outlet } from 'react-router-dom'

import { IUser } from '@/types'

interface IRolesContext {
  user?: IUser
  onEditRole: (_user: IUser) => void
}

export const RolesContext = createContext({} as IRolesContext)

export const RolesContextProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<IUser>()

  const onEditRole = useCallback((_proctor: IUser) => {
    setUser(_proctor)
  }, [])

  const value = useMemo(() => ({ user, onEditRole }), [user, onEditRole])

  return (
    <RolesContext.Provider value={value}>
      {children ?? <Outlet />}
    </RolesContext.Provider>
  )
}
