import classNames from 'classnames'

import { IDataTableProps } from './types'

export const DataTable = <T extends object | string>({
  className,
  data,
  footer,
  headers,
  children,
}: IDataTableProps<T>) => {
  return (
    <table
      className={classNames(
        'w-full table-auto border border-gray-300 mb-6 rounded-md overflow-hidden',
        className,
      )}
    >
      <thead className="bg-gray-200">
        <tr>
          {headers.map(header => (
            <td
              key={header.key?.toString()}
              className={classNames('py-2 px-4 border-b', header.className)}
            >
              {header.label}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>{children(data)}</tbody>
      {!!footer && <tfoot>{footer}</tfoot>}
    </table>
  )
}

export default DataTable
