import React from 'react'
import { Link } from 'react-router-dom'

import { navItems } from './constants'

export const AppFooter: React.FC = () => {
  return (
    <footer className="bg-[#0F1F30] text-white py-4">
      <div className="w-full container mx-auto px-4 sm:px-6 lg:px-8 flex justify-between md:flex-row flex-col items-center">
        <p className="text-[14px] text-[#F9FAFB] text-[14px]">
          &copy; 2024 Unity Learn. All rights reserved.
        </p>
        <nav className="space-x-4 hidden lg:block md:block">
          {navItems.map(navItem => (
            <Link
              className="text-[14px] underline"
              key={navItem.path + (navItem.element ?? '')}
              onClick={() => {
                if (!!navItem.element) {
                  const timer = setTimeout(() => {
                    const element = document
                      // @ts-ignore
                      .getElementById(navItem.element)
                    element?.scrollIntoView({ behavior: 'smooth' })
                    clearTimeout(timer)
                  }, 200)
                }
              }}
              to={navItem.path}
            >
              {navItem.defaultTitle}
            </Link>
          ))}
        </nav>
      </div>
    </footer>
  )
}
