import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { IConsultation, IPageMeta, TConsultationModal } from '@/types'
import { useConsultations, useProcessConsultation } from '@/apis'
import { DataTable, WithPaginator } from '@/components'

import { ConfirmationModal } from '@/pages/shared'

import { ConsultationRow } from './components'

const headers = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Email', key: 'email' },
  { label: 'Phone Number', key: 'phoneNumber' },
  { label: 'Status', key: 'status' },
  { label: 'Actions', key: 'actions' },
]

export const ConsultationsPage: React.FC = () => {
  const [selectedConsultation, setSelectedConsultation] =
    useState<IConsultation>()
  const [modalType, setModalType] = useState<TConsultationModal>(
    TConsultationModal.ALLOW,
  )
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    page: 1,
    pageSize: 10,
  })
  const [consultations, setConsultations] = useState<IConsultation[]>([])

  const onConsultationsSuccess = useCallback((_data: IConsultation[]) => {
    setConsultations(_data)
  }, [])

  const [getConsultations] = useConsultations(onConsultationsSuccess)
  const { mutateAsync: processConsultation } = useProcessConsultation()

  useEffect(() => {
    getConsultations()
  }, [getConsultations])

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const pageData = useMemo(() => {
    const firstPageIndex = (pageMeta.page - 1) * (pageMeta.pageSize || 10)
    const lastPageIndex = firstPageIndex + (pageMeta.pageSize || 10)
    return consultations.slice(firstPageIndex, lastPageIndex)
  }, [consultations, pageMeta.page, pageMeta.pageSize])

  const onSelectConsultation = useCallback(
    (_payload: IConsultation, _type: TConsultationModal) => () => {
      setModalType(_type)
      setSelectedConsultation(_payload)
    },
    [],
  )

  const onClose = useCallback(() => {
    setModalType(TConsultationModal.ALLOW)
    setSelectedConsultation(undefined)
  }, [])

  const onProcessConsultation = useCallback(async () => {
    if (!selectedConsultation?._id) return
    const response = await processConsultation({
      type: modalType,
      id: selectedConsultation?._id,
    })
    if (response.data.success) {
      onClose()
      getConsultations()
    }
  }, [
    onClose,
    modalType,
    getConsultations,
    processConsultation,
    selectedConsultation?._id,
  ])

  const { buttonText, desc } = useMemo(
    () => ({
      buttonText: modalType === TConsultationModal.ALLOW ? 'Allow' : 'Reject',
      desc:
        modalType === TConsultationModal.ALLOW
          ? `Are you sure you want to allow the creation of a school and send a link to fill out the form to ${selectedConsultation?.email} ?`
          : 'Are you sure you want to reject the creation of a school ?',
    }),
    [modalType, selectedConsultation?.email],
  )

  return (
    <div className="p-4 w-full">
      {!!selectedConsultation && (
        <ConfirmationModal
          onClose={onClose}
          description={desc}
          buttonText={buttonText}
          onConfirm={onProcessConsultation}
        />
      )}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-3xl font-semibold mb-4">New school applications</h2>
      </div>
      <WithPaginator
        data={pageData}
        initialPageSize={10}
        totalItems={consultations.length}
        onPageMetaChange={onPageMetaChange}
      >
        {pagedData => (
          <DataTable data={pagedData} headers={headers}>
            {data => {
              return data.map((item, idx) => (
                <ConsultationRow
                  key={idx}
                  {...item}
                  onAllow={onSelectConsultation(item, TConsultationModal.ALLOW)}
                  onReject={onSelectConsultation(
                    item,
                    TConsultationModal.REJECT,
                  )}
                />
              ))
            }}
          </DataTable>
        )}
      </WithPaginator>
    </div>
  )
}
