import { SectionTypes, Templates } from '@/types'

export const sectionTypesData: Record<Templates, SectionTypes[]> = {
  default_template: [
    SectionTypes.WHAT_WE_DO,
    SectionTypes.OUR_MISSION,
    SectionTypes.WHAT_WE_OFFER,
    SectionTypes.TESTIMONIALS,
    SectionTypes.CONTACT_US,
    SectionTypes.JOIN_TODAY,
  ],
  template_1: [
    SectionTypes.ADVANTAGES,
    SectionTypes.ADVANCE,
    SectionTypes.REWARD,
  ],
}
