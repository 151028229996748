import React, { useCallback, useMemo, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment'

import { useFinishCourse, useRescheduleCourse } from '@/apis'
// import { myTestsPath, setStorageValue } from '@/utils'
import { IStartedProgress, IStatus, TFunc } from '@/types'
import { useAuth, useFeedback, useToast } from '@/hooks'
// import { QUIZ_ID_KEY } from '@/constants'
import { DATE_FORMAT } from '@/constants'
import { Button } from '@/components'

import { ConfirmationModal } from './confirmationModal'
import { Player } from '../Admin/shared/components'
import { ChooseDate } from './chooseDate'
import { FeedbackForm } from './feedback'

interface ICourseWidget {
  onClose: TFunc
  onRefresh: TFunc
  plan: IStartedProgress
}

export const CourseWidget: React.FC<ICourseWidget> = ({
  plan,
  onClose,
  onRefresh,
}) => {
  const { isAdmin } = useAuth()
  // const navigate = useNavigate()
  const { addToast } = useToast()
  const [isActive, setIsActive] = useState(0)
  const [progress, setProgress] = useState(0)
  const [date, setDate] = useState<string>(moment().utc().format())
  const [chooseDate, setChooseDate] = useState(false)
  const [isOpenStart, setIsOpenStart] = useState(false)
  const [currentProgress, setCurrentProgress] = useState<
    Record<string, number>
  >({})
  const { toggleFeedbackView, showFeedbackView } = useFeedback()

  const { mutateAsync: finishCourse, isPending: isFinishLoading } =
    useFinishCourse()
  const { mutateAsync: rescheduleCourse } = useRescheduleCourse()

  const onToggleStart = useCallback(() => {
    setIsOpenStart(prev => !prev)
  }, [])

  const _onClose = useCallback(() => {
    onClose()
    onRefresh()
    setIsOpenStart(false)
  }, [onClose, onRefresh])

  const onFinish = useCallback(
    async (date: string) => {
      const request = plan.reschedule ? rescheduleCourse : finishCourse
      const response = await request({
        date,
        courseId: plan.item._id,
      })
      setDate(date)
      if (response.data.success) {
        addToast('success', 'You have successfully completed your course')
        if (!plan.item.quiz) {
          _onClose()
        } else {
          onToggleStart()
        }
        if (!plan.reschedule) {
          toggleFeedbackView(true, plan.item._id)
        }
      }
    },
    [
      _onClose,
      addToast,
      onToggleStart,
      finishCourse,
      plan.item._id,
      plan.item.quiz,
      plan.reschedule,
      rescheduleCourse,
      toggleFeedbackView,
    ],
  )

  const onOpenModule = useCallback(
    (id: number) => () => {
      setIsActive(id)
    },
    [],
  )

  const onCloseDate = useCallback(() => {
    setChooseDate(false)
  }, [])

  const onStartQuiz = useCallback(() => {
    _onClose()
    // setStorageValue(QUIZ_ID_KEY, plan.item.quiz?._id, false)
    // setTimeout(() => {
    //   navigate(myTestsPath)
    // }, 200)
  }, [
    _onClose,
    // navigate,
    // plan.item.quiz?._id
  ])

  const onUpdateProgress = useCallback(
    (contentId: string, progress: number) => {
      setCurrentProgress(prev => ({
        ...prev,
        [contentId]: progress,
      }))
    },
    [],
  )

  const onSetProgress = useCallback((progress: number) => {
    setProgress(prev => prev + progress)
  }, [])

  const _date = useMemo(() => moment(date).format(DATE_FORMAT), [date])

  const width = useMemo(() => {
    const _progress =
      plan.percent ??
      (Object.values(currentProgress).reduce((acc, cur) => acc + cur, 0) /
        (progress || 1)) *
        100

    return _progress > 100 ? 100 : _progress
  }, [currentProgress, progress, plan.percent])

  const fakeModules = useMemo(
    () =>
      plan?.item?.modules?.map(_v => {
        return _v.contents.map(content => (
          <Player
            key={content.id}
            contentId={content.id}
            setProgress={onSetProgress}
            onUpdateProgress={onUpdateProgress}
            editable={plan.status !== IStatus.Completed}
          />
        ))
      }),
    [onSetProgress, onUpdateProgress, plan?.item?.modules, plan.status],
  )

  return (
    <div className="w-full bg-white p-4">
      {chooseDate && (
        <ChooseDate
          onChoose={onFinish}
          onClose={onCloseDate}
          title={plan.item.title}
        />
      )}
      {isOpenStart && !showFeedbackView && (
        <ConfirmationModal
          onClose={_onClose}
          buttonText="Ok"
          onConfirm={onStartQuiz}
          description={`The linked test to this course will be available under your tests on ${_date}`}
        />
      )}
      <FeedbackForm />
      <h2 className="text-2xl font-semibold mb-6">
        {plan?.item.title}
        {!isAdmin && (
          <>
            <div className="w-1/2 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{
                  width: `${width}%`,
                }}
              ></div>
            </div>
            {width.toFixed(2)}%
          </>
        )}
      </h2>
      <div className="grid grid-rows-1 grid-flow-col gap-4 mb-4">
        <div className="rounded border col-span-9">
          {plan.item?.modules?.[isActive]?.contents?.map?.(content => (
            <div key={content.id} className="shadow-sm">
              <div className="px-5 rounded">
                <div className="accordion-content pt-0 overflow-hidden transition">
                  <div className="accordion-header transition flex space-x-5 h-16 items-center justify-between">
                    <div className="flex gap-4">
                      <h3>{content.title}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <Player
                contentId={content.id}
                isFinishLoading={isFinishLoading}
                onUpdateProgress={onUpdateProgress}
                editable={plan.status !== IStatus.Completed}
              />
            </div>
          ))}
          <div className="hidden">
            {plan.status !== IStatus.Completed && fakeModules}
          </div>
        </div>
        <div className="rounded border col-span-3">
          <ul className="p-4">
            <li className="text-2xl font-semibold mb-2">Modules</li>
            {plan?.item?.modules.map((item, idx) => (
              <li className="mb-2" key={idx}>
                <Button
                  disabled={idx === isActive}
                  className={classNames(
                    'w-full text-black font-bold py-2 px-4 rounded hover:bg-green-300 flex items-center justify-between',
                    {
                      'bg-zinc-100': idx !== isActive,
                      'bg-green-300': idx === isActive,
                    },
                  )}
                  onClick={onOpenModule(idx)}
                >
                  <div>{item.name}</div>
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex">
        {plan.reschedule && (
          <div>
            <Button
              onClick={() => setChooseDate(true)}
              className="bg-green-500 text-white mr-2 px-4 py-2 rounded-md hover:bg-green-600"
            >
              Reschedule
            </Button>
          </div>
        )}
        <div className="w-full flex justify-end">
          <Button
            onClick={onClose}
            className="bg-red-500 text-white mr-2 px-4 py-2 rounded-md hover:bg-red-600"
          >
            Leave
          </Button>
          {!plan.completed && (
            <Button
              onClick={() => setChooseDate(true)}
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
            >
              Finish
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
