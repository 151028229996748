import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { useAffiliateClientSecret, useMyAffiliate } from '@/apis'
import { IMyAffiliateResponse } from '@/types'
import { Button, Tabs } from '@/components'
import { useToast } from '@/hooks'
import { ENV } from '@/constants'

import {
  CheckoutForm,
  TemplateTabs,
  UpdateBrandForm,
  UpdateAffiliateForm,
} from './components'
import { AffiliateTabs, myAffiliateTabs } from './constants'

const stripePromise = loadStripe(ENV.VITE_STRIPE_PUB_KEY)

export const MyAffiliatePage: React.FC = () => {
  const mounted = useRef(false)
  const { addToast } = useToast()
  const [sectionType, setSectionType] = useState<AffiliateTabs>(
    AffiliateTabs.AFFILIATE,
  )
  const [clientSecret, setClientSecret] = useState<string>()
  const [myAffiliate, setMyAffiliate] = useState<IMyAffiliateResponse>()

  const onMyAffiliateSuccess = useCallback((_data: IMyAffiliateResponse) => {
    setMyAffiliate(_data)
  }, [])

  const { mutateAsync: affiliateClientSecret } = useAffiliateClientSecret()
  const [getMyAffiliate] = useMyAffiliate(onMyAffiliateSuccess)

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      getMyAffiliate()
    }
  }, [getMyAffiliate])

  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  const onPaymentSuccess = useCallback(() => {
    setClientSecret(undefined)
    // @ts-ignore
    setMyAffiliate(prev => ({ ...(prev ?? {}), isActive: true }))
    addToast(
      'success',
      'Your school is activated and available for new students',
    )
  }, [addToast])

  const openPaymentModal = useCallback(async () => {
    const response = await affiliateClientSecret({
      affiliateId: myAffiliate?._id,
    })
    if (response?.data?.clientSecret) {
      getMyAffiliate()
      setClientSecret(response.data.clientSecret)
    }
  }, [getMyAffiliate, affiliateClientSecret, myAffiliate?._id])

  return (
    <div className="p-4 w-full">
      {!!myAffiliate?.paymentDetails && !!clientSecret && (
        <Elements stripe={stripePromise}>
          <CheckoutForm
            clientSecret={clientSecret}
            onSuccess={onPaymentSuccess}
            onClose={() => setClientSecret(undefined)}
            price={myAffiliate?.paymentDetails?.amount || 0}
          />
        </Elements>
      )}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-3xl font-semibold mb-4">My School</h2>
        {myAffiliate?._id && !myAffiliate.isActive && (
          <Button
            onClick={openPaymentModal}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Activate school
          </Button>
        )}
      </div>
      <Tabs
        tabs={myAffiliateTabs}
        activeTab={sectionType}
        onChange={setSectionType}
      />
      {!!myAffiliate && (
        <>
          {sectionType === AffiliateTabs.AFFILIATE && (
            <UpdateAffiliateForm {...myAffiliate} onRefresh={getMyAffiliate} />
          )}
          {sectionType === AffiliateTabs.BRAND && (
            <UpdateBrandForm {...myAffiliate.affiliateBrand} />
          )}
          {sectionType === AffiliateTabs.TEMPLATE && (
            <TemplateTabs brand={myAffiliate.affiliateBrand} />
          )}
        </>
      )}
    </div>
  )
}
