import React, { useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { PaymentContextProvider } from '@/context'
import { EFinanceTabs } from '@/types'
import { Tabs } from '@/components'
import { useAuth } from '@/hooks'
import { ENV } from '@/constants'

import { IncomesTab, MethodsTab, PaymentsTab } from './components'
import { pageTabs } from './constant'

const stripePromise = loadStripe(ENV.VITE_STRIPE_PUB_KEY)

const PaymentView: React.FC = () => {
  const { isAdmin } = useAuth()
  const [activeTab, setActiveTab] = useState<EFinanceTabs>(
    EFinanceTabs.Payments,
  )

  return (
    <div className="p-4 w-full">
      <Tabs
        activeTab={activeTab}
        tabs={pageTabs(isAdmin)}
        onChange={setActiveTab}
      />
      {activeTab === EFinanceTabs.Payments && <PaymentsTab />}
      {!isAdmin && activeTab === EFinanceTabs.Methods && (
        <Elements stripe={stripePromise}>
          <MethodsTab />
        </Elements>
      )}
      {activeTab === EFinanceTabs.Incomes && <IncomesTab />}
    </div>
  )
}

export const PaymentPage: React.FC = () => {
  return (
    <PaymentContextProvider>
      <PaymentView />
    </PaymentContextProvider>
  )
}
