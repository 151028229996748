import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { getFile, signUpPath } from '@/utils'
import { Button } from '@/components'
import { useBrand } from '@/hooks'

export const HeroHeroSection: React.FC = () => {
  const navigate = useNavigate()
  const { template, color } = useBrand()

  return (
    <React.Fragment>
      <div
        className="hero-bg"
        style={{ backgroundImage: getFile(template?.image, true) }}
      ></div>
      <section className="template-hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1
                className="hero-title"
                style={{
                  color: color?.title,
                }}
              >
                {template?.title}
              </h1>
              <p
                className="hero-text"
                style={{
                  color: color?.description,
                }}
              >
                {template?.description}
              </p>
              <div className="buttons-group flex-wrap flex-lg-nowrap">
                <Button
                  type="button"
                  onClick={() => navigate(signUpPath)}
                  className="btn custom-btn green w-100"
                >
                  Get Started
                </Button>
                {!!template?.mainVideo && (
                  <Link
                    target="_blank"
                    to={template.mainVideo}
                    className="btn custom-btn w-100"
                  >
                    Watch Video
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
