import avatar1 from '../assets/img/cards/avatar-1.png'
import avatar2 from '../assets/img/cards/avatar-2.png'
import avatar3 from '../assets/img/cards/avatar-3.png'
import card1Img from '../assets/img/cards/card-1.png'
import card2Img from '../assets/img/cards/card-2.png'
import card3Img from '../assets/img/cards/card-3.png'
import studentsImg from '../assets/img/students.png'
import phoneImg from '../assets/img/icons/phone.png'
import everyBgImg from '../assets/img/every-bg.png'
import mailImg from '../assets/img/icons/mail.png'
import heroBg from '../assets/img/hero-bg.png'

export const heroPageDefaults = {
  image: heroBg,
  title: 'Empower Your Future with Instant Certification',
  description: `Unity Learn is transforming education by offering community-driven,
            certified alternatives to traditional schools. Complete courses,
            download your certificate instantly, and help build a brighter
            future for students everywhere.`,
}

export const whatWeDoPage = {
  title: `Transforming Education for Every Student's Future`,
  description: `The current education system is failing many students,
              particularly those in underserved communities. Unity Learn offers
              a vital alternative, providing innovative and supportive
              educational pathways.`,
  image: studentsImg,
}

export const ourMissionPage = {
  image: everyBgImg,
  title: 'Revolutionizing Education for Every Learner',
  description: `Unity Learn offers innovative solutions to the challenges facing
              today's education system. By empowering communities and educators,
              we create personalized learning environments that foster growth
              and success.`,
  blocks: [
    {
      title: '50%',
      description: 'Join us in transforming education for future generations.',
    },
    {
      title: '50%',
      description: 'Explore our community-driven educational programs today.',
    },
  ],
}

export const whatWeOfferPage = {
  title: 'Unlock Your Potential with Unity Learn',
  description: `Unity Learn offers a transformative approach to education, focusing
            on community-driven programs that empower learners. Our platform
            provides the flexibility and support needed to thrive in today’s
            world.`,
  blocks: [
    {
      title: 'Community-based learning for students',
      description:
        'Join a network of educators dedicated to personalized learning.',
      image: card1Img,
    },
    {
      title: 'State certification for quality education',
      description:
        'Our programs meet rigorous state standards to ensure quality.',
      image: card2Img,
    },
    {
      title: 'Flexible learning options to fit your needs',
      description:
        'Choose from various learning paths that suit your lifestyle.',
      image: card3Img,
    },
  ],
}

export const testimonialsPage = {
  title: 'Customer Testimonials',
  description: `Unity Learn transformed my teaching experience completely.`,
  blocks: [
    {
      title: `"Unity Learn has revolutionized how we approach education."`,
      image: avatar1,
      block: {
        title: 'Jane Doe',
        description: 'Educator, Local School',
      },
    },
    {
      title: `"The resources provided are invaluable for our students."`,
      image: avatar2,
      block: {
        title: 'John Smith',
        description: 'Principal, Community Academy',
      },
    },
    {
      title: `"I’ve seen my students thrive like never before!"`,
      image: avatar3,
      block: {
        title: 'Emily Johnson',
        description: 'Teacher, Homeschool Group',
      },
    },
  ],
}

export const contactUsPage = {
  title: 'Get in Touch',
  description: `We’re here to help you with any questions or information you need
            about Unity Learn.`,
  blocks: [
    {
      title: 'Email',
      image: mailImg,
      description: 'support@unitylearn.com',
    },
    {
      title: 'Phone',
      image: phoneImg,
      description: '+1 (555) 123-4567',
    },
  ],
}

export const joinTodayPage = {
  title: `Join the Education <br /> Revolution Today`,
  description: `Discover how Unity Learn can transform your educational
              experience. Sign up now to receive updates and learn how to start
              your own certified program.`,
}
