import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import Select, { MultiValue } from 'react-select'
import { SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { IOption, IRole, publicRoles, roles, TCreateRoleSchema } from '@/types'
import { Button, Form, FormError, TextInput } from '@/components'
import { useHookForm, useRoles, useToast } from '@/hooks'
import { useOwnerUpdateRoles } from '@/apis'
import { createRoleSchema } from '@/utils'

export const EditRolePage: React.FC = () => {
  const { user } = useRoles()
  const mounted = useRef(false)
  const navigate = useNavigate()
  const { addToast } = useToast()

  const {
    handler: {
      reset,
      trigger,
      setValue,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(createRoleSchema)

  useEffect(() => {
    if (mounted.current || !user) return
    mounted.current = true
    reset({
      userId: user?._id,
      roles: Object.values(user?.roles ?? []).filter(
        _v => typeof _v === 'number',
      ),
    })
  }, [reset, user])

  const { mutateAsync: updateRoles } = useOwnerUpdateRoles()

  useEffect(() => {
    if (!user) {
      navigate(-1)
    }
  }, [navigate, user])

  const onSubmit: SubmitHandler<TCreateRoleSchema> = useCallback(
    async data => {
      try {
        const roles = data.roles.filter(_v => _v !== undefined)
        const response = await updateRoles({
          ...data,
          roles,
        })
        if (response.data?._id) {
          addToast('success', 'The role was updated successfully.')
          navigate(-1)
        }
      } catch (error: any) {
        addToast('error', error?.message ?? 'Something went wrong')
      }
    },
    [updateRoles, addToast, navigate],
  )

  const onSelect = useCallback(
    (_options: MultiValue<IOption<string>>) => {
      setValue(
        'roles',
        _options.map(_o => roles.indexOf(_o.value)).filter(_v => _v >= 0),
      )
      trigger('roles')
    },
    [setValue, trigger],
  )

  const roleOptions = useMemo(
    () =>
      Object.values(IRole)
        .filter(_v => typeof _v === 'string')
        .filter(_v => publicRoles.includes(_v))
        .map(_v => ({
          label: _v,
          value: _v,
        })),
    [],
  )

  const values = getValues('roles')?.map(role => ({
    // @ts-ignore
    label: roles?.[role],
    // @ts-ignore
    value: roles?.[role],
  }))

  return (
    <div
      className="p-4 w-full h-full overflow-auto"
      style={{ height: 'calc(100% - 72px)' }}
    >
      <div className="w-full bg-white p-8 rounded-md shadow-md">
        <Button
          onClick={() => navigate(-1)}
          className="text-gray-500 hover:text-gray-800 mb-4 inline-flex items-center"
        >
          <i className="fi fi-br-arrow-left h-5 mr-3"></i>
          Back
        </Button>
        <h2 className="text-2xl font-semibold mb-6">Edit roles</h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextInput disabled label="User: " value={user?.name} />
          <div className="mt-1 w-full rounded-md">
            <label className="block text-sm font-medium text-black">
              Roles:
            </label>
            <Select
              isMulti
              value={values}
              onChange={onSelect}
              options={roleOptions}
              className="w-full flex-col mt-1"
              placeholder="Select roles for user"
            />
            {errors?.roles?.map?.(_role => (
              <FormError error={_role?.message} />
            ))}
          </div>
          <Button
            type="submit"
            className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-700 mt-4"
          >
            Save
          </Button>
        </Form>
      </div>
    </div>
  )
}
