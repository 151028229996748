import React, { createContext, useCallback, useMemo, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { editPath, usersPath } from '@/utils'
import { IUser, TFunc } from '@/types'
import { useGetUsers } from '@/apis'

export interface IUserDetails extends IUser {
  courses: number
  quizzes: number
  payments: number
}

interface IUsersContext {
  onGetUsers: TFunc
  users: IUserDetails[]
  selectedUser?: IUserDetails
  onEdit: (_user: IUserDetails) => void
}

export const UsersContext = createContext<IUsersContext>({
  users: [],
  onEdit: () => undefined,
  onGetUsers: () => undefined,
})

export const UsersProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate()
  const [users, setUsers] = useState<IUserDetails[]>([])
  const [selectedUser, setSelectedUser] = useState<IUserDetails>()

  const onUsersSuccess = useCallback((data: { data: IUserDetails[] }) => {
    setUsers(data.data)
  }, [])

  const [getUsers] = useGetUsers(onUsersSuccess)

  const onGetUsers = useCallback(() => {
    getUsers()
  }, [getUsers])

  const onEdit = useCallback(
    (user: IUserDetails) => {
      setSelectedUser(user)
      navigate(`${usersPath}/${editPath}`)
    },
    [navigate],
  )

  const value = useMemo(
    () => ({ users, selectedUser, onEdit, onGetUsers }),
    [users, selectedUser, onEdit, onGetUsers],
  )

  return (
    <UsersContext.Provider value={value}>
      {children ?? <Outlet />}
    </UsersContext.Provider>
  )
}
