import React, { useCallback, useEffect } from 'react'

import { useSubscription } from '@/hooks'
import { Button } from '@/components'

export const AffiliatePricingPage: React.FC = () => {
  const { subscriptions, onGetSubscriptions, onBuySubscription } =
    useSubscription()

  useEffect(() => {
    onGetSubscriptions()
  }, [onGetSubscriptions])

  const subscribe = useCallback(
    (id: string) => {
      onBuySubscription(id)
    },
    [onBuySubscription],
  )

  return (
    <div className="container mx-auto pb-8 pt-2">
      <div className="mb-8">
        <h2 className="text-3xl font-bold tracki text-center mt-12 sm:text-5xl ">
          Pricing
        </h2>
        <p className="max-w-3xl mx-auto mt-4 text-xl text-center ">
          If you want to open a school you must buy a plan.
        </p>
      </div>
      <div className="mt-24 container space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
        {subscriptions.map((item, index) => (
          <div
            key={index}
            className="relative p-8  border border-gray-200 rounded-2xl shadow-sm flex flex-col"
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold ">{item.type}</h3>
              <p>{item.description}</p>
              <p className="mt-4 flex items-baseline ">
                <span className="text-5xl font-extrabold tracking-tight">{`${item.currency}${item.price}`}</span>
                <span className="ml-1 text-xl font-semibold">/month</span>
              </p>
              <ul role="list" className="mt-6 space-y-6">
                <li className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="flex-shrink-0 w-6 h-6 text-emerald-500"
                    aria-hidden="true"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                  <span className="ml-3 ">...</span>
                </li>
                <li className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="flex-shrink-0 w-6 h-6 text-emerald-500"
                    aria-hidden="true"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                  <span className="ml-3 ">...</span>
                </li>
                <li className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="flex-shrink-0 w-6 h-6 text-emerald-500"
                    aria-hidden="true"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                  <span className="ml-3 ">...</span>
                </li>
              </ul>
            </div>
            <Button
              type="button"
              onClick={() => subscribe(item._id)}
              // eslint-disable-next-line max-len
              className="bg-emerald-500 text-white  hover:bg-emerald-600 mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
            >
              Buy
            </Button>
          </div>
        ))}
      </div>
    </div>
  )
}
