import React from 'react'
import moment from 'moment'
import classNames from 'classnames'

import { IStartedProgress, TFunc } from '@/types'
import { DATE_FORMAT } from '@/constants'

export const CourseRow: React.FC<IStartedProgress & { startCourse: TFunc }> = ({
  item,
  completed,
  startCourse,
}) => {
  return (
    <tr className="cursor-pointer hover:bg-gray-100" onClick={startCourse}>
      <td className="py-2 px-4 border-b text-start">{item?._id}</td>
      <td className="py-2 px-4 border-b text-start">{item?.title}</td>
      <td className="py-2 px-4 border-b text-start">{item?.description}</td>
      <td className="py-2 px-4 border-b text-start">${item?.price ?? 0}</td>
      <td className="py-2 px-4 border-b text-start">
        <span
          className={classNames(
            'inline-block text-black text-sm px-3 py-1 rounded-full',
            {
              'bg-zinc-300': !completed,
              'bg-green-400': completed,
            },
          )}
        >
          <td>{completed ? 'Completed' : 'Pending'}</td>
        </span>
      </td>
      <td className="py-2 px-4 border-b text-start">
        {moment(item?.createdAt).format(DATE_FORMAT)}
      </td>
    </tr>
  )
}
