import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useConsultationByToken } from '@/apis'
import { IConsultationResponse } from '@/types'
import { Tabs } from '@/components'
import { homePath } from '@/utils'

import { AffiliateTabs, myAffiliateTabs } from '../MyAffiliate/constants'

import { AffiliateForm, BrandForm } from './components'

export const CreateAffiliateFormPage: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [consultationResponse, setConsultationResponse] =
    useState<IConsultationResponse | null>()
  const isLoaded = useRef<boolean>(false)
  const [sectionType, setSectionType] = useState<AffiliateTabs>(
    AffiliateTabs.AFFILIATE,
  )

  const params = new URLSearchParams(location.search)
  const token = params.get('token')

  const onConsultationsSuccess = useCallback((_data: IConsultationResponse) => {
    setConsultationResponse(_data ?? null)
  }, [])

  const onConsultationFail = useCallback(() => {
    navigate(homePath)
  }, [navigate])

  const [getConsultationByToken] = useConsultationByToken(
    onConsultationsSuccess,
    onConsultationFail,
  )

  useEffect(() => {
    if (token && !isLoaded.current) {
      isLoaded.current = true
      getConsultationByToken({
        pathParams: {
          token,
        },
      })
    }
  }, [getConsultationByToken, token])

  useEffect(() => {
    if (consultationResponse === null) {
      navigate('/')
    } else if (consultationResponse?.affiliate?._id) {
      setSectionType(AffiliateTabs.BRAND)
    } else {
      setSectionType(AffiliateTabs.AFFILIATE)
    }
  }, [consultationResponse, navigate])

  const onCreateSuccess = useCallback(() => {
    if (token) {
      setSectionType(AffiliateTabs.BRAND)
      getConsultationByToken({
        pathParams: {
          token,
        },
      })
    }
  }, [getConsultationByToken, token])

  return (
    <div className="container mx-auto pb-8 pt-2">
      {!!consultationResponse && !!token && (
        <div className="mb-8">
          <h2 className="text-xl font-bold tracki text-center mt-12 sm:text-2xl ">
            Affiliate school registration
          </h2>
          <Tabs
            activeTab={sectionType}
            onChange={setSectionType}
            tabs={myAffiliateTabs.filter(
              _v => _v.key !== AffiliateTabs.TEMPLATE,
            )}
          />
          {sectionType === AffiliateTabs.AFFILIATE && (
            <AffiliateForm
              token={token}
              onSuccess={onCreateSuccess}
              ownerId={consultationResponse?.owner?._id}
            />
          )}
          {sectionType === AffiliateTabs.BRAND && (
            <BrandForm
              token={token}
              affiliateId={consultationResponse?.affiliate?._id}
            />
          )}
        </div>
      )}
    </div>
  )
}
