import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { DataTable, WithPaginator } from '@/components'
import { IPageMeta } from '@/types'
import { useUsers } from '@/hooks'

import { UserRow } from './components'

export const UsersPage: React.FC = () => {
  const { users, onGetUsers } = useUsers()
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    page: 1,
    pageSize: 10,
  })

  useEffect(() => {
    onGetUsers()
  }, [onGetUsers])

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const pageData = useMemo(() => {
    const firstPageIndex = (pageMeta.page - 1) * (pageMeta.pageSize || 10)
    const lastPageIndex = firstPageIndex + (pageMeta.pageSize || 10)
    return users.slice(firstPageIndex, lastPageIndex)
  }, [users, pageMeta.page, pageMeta.pageSize])

  return (
    <div className="p-4 w-full">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-3xl font-semibold mb-4">Users</h2>
      </div>
      <WithPaginator
        data={pageData}
        initialPageSize={10}
        totalItems={users.length}
        onPageMetaChange={onPageMetaChange}
      >
        {pagedData => (
          <DataTable
            data={pagedData}
            headers={[
              { label: 'Name', key: 'name' },
              { label: 'Email', key: 'email' },
              { label: 'Affiliate', key: 'affiliate' },
              { label: 'Email verified', key: 'verified' },
              { label: 'Created At', key: 'createdAt' },
            ]}
          >
            {data => data.map((item, idx) => <UserRow key={idx} {...item} />)}
          </DataTable>
        )}
      </WithPaginator>
    </div>
  )
}
