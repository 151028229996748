import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  useQuery,
  useMutation,
  UseMutationResult,
  UseMutationOptions,
  DefinedInitialDataOptions,
} from '@tanstack/react-query'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'

import { IPageMeta, TLazyQueryParams } from '@/types'
import { extractErrorFromResponse, generatePath } from '@/utils'
import { useToast } from '@/hooks'

import { apiRequest } from './request'
import { errorBlackList } from './errorBlackList'
import { VariantType } from 'notistack'

export const defaultPageMeta: IPageMeta = {
  page: 1,
  pageSize: 10,
}

interface TQueryCallbacks<TResponse = unknown> {
  onSuccess?: (_data: TResponse) => void
  onFail?: (_error: any) => void
  onSettled?: () => void
}

interface IQueryOptions<TResponse = unknown>
  extends TQueryCallbacks<TResponse> {
  options?: Partial<
    DefinedInitialDataOptions<AxiosResponse<TResponse> | undefined, AxiosError>
  >
}

interface IPaginatedRes<T = any> {
  success: boolean
  data: T[]
  total: number
  summary?: number
}

interface IUsePaginatedRequest {
  enabled?: boolean
  refreshKey?: string
  initialPageMeta?: IPageMeta
  path: string
  params?: TLazyQueryParams<any>
  paginated?: boolean
}

const onError = (
  error: any,
  apiPath: string,
  toastFn: (_type: VariantType, _message: string) => void,
) => {
  if (error.response?.status === 401) {
    // @ts-ignore
    window?.signOut?.()
  } else if (error.message) {
    if (!errorBlackList.includes(apiPath)) {
      toastFn('error', extractErrorFromResponse(error))
    }
  }
}

export const useGet = <TParam = unknown, TResponse = unknown>(
  path: string,
  params?: TParam,
  options?: IQueryOptions<TResponse>,
) => {
  const { addToast } = useToast()
  return useQuery({
    queryKey: [...(options?.options?.queryKey || []), path, params],
    queryFn: async () => {
      try {
        const res = await apiRequest.get<TResponse>(path)
        options?.onSuccess?.(res.data)
        return res
      } catch (error: any) {
        if (error) addToast('error', extractErrorFromResponse(error))
        options?.onFail?.(error)
      } finally {
        options?.onSettled?.()
      }
    },
    throwOnError: (error: AxiosError) => {
      addToast('error', extractErrorFromResponse(error))
      return false
    },
    ...options,
  })
}

export const useLazyGet = <TParam = unknown, TResponse = unknown>(
  path: string,
  params?: TLazyQueryParams<TParam>,
  options?: IQueryOptions<TResponse>,
  config?: AxiosRequestConfig,
) => {
  const { addToast } = useToast()
  const enabledRef = useRef(false)
  const [enabled, setEnabled] = useState(false)
  const [apiPath, setApiPath] = useState<string>(
    generatePath(path, params?.pathParams || {}, params?.queryParams || []),
  )

  const trigger = useCallback(
    (_payload?: TLazyQueryParams<TParam>) => {
      if (_payload) {
        setApiPath(
          generatePath(
            path,
            _payload?.pathParams || {},
            _payload?.queryParams || [],
          ),
        )
      }
      if (!enabledRef.current) {
        setEnabled(true)
        enabledRef.current = true
      }
    },
    [path],
  )

  const query = useQuery({
    queryKey: [...(options?.options?.queryKey || []), apiPath],
    queryFn: async () => {
      try {
        const res = await apiRequest.get<TResponse>(apiPath, config)
        options?.onSuccess?.(res.data)
        return res
      } catch (error: any) {
        if (error) addToast('error', extractErrorFromResponse(error))
        options?.onFail?.(error)
      } finally {
        options?.onSettled?.()
        setEnabled(false)
        enabledRef.current = false
      }
    },
    enabled,
    retry: 0,
    ...options,
  })

  return [trigger, query] as const
}

export function useGenericMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext> & {
    hideLoading?: boolean
  },
): UseMutationResult<TData, TError, TVariables, TContext> {
  const { addToast } = useToast()
  return useMutation({
    ...options,
    onError: (error: any, variables, context) => {
      if (error) addToast('error', extractErrorFromResponse(error))
      return options?.onError?.(error, variables, context)
    },
  })
}

export const useDelete = <TParam = unknown, TResponse = unknown>(
  path: string,
  data?: TParam,
  options?: UseMutationOptions<AxiosResponse<TResponse>, AxiosError, TParam>,
) => {
  return useGenericMutation({
    mutationFn: (variables: TParam | void) =>
      apiRequest.delete<TResponse>(path, {
        data: variables || data,
      }),
    ...options,
  })
}

export const usePost = <TParam = unknown, TResponse = unknown>(
  path: string,
  data?: TParam,
  options?: UseMutationOptions<
    AxiosResponse<TResponse>,
    AxiosError,
    TParam | void
  >,
) => {
  return useGenericMutation({
    mutationFn: (variables: TParam | void) =>
      apiRequest.post<TResponse>(path, variables || data),
    ...options,
  })
}

export const usePut = <TParam = unknown, TResponse = unknown>(
  path: string,
  data?: TParam,
  options?: UseMutationOptions<
    AxiosResponse<TResponse>,
    AxiosError,
    TParam | void
  >,
) => {
  return useGenericMutation({
    mutationFn: (variables: TParam | void) =>
      apiRequest.put<TResponse>(path, variables || data),
    ...options,
  })
}

export const usePaginatedRequest = <T = any>({
  enabled = true,
  refreshKey,
  path,
  params,
  initialPageMeta = defaultPageMeta,
  paginated = true,
}: IUsePaginatedRequest) => {
  const { addToast } = useToast()
  const [total, setTotal] = useState<number>(0)
  const [summary, setSummary] = useState<number>(0)
  const [pageMeta, setPageMeta] = useState<IPageMeta>(initialPageMeta)
  const [data, setData] = useState<T[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const apiPath = useMemo(() => {
    const queryParam = paginated
      ? [
          ...(params?.queryParams || []),
          { page: pageMeta.page },
          { limit: pageMeta.pageSize },
        ]
      : params?.queryParams || []
    const pathParam = params?.pathParams || {}
    return generatePath(path, pathParam, queryParam)
  }, [
    pageMeta.page,
    pageMeta.pageSize,
    paginated,
    params?.pathParams,
    params?.queryParams,
    path,
  ])

  useEffect(() => {
    if (refreshKey) {
      setPageMeta(defaultPageMeta)
      setData([])
      setTotal(0)
    }
  }, [refreshKey])

  useEffect(() => {
    if (!enabled) return
    setLoading(true)
    apiRequest
      .get<IPaginatedRes<T>>(apiPath)
      .then(res => res?.data)
      .then(res => {
        setTotal(res?.total ?? 0)
        setData(res?.data || [])
        if (res.summary !== undefined) {
          setSummary(res.summary)
        }
      })
      .catch(error => {
        onError(error, apiPath, addToast)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [addToast, apiPath, enabled, refreshKey])

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  return {
    summary,
    loading,
    total,
    data,
    pageMeta,
    onPageMetaChange,
  }
}
