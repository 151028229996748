import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CourseFilters, IPageMeta, IPlan, TFunc, TProgress } from '@/types'
import { Button, DataTable, Tabs, WithPaginator } from '@/components'
import { affiliateTableFilters, editPath, newPath } from '@/utils'
import { useAuth } from '@/hooks'

import { PlanRow } from './components'

interface IManagePlanSchemaProps {
  plans: IPlan[]
  type: TProgress
  onRefresh: TFunc
  onEdit?: (_plan?: IPlan) => void
}

export const ManagePlanSchema: React.FC<IManagePlanSchemaProps> = ({
  type,
  plans,
  onEdit,
  onRefresh,
}) => {
  const navigate = useNavigate()
  const { isAdmin, isSchoolOwner } = useAuth()
  const [activeFilter, setFilter] = useState<CourseFilters>(
    CourseFilters.AFFILIATE,
  )
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    page: 1,
    pageSize: 10,
  })

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const filteredPlans = useMemo(
    () =>
      isAdmin
        ? plans
        : plans.filter(_v =>
            activeFilter === CourseFilters.AFFILIATE
              ? _v.affiliate
              : !_v.affiliate,
          ),
    [activeFilter, isAdmin, plans],
  )

  const pageData = useMemo(() => {
    const firstPageIndex = (pageMeta.page - 1) * (pageMeta.pageSize || 10)
    const lastPageIndex = firstPageIndex + (pageMeta.pageSize || 10)
    return filteredPlans.slice(firstPageIndex, lastPageIndex)
  }, [filteredPlans, pageMeta.page, pageMeta.pageSize])

  const onCreate = useCallback(() => {
    onEdit?.(undefined)
    navigate(newPath)
  }, [onEdit, navigate])

  const _onEdit = useCallback(
    (plan?: IPlan) => () => {
      onEdit?.(plan)
      navigate(editPath)
    },
    [navigate, onEdit],
  )

  const label = useMemo(
    () => (type === TProgress.COURSE ? ' Course' : ' Quiz'),
    [type],
  )

  return (
    <div className="p-4 w-full">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-3xl font-semibold mb-4">{label} Management</h2>
        <Button
          onClick={onCreate}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          New
          {label}
        </Button>
      </div>
      <WithPaginator
        data={pageData}
        initialPageSize={10}
        totalItems={plans.length}
        onPageMetaChange={onPageMetaChange}
      >
        {pagedData => (
          <>
            {isSchoolOwner && (
              <Tabs
                onChange={setFilter}
                activeTab={activeFilter}
                tabs={affiliateTableFilters}
              />
            )}
            <DataTable
              data={pagedData}
              headers={[
                { label: 'Title', key: 'title' },
                { label: 'Description', key: 'desc' },
                { label: 'Price', key: 'price' },
                ...(isAdmin
                  ? [
                      { label: 'Affiliate', key: 'affiliate' },
                      { label: 'Editable', key: 'canEdit' },
                      {
                        label: 'Visible for affiliates',
                        key: 'visibleForAffiliates',
                      },
                      { label: 'Request status', key: 'requestStatus' },
                    ]
                  : []),
                { label: 'Status', key: 'status' },
                { label: 'Created At', key: 'createdAt' },
                ...(isSchoolOwner && activeFilter !== CourseFilters.AFFILIATE
                  ? [{ label: 'Actions', key: 'action' }]
                  : []),
              ]}
            >
              {data =>
                data.map((item, idx) => (
                  <PlanRow
                    key={idx}
                    {...item}
                    onRefresh={onRefresh}
                    onEdit={_onEdit(item)}
                    activeFilter={activeFilter}
                  />
                ))
              }
            </DataTable>
          </>
        )}
      </WithPaginator>
    </div>
  )
}
