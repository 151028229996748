import React, { useMemo } from 'react'

import { TextInput } from '@/components'

import { ISectionProps } from '../types'

import rewardImg from '@/assets/images/reward.png'
import { UploadImage } from './uploadImg'

export const EditRewardSection: React.FC<ISectionProps> = ({
  errors,
  register,
  onUpload,
  getValues,
  sectionIndex,
}) => {
  const _errors = useMemo(
    () => errors.sections?.[sectionIndex],
    [sectionIndex, errors],
  )

  return (
    <div className="flex flex-col gap-2">
      <img src={rewardImg} alt="" className="w-full object-contain" />
      <div className="flex flex-wrap w-full justify-between items-center">
        <div className="w-1/2 pr-2">
          <TextInput
            type="text"
            label="Title"
            placeholder="Enter the title"
            {...register(`sections.${sectionIndex}.title`)}
            error={_errors?.title?.message}
          />
          <TextInput
            type="text"
            label="Description"
            placeholder="Enter the description"
            {...register(`sections.${sectionIndex}.description`)}
            error={_errors?.description?.message}
          />
        </div>
        <div className="w-1/2">
          <UploadImage
            maxWidth="100%"
            className="mb-4"
            onSelect={onUpload(`sections.${sectionIndex}.image`)}
            imagePath={getValues(`sections.${sectionIndex}.image.path`)}
            error={_errors?.image?.file?.message}
          />
        </div>
      </div>
    </div>
  )
}
