import React, { useCallback } from 'react'

import { IPaymentMethod, TFunc } from '@/types'
import { useMakeDefaultMethod } from '@/apis'
import { Button } from '@/components'
import classNames from 'classnames'

const status = {
  0: 'Pending',
  1: 'Connected',
}

export const MethodRow: React.FC<
  IPaymentMethod & {
    index: number
    onRefresh: TFunc
  }
> = ({ index, onRefresh, ...method }) => {
  const { mutateAsync: makeDefault } = useMakeDefaultMethod()

  const onMakeDefault = useCallback(async () => {
    const response = await makeDefault({
      id: method._id,
    })
    if (response.data.success) {
      onRefresh?.()
    }
  }, [makeDefault, method._id, onRefresh])

  return (
    <tr className="cursor-pointer hover:bg-gray-100">
      <td className="py-2 px-4 border-b text-start">{index}.</td>
      <td className="py-2 px-4 border-b text-start">{method.method}</td>
      <td
        className={classNames(
          'py-2 px-4 border-b capitalize text-start text-orange',
          {
            'text-green-400': !!method.status,
          },
        )}
      >
        {status[method.status]}
      </td>
      <td className="py-2 px-4 border-b text-center">
        {!method.isDefault ? (
          <Button
            onClick={onMakeDefault}
            className="rounded-md bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded border"
          >
            Set default
          </Button>
        ) : (
          <span className="text-indigo-600">Default</span>
        )}
      </td>
    </tr>
  )
}
