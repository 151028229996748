import React from 'react'

import { TProgress } from '@/types'
import { useQuiz } from '@/hooks'

import { CreatePlanSchema } from '../shared'

export const CreateQuizPage: React.FC = () => {
  const { quiz, onSubmitQuiz } = useQuiz()

  return (
    <CreatePlanSchema
      plan={quiz}
      type={TProgress.QUIZ}
      onUpdate={onSubmitQuiz}
    />
  )
}
