import React from 'react'

import { useCourse, useH5P } from '@/hooks'
import { manageCoursePath } from '@/utils'

import { PlanDetailsSchema } from '../shared'

export const CourseDetailsPage: React.FC = () => {
  const { course } = useCourse()
  const { getCourses } = useH5P()

  return (
    <PlanDetailsSchema
      plan={course}
      onRefresh={getCourses}
      path={manageCoursePath}
    />
  )
}
