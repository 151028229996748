import React, { useMemo } from 'react'

import { Button, FieldArray, TextInput } from '@/components'

import { ISectionProps } from '../types'
import { UploadImage } from './uploadImg'

import ourMissionImg from '@/assets/images/ourMission.png'

export const EditOurMissionSection: React.FC<ISectionProps> = ({
  errors,
  control,
  onUpload,
  register,
  getValues,
  sectionIndex,
}) => {
  const _errors = useMemo(
    () => errors.sections?.[sectionIndex],
    [sectionIndex, errors],
  )

  return (
    <div className="flex flex-col gap-2">
      <img src={ourMissionImg} alt="" className="w-full object-contain" />
      <div className="flex flex-wrap w-full justify-between items-center">
        <div className="w-1/2 pr-2">
          <TextInput
            type="text"
            label="Title"
            placeholder="Enter the title"
            {...register(`sections.${sectionIndex}.title`)}
            error={_errors?.title?.message}
          />
          <TextInput
            type="text"
            label="Description"
            placeholder="Enter the description"
            {...register(`sections.${sectionIndex}.description`)}
            error={_errors?.description?.message}
          />
        </div>
        <div className="w-1/2">
          <UploadImage
            maxWidth="100%"
            className="mb-4"
            error={_errors?.image?.file?.message}
            onSelect={onUpload(`sections.${sectionIndex}.image`)}
            imagePath={getValues(`sections.${sectionIndex}.image.path`)}
          />
        </div>
        <div className="pl-4">
          <FieldArray
            control={control}
            name={`sections.${sectionIndex}.blocks`}
            children={(_fields, _u, append, _remove) => (
              <>
                <div className="flex flex-wrap gap-3">
                  {_fields.map((field, index) => (
                    <div key={field.id} className="flex flex-col pt-2 pb-2">
                      <TextInput
                        label={`Block ${index + 1} Title`}
                        type="text"
                        placeholder={`Enter block ${index + 1} title`}
                        error={_errors?.blocks?.[index]?.title?.message}
                        {...register(
                          `sections.${sectionIndex}.blocks.${index}.title`,
                        )}
                      />
                      <TextInput
                        type="text"
                        label={`Block ${index + 1} Description`}
                        placeholder={`Enter block ${index + 1} description`}
                        error={_errors?.blocks?.[index]?.description?.message}
                        {...register(
                          `sections.${sectionIndex}.blocks.${index}.description`,
                        )}
                      />
                      {_fields.length > 0 && (
                        <Button
                          type="button"
                          onClick={() => _remove(index)}
                          style={{ height: 'fit-content' }}
                          className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
                <Button
                  type="button"
                  className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                  onClick={() =>
                    append({
                      title: '',
                      description: '',
                      image: {
                        file: undefined,
                        path: '',
                      },
                    })
                  }
                >
                  Add block
                </Button>
              </>
            )}
          />
        </div>
      </div>
    </div>
  )
}
