import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

export const queryClient = new QueryClient()

export const QueryProvider = QueryClientProvider

export * from './hooks'
export * from './utils/request'
export * from './utils/wrapper'
export * from './endpoints'
