import { EFinanceTabs, PaymentSchemaTypes } from '@/types'

export const headers = [
  {
    key: 'n',
    label: 'N',
  },
  {
    key: 'user',
    label: 'User',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'amount',
    label: 'Amount',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'createdAt',
    label: 'Created at',
  },
]

export const incomeHeaders = [
  {
    key: 'n',
    label: 'N',
  },
  {
    key: 'referral',
    label: 'Referral',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'createdAt',
    label: 'Created at',
  },
  {
    key: 'amount',
    label: 'Amount',
  },
]

export const methodTabs = [
  {
    key: 'n',
    label: 'N',
  },
  {
    key: 'provider',
    label: 'Provider',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'actions',
    label: 'Default',
    className: 'text-center',
  },
]

export const pageTabs = (
  isAdmin?: boolean,
): { label: string; key: EFinanceTabs }[] => [
  { label: 'Payments', key: EFinanceTabs.Payments },
  { label: 'Incomes', key: EFinanceTabs.Incomes },
  ...(isAdmin ? [] : [{ label: 'Methods', key: EFinanceTabs.Methods }]),
]

export const colors: Record<PaymentSchemaTypes, string> = {
  [PaymentSchemaTypes.QUIZ]: 'rgba(253, 135, 135, 0.8)',
  [PaymentSchemaTypes.COURSE]: 'rgba(250, 192, 19, 0.8)',
  [PaymentSchemaTypes.AFFILIATE]: 'rgba(43, 63, 229, 0.8)',
  [PaymentSchemaTypes.SUBMISSION]: 'rgba(225, 220, 120, 0.8)',
}
