import React, { createContext, useCallback, useMemo, useState } from 'react'

import { IPlan } from '@/types'

interface ICreateCourseContext {
  course?: IPlan
  onEdit: (_course?: IPlan) => void
}

export const CreateCourseContext = createContext<ICreateCourseContext>({
  onEdit: () => undefined,
})

export const CreateCourseContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [course, setCourse] = useState<IPlan>()

  const onEdit = useCallback((_course?: IPlan) => {
    setCourse(_course)
  }, [])

  const value = useMemo(
    () => ({
      onEdit,
      course,
    }),
    [course, onEdit],
  )

  return (
    <CreateCourseContext.Provider value={value}>
      {children}
    </CreateCourseContext.Provider>
  )
}
