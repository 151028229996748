import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
  useRef,
} from 'react'

import { IFeedback, IFeedbackPayload, IFeedbacksResponse } from '@/types'
import { useCreateFeedback, useGetFeedbacks } from '@/apis'
import { useAuth } from '@/hooks'

interface IFeedbackContext {
  creating: boolean
  feedbacks: IFeedback[]
  showFeedbackView: boolean
  onCreateFeedback: (_payload: Pick<IFeedbackPayload, 'comment'>) => void
  toggleFeedbackView: (_bool: boolean, _courseId?: string) => void
}

export const FeedbackContext = createContext<IFeedbackContext>({
  feedbacks: [],
  creating: false,
  showFeedbackView: false,
  onCreateFeedback: () => undefined,
  toggleFeedbackView: () => undefined,
})

export const FeedbackContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { isAdmin, isSchoolOwner } = useAuth()
  const courseId = useRef<string>()
  const [feedbacks, setFeedbacks] = useState<IFeedback[]>([])
  const [showFeedbackView, setShowFeedbackView] = useState(false)

  const onFeedbacksSuccess = useCallback((_data: IFeedbacksResponse) => {
    setFeedbacks(_data.data)
  }, [])

  const [getFeedbacks] = useGetFeedbacks(onFeedbacksSuccess)
  const { mutateAsync: createFeedback, isPending: creating } =
    useCreateFeedback()

  const toggleFeedbackView = useCallback(
    (_bool: boolean, _courseId?: string) => {
      setShowFeedbackView(_bool)
      courseId.current = _courseId
    },
    [],
  )

  const onCreateFeedback = useCallback(
    async (_payload: Pick<IFeedbackPayload, 'comment'>) => {
      if (!courseId.current) return
      await createFeedback({ ..._payload, course: courseId.current })
      toggleFeedbackView(false, undefined)
    },
    [createFeedback, toggleFeedbackView],
  )

  useEffect(() => {
    if (!isAdmin && !isSchoolOwner) return
    getFeedbacks()
  }, [isAdmin, isSchoolOwner, getFeedbacks])

  const value = useMemo(
    () => ({
      creating,
      feedbacks,
      showFeedbackView,
      onCreateFeedback,
      toggleFeedbackView,
    }),
    [
      creating,
      feedbacks,
      showFeedbackView,
      onCreateFeedback,
      toggleFeedbackView,
    ],
  )

  return (
    <FeedbackContext.Provider value={value}>
      {children}
    </FeedbackContext.Provider>
  )
}
