import React, { useCallback, useEffect, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'

import { Button, Form, Modal, Select, TextInput } from '@/components'
import { IOption, TUploadDocumentSchema } from '@/types'
import { useCreateDocument, useGetUsers } from '@/apis'
import { uploadDocumentForm } from '@/utils'
import { IUserDetails } from '@/context'
import { useHookForm } from '@/hooks'

export const CreateDocument: React.FC<{
  onClose: (_refresh?: boolean) => void
}> = ({ onClose }) => {
  const [users, setUsers] = useState<IUserDetails[]>([])
  const {
    handler: {
      trigger,
      setValue,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(uploadDocumentForm)

  const onUsersSuccess = useCallback((data: { data: IUserDetails[] }) => {
    setUsers(data.data)
  }, [])

  const [getUsers] = useGetUsers(onUsersSuccess)
  const { mutateAsync: createDocument } = useCreateDocument()

  useEffect(() => {
    getUsers()
  }, [getUsers])

  const onSubmit: SubmitHandler<TUploadDocumentSchema> = useCallback(
    async payload => {
      const formData = new FormData()
      Object.keys(payload).forEach(key => {
        if (key === 'file') {
          const _file = payload?.[key].file
          if (_file) {
            // @ts-ignore
            formData.append(key, _file)
          }
        } else {
          // @ts-ignore
          formData.append(key, payload[key])
        }
      })
      const response = await createDocument(formData)
      if (response.data.success) {
        onClose(true)
      }
    },
    [createDocument, onClose],
  )

  const onSelect = useCallback(
    (user: IOption<string>) => {
      setValue('user', user.value)
      trigger('user')
    },
    [setValue, trigger],
  )

  const onUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore
      const file = e.target.files?.[0]
      if (!file) return
      const reader = new FileReader()

      reader.onloadend = () => {
        const base64String = reader.result

        setValue('file.file', file)
        setValue('file.path', base64String as string)
      }

      reader.readAsDataURL(file)
    },
    [setValue],
  )

  return (
    <Modal isOpen onClose={onClose} canClose={true}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="w-full">
              <TextInput
                type="file"
                label="Document"
                onChange={onUpload}
                accept="application/pdf"
                error={errors.file?.message}
              />
              <Select
                label="User"
                onChange={onSelect}
                value={getValues('user')}
                placeHolder="Select user"
                error={errors.user?.message}
                options={users?.map(_v => ({
                  value: _v._id,
                  label: `${_v.name} (${_v?.affiliate?.name ?? 'UC'})`,
                }))}
              />
            </div>
          </div>
        </div>
        <div className="bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <Button
            type="submit"
            // eslint-disable-next-line max-len
            className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
          >
            Create
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
