import { IChatResponse, IMessageResponse, IMessagesPayload } from '@/types'

import { useLazyGet } from '../utils/wrapper'
import endpoints from '../endpoints'

export const useGetChats = (onSuccess: (_data: IChatResponse) => void) => {
  return useLazyGet(endpoints.chats, undefined, {
    onSuccess,
  })
}

export const useGetMessages = (
  onSuccess: (_data: IMessageResponse) => void,
) => {
  return useLazyGet<IMessagesPayload, IMessageResponse>(
    endpoints.messages,
    undefined,
    {
      onSuccess,
    },
  )
}
