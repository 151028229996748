import {
  IUser,
  IResponse,
  IFontFamily,
  IStartEnroll,
  IConsultation,
  ISetRolePayload,
  IAffiliateBrand,
  IStudentsResponse,
  IAffiliateDetails,
  IBrandDetailsPayload,
  IConsultationPayload,
  IMyAffiliateResponse,
  IConsultationResponse,
  IChooseTemplatePayload,
  ICreateAffiliatePayload,
  IUpdateAffiliatePayload,
  ICreateConsultationPayload,
  IProcessConsultationPayload,
  IAdminCreateAffiliatePayload,
} from '@/types'

import { useLazyGet, usePost, usePut } from '../utils/wrapper'
import endpoints from '../endpoints'

export const useBrandDetails = (
  onSuccess: (_data: IAffiliateBrand) => void,
) => {
  return useLazyGet<IBrandDetailsPayload, IAffiliateBrand>(
    endpoints.brandFromSubdomain,
    undefined,
    { onSuccess },
  )
}

export const useAffiliates = (
  onSuccess: (_data: IAffiliateDetails[]) => void,
) => {
  return useLazyGet(endpoints.affiliates, undefined, {
    onSuccess,
  })
}

export const useAffiliateUsers = (onSuccess: (_data: IUser[]) => void) => {
  return useLazyGet(endpoints.staff, undefined, {
    onSuccess,
  })
}

export const useMyAffiliate = (
  onSuccess: (_data: IMyAffiliateResponse) => void,
) => {
  return useLazyGet(endpoints.affiliateAction, undefined, {
    onSuccess,
  })
}

export const useMyBrand = (onSuccess: (_data: IAffiliateBrand) => void) => {
  return useLazyGet(endpoints.myBrand, undefined, {
    onSuccess,
  })
}

export const useFontFamilies = (onSuccess: (_data: IFontFamily[]) => void) => {
  return useLazyGet(endpoints.fonts, undefined, {
    onSuccess,
  })
}

export const useAffiliateClientSecret = () => {
  return usePost<unknown, IStartEnroll>(endpoints.buySubdomain, undefined)
}

export const useConsultation = (onSuccess: (_data: IConsultation) => void) => {
  return useLazyGet(endpoints.consultation, undefined, {
    onSuccess,
  })
}

export const useConsultationByToken = (
  onSuccess: (_data: IConsultationResponse) => void,
  onFail: (_error?: string) => void,
) => {
  return useLazyGet<IConsultationPayload, IConsultationResponse>(
    endpoints.consultationByToken,
    undefined,
    {
      onSuccess,
      onFail,
    },
  )
}

export const useConsultations = (
  onSuccess: (_data: IConsultation[]) => void,
) => {
  return useLazyGet(endpoints.consultations, undefined, {
    onSuccess,
  })
}

export const useCreateConsultation = () => {
  return usePost<ICreateConsultationPayload, IResponse>(endpoints.consultation)
}

export const useCreateAffiliateBrand = () => {
  return usePost<FormData, IResponse>(endpoints.affiliateBrand)
}

export const useCreateAffiliate = () => {
  return usePost<ICreateAffiliatePayload, IResponse>(endpoints.affiliate)
}

export const useUpdateAffiliate = () => {
  return usePut<IUpdateAffiliatePayload, IResponse>(endpoints.affiliateAction)
}

export const useAdminCreateAffiliate = () => {
  return usePost<IAdminCreateAffiliatePayload, IResponse>(
    endpoints.affiliateAction,
  )
}

export const useOwnerUpdateRoles = () => {
  return usePost<ISetRolePayload, IUser>(endpoints.addToRole)
}

export const useProcessConsultation = () => {
  return usePost<IProcessConsultationPayload, IResponse>(
    endpoints.processConsultation,
  )
}

export const useRoleStudents = (
  onSuccess: (_data: IStudentsResponse) => void,
) => {
  return useLazyGet(endpoints.affiliateStudents, undefined, {
    onSuccess,
  })
}

export const useChooseTemplate = () => {
  return usePut<IChooseTemplatePayload, IAffiliateBrand>(
    endpoints.chooseTemplate,
    undefined,
  )
}

export const useUpdateAffiliateBrand = () => {
  return usePut<FormData, IResponse>(endpoints.myBrand, undefined)
}
