import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '@/components'

import { sidebarLinks } from './sidebar'

interface IMobileMenuProps {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  items: ReturnType<typeof sidebarLinks>
}

export const MobileMenu: React.FC<IMobileMenuProps> = ({
  items,
  onOpen,
  isOpen,
  onClose,
}) => {
  return (
    <>
      <div className="md:hidden p-4">
        <Button
          onClick={onOpen}
          className="text-gray-500 hover:text-green-300 focus:outline-none"
        >
          <i className="fi fi-br-menu-burger mr-4 h-5 text-2xl" />
        </Button>
      </div>
      <div
        className={classNames(
          'md:hidden fixed inset-0 bg-gray-800 bg-opacity-75 z-50',
          {
            hidden: !isOpen,
          },
        )}
      >
        <div className="flex justify-end p-4">
          <Button
            onClick={onClose}
            className="text-gray-500 hover:text-green-300 focus:outline-none"
          >
            <i className="fi fi-br-cross h-5 text-2xl" />
          </Button>
        </div>
        <div className="text-gray-300 p-4">
          <PageTitle />
          <ul>
            {items.map((item, idx) => (
              <li className="mb-2" key={idx}>
                <Link
                  to={item.path}
                  onClick={onClose}
                  className="block py-2 px-4 rounded hover:bg-gray-700"
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

export const PageTitle: React.FC = () => {
  const { pathname } = useLocation()
  return (
    <h1 className="text-2xl font-semibold mb-4">
      {(pathname.slice(1).charAt(0).toUpperCase() + pathname.slice(2)).replace(
        '-',
        ' ',
      )}
    </h1>
  )
}
