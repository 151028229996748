import React, { useCallback, useState } from 'react'

import { DataTable, WithPaginator } from '@/components'
import { useAllTasks } from '@/apis'
import { useAuth } from '@/hooks'

import { TaskRow } from './components'

const headers = [
  { label: 'Name', key: 'name' },
  { label: 'Email', key: 'email' },
  { label: 'Affiliate', key: 'affiliate' },
  { label: 'Transcript path', key: 'path' },
  { label: 'Date', key: 'date' },
  { label: 'Actions', key: 'actions', className: 'flex justify-center' },
]

export const AllTasksPage: React.FC = () => {
  const { me } = useAuth()
  const [refreshKey, setRefreshKey] = useState(`${Date.now()}`)

  const { onPageMetaChange, pageMeta, total, data } = useAllTasks(
    !!me?._id,
    refreshKey,
  )

  const onRefresh = useCallback(() => {
    setRefreshKey(`${Date.now()}`)
  }, [])

  return (
    <div className="p-4 w-full">
      <WithPaginator
        data={data}
        totalItems={total}
        initialPageSize={pageMeta.pageSize}
        onPageMetaChange={onPageMetaChange}
      >
        {pagedData => (
          <DataTable data={pagedData} headers={headers}>
            {data => {
              return data.map((item, idx) => (
                <TaskRow key={idx} {...item} onRefresh={onRefresh} />
              ))
            }}
          </DataTable>
        )}
      </WithPaginator>
    </div>
  )
}
