import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'

import { DataTable, WithPaginator } from '@/components'
import { IPageMeta, ISubmissionForm } from '@/types'
import { DATE_FORMAT } from '@/constants'
import { useDoctorates } from '@/hooks'

import { SubmissionDetails } from './components'

export type ActiveType = 'nominees' | 'declined' | 'accepted'

const defaultPageMeta = {
  page: 1,
  pageSize: 10,
}

export const DoctoralListPage: React.FC = () => {
  const { data } = useDoctorates()
  const [details, setDetails] = useState<ISubmissionForm>()
  const [pageMeta, setPageMeta] = useState<IPageMeta>(defaultPageMeta)
  const [activeType, setActiveType] = useState<ActiveType>('nominees')

  const list = useMemo(() => data[activeType], [activeType, data])

  const onSetActiveType = useCallback(
    (type: ActiveType) => () => {
      setPageMeta(defaultPageMeta)
      setActiveType(type)
    },
    [],
  )

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const onShowDetails = useCallback(
    (submission: ISubmissionForm) => () => {
      setDetails(submission)
    },
    [],
  )

  const onClose = useCallback(() => {
    setDetails(undefined)
  }, [])

  const pageData = useMemo(() => {
    const firstPageIndex = (pageMeta.page - 1) * (pageMeta.pageSize || 10)
    const lastPageIndex = firstPageIndex + (pageMeta.pageSize || 10)
    return list.slice(firstPageIndex, lastPageIndex)
  }, [list, pageMeta.page, pageMeta.pageSize])

  return (
    <>
      <div
        style={{ height: 'calc(100% - 72px)' }}
        className="p-4 w-full h-full overflow-auto"
      >
        <div className="w-full bg-white p-8 rounded-md shadow-md">
          <h2 className="text-2xl font-bold mb-6">Honorary Doctorate</h2>
          <div className="grid sm:grid-cols-1 md:grid-cols-3 flex-wrap gap-4 mb-6">
            <div
              onClick={onSetActiveType('nominees')}
              className={classNames(
                'bg-green-300 hover:bg-green-600 p-4 border cursor-pointer rounded',
                {
                  'bg-green-600': activeType === 'nominees',
                },
              )}
            >
              <h2 className="text-2xl font-semibold mb-4 text-center text-black">
                Nominees
              </h2>
              <p className="text-2xl font-bold text-center text-black">
                {data.nominees.length}
              </p>
            </div>
            <div
              onClick={onSetActiveType('accepted')}
              className={classNames(
                'bg-green-100 hover:bg-green-400 p-4 border cursor-pointer rounded',
                {
                  'bg-green-400': activeType === 'accepted',
                },
              )}
            >
              <h2 className="text-2xl font-bold mb-4 text-center text-black">
                Accepted
              </h2>
              <p className="text-2xl font-bold text-center text-black">
                {data.accepted.length}
              </p>
            </div>
            <div
              onClick={onSetActiveType('declined')}
              className={classNames(
                'bg-red-100 hover:bg-red-300 p-4 border cursor-pointer rounded',
                {
                  'bg-red-300': activeType === 'declined',
                },
              )}
            >
              <h2 className="text-2xl font-bold mb-4 text-center text-black">
                Declined
              </h2>
              <p className="text-2xl font-bold text-center text-black">
                {data.declined.length}
              </p>
            </div>
          </div>
          <h2 className="text-2xl font-semibold mb-6">Doctorates</h2>
          <WithPaginator
            data={pageData}
            initialPageSize={10}
            totalItems={[].length}
            onPageMetaChange={onPageMetaChange}
          >
            {pagedData => (
              <DataTable
                data={pagedData}
                headers={[
                  { label: 'Name', key: 'fullName' },
                  { label: 'Email', key: 'email' },
                  { label: 'Phone', key: 'phone' },
                  { label: 'Status', key: 'status' },
                  { label: 'Created At', key: 'createdAt' },
                ]}
              >
                {data =>
                  data.map((item, idx) => (
                    <tr
                      key={idx}
                      onClick={onShowDetails(item)}
                      className="hover:bg-gray-100 cursor-pointer"
                    >
                      <td className="py-2 px-4 border-b text-start">
                        {item.personalDetails.name}
                      </td>
                      <td className="py-2 px-4 border-b text-start">
                        {item.personalDetails.email}
                      </td>
                      <td className="py-2 px-4 border-b text-start">
                        {item.personalDetails.phone}
                      </td>
                      <td className="py-2 px-4 border-b text-start">
                        <span
                          className={classNames(
                            'inline-block text-black text-sm px-3 py-1 rounded-full',
                            {
                              'bg-red-300': item.declined,
                              'bg-green-400': item.accepted,
                              'bg-zinc-300': !item.accepted && !item.declined,
                            },
                          )}
                        >
                          {item.accepted
                            ? 'Accepted'
                            : item.declined
                              ? 'Declined'
                              : 'Pending'}
                        </span>
                      </td>

                      <td className="py-2 px-4 border-b text-start">
                        {moment(item?.createdAt).format(DATE_FORMAT)}
                      </td>
                    </tr>
                  ))
                }
              </DataTable>
            )}
          </WithPaginator>
        </div>
      </div>
      <SubmissionDetails
        type={activeType}
        details={details}
        onClose={onClose}
      />
    </>
  )
}
