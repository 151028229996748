import React, { useCallback, useMemo, useState } from 'react'

import { Button } from '@/components'

import { CreateConsultation } from '@/layout/MainLayout/components'

import { useBrand } from '@/hooks'
import { getFile } from '@/utils'

import { heroPageDefaults } from './constants'

export const DefaultHeroSection: React.FC = () => {
  const { template } = useBrand()
  const [isOpen, setIsOpen] = useState(false)

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const { title, description, image } = useMemo(
    () => ({
      title: template?.title ?? heroPageDefaults.title,
      description: template?.description ?? heroPageDefaults.description,
      image: getFile(template?.image) ?? heroPageDefaults.image,
    }),
    [template],
  )

  return (
    <section className="hero relative">
      <div className="relative overflow-hidden">
        <img
          src={image}
          alt="Graduation"
          className="w-full h-[600px] md:h-[900px] object-cover"
        />
      </div>
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center px-4">
        <div className="text-white w-full w-full container px-4">
          <h1 className="text-[32px] md:text-[56px] w-full md:w-[700px] font-bold mb-4">
            {title}
          </h1>
          <p className="text-[16px] md:text-[18px] w-full md:w-[700px] mb-6">
            {description}
          </p>
          <Button
            onClick={() => setIsOpen(true)}
            // eslint-disable-next-line max-len
            className="inline-block bg-white text-[#161C24] py-3 px-6 rounded-lg hover:bg-blue-700 transition duration-300 text-[14px] md:text-[16px] font-medium"
          >
            Sign up to enroll your school with Unity Learn
          </Button>
        </div>
      </div>
      {!!isOpen && <CreateConsultation onClose={onClose} />}
    </section>
  )
}
