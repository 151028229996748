import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { getFile, signUpPath } from '@/utils'
import { SectionTypes } from '@/types'
import { Button } from '@/components'
import { useBrand } from '@/hooks'

export const RewardSection: React.FC = () => {
  const navigate = useNavigate()
  const { template, color } = useBrand()

  const rewardInfo = useMemo(
    () => template?.sections?.find(_v => _v.type === SectionTypes.REWARD),
    [template?.sections],
  )

  if (!rewardInfo) return null

  return (
    <section
      className="reward"
      style={{
        backgroundImage: getFile(rewardInfo?.image, true),
      }}
    >
      <div className="container">
        <h2
          className="section-title white"
          style={{
            color: color?.title,
          }}
        >
          {rewardInfo?.title}
        </h2>
        <h3
          className="section-subtitle white mb-5"
          style={{
            color: color?.description,
          }}
        >
          {rewardInfo?.description}
        </h3>
        <div className="d-flex justify-content-center">
          <Button
            type="button"
            className="btn custom-btn green"
            onClick={() => navigate(signUpPath)}
          >
            Get Started
          </Button>
        </div>
      </div>
    </section>
  )
}
