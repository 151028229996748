import React, { useCallback, useMemo, useState } from 'react'
import moment from 'moment'

import { ConfirmationModal } from '@/pages/shared'
import { IAvailableZone, TFunc } from '@/types'
import { useDeleteCalendar } from '@/apis'
import { DATE_FORMAT } from '@/constants'
import { Button } from '@/components'

export const CalendarRow: React.FC<
  IAvailableZone & { onEdit: (_zone: IAvailableZone) => void; onRefresh: TFunc }
> = item => {
  const [isOpen, setIsOpen] = useState(false)
  const { to, from, location, onRefresh, onEdit } = useMemo(() => item, [item])

  const { mutateAsync: deleteCalendar } = useDeleteCalendar()

  const onDelete = useCallback(async () => {
    const response = await deleteCalendar({
      id: item._id,
    })

    if (response.data.success) {
      onRefresh()
    }
  }, [deleteCalendar, onRefresh, item._id])

  const _onEdit = useCallback(() => {
    onEdit(item)
  }, [onEdit, item])

  const processDelete = useCallback(() => {
    setIsOpen(true)
  }, [])

  const onCancel = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <>
      <tr className="cursor-pointer hover:bg-gray-100">
        <td className="py-2 px-4 text-start">
          {moment(from).format(DATE_FORMAT)}
        </td>
        <td className="py-2 px-4 text-start">
          {moment(to).format(DATE_FORMAT)}
        </td>
        <td className="py-2 px-4 text-start">{location}</td>
        <td className="py-2 px-4 flex justify-center">
          <Button
            type="button"
            onClick={_onEdit}
            className="rounded-full bg-green-400 text-white font-medium font-lg mx-2 px-6 py-2 hover:bg-green-500"
          >
            Edit
          </Button>
          <Button
            type="button"
            onClick={processDelete}
            className="rounded-full bg-red-400 text-white font-medium font-lg px-6 py-2 hover:bg-red-500"
          >
            Delete
          </Button>
        </td>
      </tr>
      {isOpen && (
        <ConfirmationModal
          onClose={onCancel}
          buttonText="Delete"
          onConfirm={onDelete}
          description="Are you sure you want to process it?"
        />
      )}
    </>
  )
}
