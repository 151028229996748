import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react'
import { Outlet } from 'react-router-dom'

import {
  IPageMeta,
  IPayment,
  IPaymentStat,
  IPaymentStatsResponse,
} from '@/types'
import { useGetPaymentHistory, usePaymentStats } from '@/apis'

interface IPaymentContext {
  totalItems: number
  history: IPayment[]
  pageMeta: IPageMeta
  stats: IPaymentStat[]
  statsLoading: boolean
  historyLoading: boolean
  onPageMetaChange: (_page: number, _pageSize: number) => void
}

const defaultPageMeta = {
  page: 1,
  pageSize: 10,
}

export const PaymentContext = createContext<IPaymentContext>({
  stats: [],
  history: [],
  totalItems: 0,
  statsLoading: true,
  historyLoading: true,
  pageMeta: defaultPageMeta,
  onPageMetaChange: () => null,
})

export const PaymentContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [stats, setStats] = useState<IPaymentStat[]>([])

  const onStatsSuccess = useCallback((res: IPaymentStatsResponse) => {
    setStats(res.data)
  }, [])

  const { onPageMetaChange, data, pageMeta, total, loading } =
    useGetPaymentHistory(true)

  const [getStats, { isLoading: statsLoading }] =
    usePaymentStats(onStatsSuccess)

  useEffect(() => {
    getStats()
  }, [getStats])

  const value = useMemo(
    () => ({
      stats,
      pageMeta,
      statsLoading,
      history: data,
      onPageMetaChange,
      totalItems: total,
      historyLoading: loading,
    }),
    [stats, pageMeta, statsLoading, data, onPageMetaChange, total, loading],
  )

  return (
    <PaymentContext.Provider value={value}>
      {children ?? <Outlet />}
    </PaymentContext.Provider>
  )
}
