import React, { useMemo } from 'react'

import { getFile, testimonials } from '@/utils'
import { SectionTypes } from '@/types'
import { useBrand } from '@/hooks'

import { testimonialsPage } from './constants'

import starImg from '../assets/img/icons/star.png'

export const DefaultTestimonialsSection: React.FC = () => {
  const { template } = useBrand()

  const testimonialsSection = useMemo(
    () => template?.sections.find(_v => _v.type === SectionTypes.TESTIMONIALS),
    [template?.sections],
  )

  const { title, description, blocks } = useMemo(
    () => ({
      title: testimonialsSection?.title ?? testimonialsPage.title,
      blocks: testimonialsSection?.blocks
        ? testimonialsSection.blocks.map(_v => ({
            ..._v,
            image: getFile(_v.image),
          }))
        : testimonialsPage.blocks,
      description:
        testimonialsSection?.description ?? testimonialsPage.description,
    }),
    [testimonialsSection],
  )

  return (
    <section className="testimonials" id={testimonials}>
      <div className="w-full container mx-auto px-4 py-12">
        <div className="text-center mb-12">
          <h1 className="text-3xl md:text-4xl font-bold text-[#000000]">
            {title}
          </h1>
          <p className="mt-4 text-[#161C24]">{description}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {blocks.map((_block, idx) => (
            <div key={idx} className="bg-[#F4F6F8] rounded-lg p-6">
              <div className="flex items-center mb-4">
                <div className="flex space-x-1 text-yellow-400">
                  <img src={starImg} className="h-5 w-5" alt="" />
                  <img src={starImg} className="h-5 w-5" alt="" />
                  <img src={starImg} className="h-5 w-5" alt="" />
                  <img src={starImg} className="h-5 w-5" alt="" />
                  <img src={starImg} className="h-5 w-5" alt="" />
                </div>
              </div>
              <p className="text-[#161C24] italic mb-4">{_block.title}</p>
              <div className="flex items-center space-x-4">
                <img
                  alt={_block.title}
                  src={_block.image}
                  className="w-10 h-10 rounded-full"
                />
                <div>
                  <h3 className="text-gray-900 font-bold">
                    {_block?.block?.title}
                  </h3>
                  <p className="text-gray-600 text-sm">
                    {_block?.block?.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
