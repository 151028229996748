import React, { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'

import { IAffiliate, TCreateAffiliateFormSchema, TFunc } from '@/types'
import { Button, Form, TextInput } from '@/components'
import { useHookForm, useToast } from '@/hooks'
import { createAffiliateSchema } from '@/utils'
import { useUpdateAffiliate } from '@/apis'

interface IUpdateAffiliateFormProps extends IAffiliate {
  onRefresh: TFunc
}

export const UpdateAffiliateForm: React.FC<IUpdateAffiliateFormProps> = ({
  _id: id,
  onRefresh,
  ...affiliate
}) => {
  const { addToast } = useToast()
  const {
    handler: {
      register,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(createAffiliateSchema, {
    defaultValues: {
      bankAddress: affiliate?.bankAddress,
      bankBranchAddress: affiliate?.bankBranchAddress,
      bankName: affiliate?.bankName,
      companyName: affiliate?.companyName,
      companyNumber: affiliate?.companyNumber,
      contact: affiliate?.contact,
      name: affiliate?.name,
      postCode: affiliate?.postCode,
      subDomain: affiliate?.subDomain,
      websiteUrl: affiliate?.websiteUrl,
    },
  })

  const { mutateAsync: updateAffiliate } = useUpdateAffiliate()

  const onSubmit: SubmitHandler<TCreateAffiliateFormSchema> = useCallback(
    async data => {
      const response = await updateAffiliate({
        ...data,
        id,
      })
      if (response.data.success) {
        onRefresh()
        addToast('success', 'The school was updated successfully.')
      }
    },
    [updateAffiliate, addToast, onRefresh, id],
  )

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="bg-white p-8 rounded-md shadow w-full"
    >
      <div className="pl-4">
        <TextInput
          label="Name of school"
          type="text"
          placeholder="Enter the name"
          error={errors.name?.message}
          {...register('name')}
        />
        <div className="flex flex-wrap items-center gap-2">
          <TextInput
            label="Domain url"
            type="text"
            placeholder="Enter the domain url"
            error={errors.subDomain?.message}
            {...register('subDomain')}
          />
          <p>.unitylearn.org</p>
        </div>
      </div>
      <h6 className="font-bold tracki mt-4 mb-4">Company contact info</h6>
      <div className="pl-4">
        <TextInput
          label="Company Name"
          type="text"
          placeholder="Enter the company name"
          error={errors.companyName?.message}
          {...register('companyName')}
        />
        <TextInput
          label="Company Phone Number"
          type="text"
          placeholder="+1 000 0000000"
          error={errors.companyNumber?.message}
          {...register('companyNumber')}
        />
        <TextInput
          label="Postcode"
          type="number"
          placeholder="Enter the postal code"
          error={errors.postCode?.message}
          {...register('postCode')}
        />
        <TextInput
          label="Website URL"
          type="text"
          placeholder="Enter the link"
          error={errors.websiteUrl?.message}
          {...register('websiteUrl')}
        />
      </div>
      <h6 className="font-bold track mt-4 mb-4">Administrator Information</h6>
      <div className="pl-4">
        <TextInput
          label="First Name"
          type="text"
          placeholder="Enter (BRN)"
          error={errors.contact?.firstName?.message}
          {...register('contact.firstName')}
        />
        <TextInput
          label="Second Name"
          type="text"
          placeholder="Enter second name"
          error={errors.contact?.lastName?.message}
          {...register('contact.lastName')}
        />
        <TextInput
          label="Phone Number"
          type="text"
          placeholder="+1 000 0000000"
          error={errors.contact?.phoneNumber?.message}
          {...register('contact.phoneNumber')}
        />
        <TextInput
          label="Email"
          type="email"
          placeholder="Enter the email"
          error={errors.contact?.email?.message}
          {...register('contact.email')}
        />
        <TextInput
          label="Mailing address"
          type="text"
          placeholder="Enter the address"
          error={errors.contact?.mailingAddress?.message}
          {...register('contact.mailingAddress')}
        />
      </div>
      <h6 className="font-bold tracki mt-4 mb-4">Financial information</h6>
      <div className="pl-4">
        <TextInput
          label="Bank name"
          type="text"
          placeholder="Enter the bank name"
          error={errors.bankName?.message}
          {...register('bankName')}
        />
        <TextInput
          label="Billing address"
          type="text"
          placeholder="Enter the address"
          error={errors.bankAddress?.message}
          {...register('bankAddress')}
        />
        <TextInput
          label="Branch address"
          type="text"
          placeholder="Enter the address"
          error={errors.bankBranchAddress?.message}
          {...register('bankBranchAddress')}
        />
      </div>
      <Button
        type="submit"
        className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
      >
        Save
      </Button>
    </Form>
  )
}
