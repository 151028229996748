import React, { useCallback, useState } from 'react'
import moment from 'moment'

import {
  Form,
  Modal,
  Button,
  TextArea,
  TextInput,
  DataTable,
} from '@/components'
import { generateFilePath } from '@/utils'
import { ISubmissionForm } from '@/types'
import { useDoctorates } from '@/hooks'
import { ActiveType } from '..'

interface ISubmissionDetailsProps {
  type: ActiveType
  onClose?: () => void
  details?: ISubmissionForm
}

export const SubmissionDetails: React.FC<ISubmissionDetailsProps> = ({
  type,
  details,
  onClose,
}) => {
  const { onUpdateDoctorateRequest } = useDoctorates()
  const [amount, setAmount] = useState<string>()

  const openFile = useCallback(
    (filePath?: string) => () => {
      if (!filePath) return
      window.open(generateFilePath(filePath, false) as string, '__blank')
    },
    [],
  )

  const _onClose = useCallback(() => {
    onClose?.()
    setAmount('')
  }, [onClose])

  const handleInput = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(ev.target.value)
  }, [])

  const onAccept = useCallback(() => {
    if (
      amount === undefined ||
      !amount.length ||
      isNaN(+amount) ||
      !details?._id
    ) {
      return
    }

    onUpdateDoctorateRequest(
      { accepted: true, amount: +amount, id: details?._id },
      _onClose,
    )
  }, [amount, onUpdateDoctorateRequest, _onClose, details])

  const onDecline = useCallback(() => {
    if (!details?._id) return
    onUpdateDoctorateRequest({ declined: true, id: details?._id }, _onClose)
  }, [onUpdateDoctorateRequest, _onClose, details])

  return (
    <Modal isOpen={!!details} onClose={_onClose} isFull canClose={true}>
      <Form>
        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="w-full">
              <div className="grid grid-cols-5 gap-4">
                <TextInput
                  label="Name"
                  disabled
                  value={details?.personalDetails?.name}
                />
                <TextInput
                  disabled
                  label="Date of Birth"
                  value={moment(details?.personalDetails?.dateOfBirth).format(
                    'YYYY-MM-DD',
                  )}
                />
                <TextInput
                  disabled
                  label="Phone"
                  value={details?.personalDetails?.phone}
                />
                <TextInput
                  disabled
                  label="Email"
                  value={details?.personalDetails?.email}
                />
                <TextInput
                  disabled
                  label="Payment Email"
                  value={details?.paymentEmail}
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="mb-4">
                  <label
                    htmlFor="cardNumber"
                    className="block text-black text-sm font-semibold mb-2"
                  >
                    References
                  </label>
                  <DataTable
                    data={details?.references || []}
                    headers={[
                      { label: 'Name', key: 'fullName' },
                      { label: 'Email', key: 'email' },
                      { label: 'Phone', key: 'phone' },
                    ]}
                  >
                    {data =>
                      data.map((item, idx) => (
                        <tr
                          key={idx}
                          className="hover:bg-gray-100 cursor-pointer"
                        >
                          <td className="py-2 px-4 border-b text-start">
                            {item.name}
                          </td>
                          <td className="py-2 px-4 border-b text-start">
                            {item.contactDetails.email}
                          </td>
                          <td className="py-2 px-4 border-b text-start">
                            {item.contactDetails.phone}
                          </td>
                        </tr>
                      ))
                    }
                  </DataTable>
                </div>
                <div className="mb-4">
                  <label className="block text-black text-sm font-semibold mb-2">
                    Supporting Documents
                  </label>
                  <DataTable
                    data={details?.supportingDocuments || []}
                    headers={[{ label: 'Document', key: 'document' }]}
                  >
                    {data =>
                      data.map((item, idx) => (
                        <tr
                          key={idx}
                          className="hover:bg-gray-100 cursor-pointer"
                        >
                          <td className="py-2 px-4 border-b text-start">
                            <Button
                              onClick={openFile(item)}
                              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                            >
                              {item.split('/').reverse()?.[0] || '-'}
                            </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </DataTable>
                </div>
                <TextArea
                  disabled
                  rows={5}
                  readOnly
                  label="Detailed Proposal"
                  value={details?.detailedProposal}
                  style={{ resize: 'none', overflow: 'auto' }}
                  className="mt-1 p-2 w-full border rounded-md"
                />
                {!!details?.professionalBackground?.length && (
                  <TextArea
                    disabled
                    rows={5}
                    readOnly
                    label="Professional Background"
                    style={{ resize: 'none', overflow: 'auto' }}
                    value={details?.professionalBackground?.join('|')}
                    className="mt-1 p-2 w-full border rounded-md"
                  />
                )}
                {!!details?.educationalBackground?.length && (
                  <div className="mb-4">
                    <label className="block text-black text-sm font-semibold mb-2">
                      Education Background
                    </label>
                    <DataTable
                      data={details?.educationalBackground || []}
                      headers={[
                        { label: 'Institution', key: 'institution' },
                        { label: 'Degree', key: 'degree' },
                        { label: 'Field Of Study', key: 'fieldOfStudy' },
                        { label: 'Graduation year', key: 'graduationYear' },
                      ]}
                    >
                      {data =>
                        data.map((item, idx) => (
                          <tr
                            key={idx}
                            className="hover:bg-gray-100 cursor-pointer"
                          >
                            <td className="py-2 px-4 border-b text-start">
                              {item.institution}
                            </td>
                            <td className="py-2 px-4 border-b text-start">
                              {item.degree}
                            </td>
                            <td className="py-2 px-4 border-b text-start">
                              {item.fieldOfStudy}
                            </td>
                            <td className="py-2 px-4 border-b text-start">
                              {item.graduationYear}
                            </td>
                          </tr>
                        ))
                      }
                    </DataTable>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {type === 'nominees' && (
          <div className="bg-gray-100 px-4 py-3 sm:flex justify-between sm:px-6">
            <TextInput
              min={0}
              type="number"
              value={amount}
              containerClassName=""
              onChange={handleInput}
              placeholder="Verification amount"
            />
            <div className="sm:flex">
              <Button
                type="button"
                onClick={onAccept}
                className="w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
              >
                Accept
              </Button>
              <Button
                type="button"
                onClick={onDecline}
                className="w-full justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700 sm:ml-3 sm:w-auto"
              >
                Decline
              </Button>
            </div>
          </div>
        )}
      </Form>
    </Modal>
  )
}
