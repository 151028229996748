import React from 'react'

import {
  DefaultHeroSection,
  DefaultWhatWeDoSection,
  DefaultJoinTodaySection,
  DefaultContactUsSection,
  DefaultOurMissionSection,
  DefaultWhatWeOfferSection,
  DefaultTestimonialsSection,
} from './components'

export const DefaultTemplate: React.FC = () => {
  return (
    <React.Fragment>
      <DefaultHeroSection />
      <DefaultWhatWeDoSection />
      <DefaultOurMissionSection />
      <DefaultWhatWeOfferSection />
      <DefaultTestimonialsSection />
      <DefaultContactUsSection />
      <DefaultJoinTodaySection />
    </React.Fragment>
  )
}
