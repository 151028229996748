import {
  IContactsFormResponse,
  ICreateContactFormPayload,
  IResponse,
} from '@/types'

import { useLazyGet, usePost } from '../utils/wrapper'
import endpoints from '../endpoints'

export const useCreateContactForm = () => {
  return usePost<ICreateContactFormPayload, IResponse>(
    endpoints.createContactForm,
    undefined,
  )
}

export const useGetContactsForm = (
  onSuccess?: (_data: IContactsFormResponse) => void,
) => {
  return useLazyGet(endpoints.getContactsForm, undefined, {
    onSuccess,
  })
}
