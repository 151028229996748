import {
  IResponse,
  IDiplomaPayload,
  IDocumentResponse,
  IEnrollmentPayload,
} from '@/types'

import { useLazyGet, usePost } from '../utils/wrapper'
import endpoints from '../endpoints'

export const useGetDiploma = (onSuccess: (_data: any) => void) => {
  return useLazyGet<IDiplomaPayload>(
    endpoints.getDiploma,
    undefined,
    {
      onSuccess,
    },
    { responseType: 'blob' },
  )
}

export const useCreateEnrollment = () => {
  return usePost<IEnrollmentPayload, IResponse>(endpoints.createEnrollment)
}

export const useGetDocuments = (
  onSuccess: (_data: IDocumentResponse) => void,
) => {
  return useLazyGet(endpoints.documents, undefined, {
    onSuccess,
  })
}
