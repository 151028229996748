import React from 'react'
import classNames from 'classnames'

import { type IFormErrorProps } from './types'

export const FormError: React.FC<IFormErrorProps> = ({
  error,
  className = 'text-red-600',
  ...rest
}) => {
  if (!error) return null
  return (
    <div className="error-container">
      <span className={classNames('error-message', className)} {...rest}>
        {error}
      </span>
    </div>
  )
}
