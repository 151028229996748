import React from 'react'
import classNames from 'classnames'
import moment from 'moment'

import { IIncome } from '@/types'

export const IncomeRow: React.FC<IIncome & { index: number }> = ({
  type,
  index,
  amount,
  referral,
  percent,
  received,
  createdAt,
}) => {
  return (
    <tr className="cursor-pointer hover:bg-gray-100">
      <td className="py-2 px-4 border-b text-start">{index}</td>
      <td className="py-2 px-4 border-b text-start">{referral.name}</td>
      <td className="py-2 px-4 border-b uppercase text-indigo-600 text-start">
        {type}
      </td>
      <td className="py-2 px-4 border-b text-start">
        <span
          className={classNames(
            'capitalize inline-block text-white text-sm px-3 py-1 rounded-full capitalize',
            {
              'bg-red-500': !received,
              'bg-green-500': received,
            },
          )}
        >
          {received ? 'Received' : 'Not received'}
        </span>
      </td>
      <td className="py-2 px-4 border-b text-start">
        {moment(createdAt).fromNow()}
      </td>
      <td className="py-2 px-4 border-b text-start">
        ${+amount.toFixed(15)}({percent}%)
      </td>
    </tr>
  )
}
