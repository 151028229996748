import { useEffect, useState } from 'react'

import { ConditionalWrapper } from '../Wrapper'
import { IWithPaginatorProps } from './types'
import { Paginator } from '../Paginator'

export const WithPaginator = <T,>({
  data,
  loading,
  initialPageSize,
  initialPage,
  disabled,
  WrapEl,
  className,
  onPageMetaChange,
  children,
  ...rest
}: IWithPaginatorProps<T>) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  useEffect(() => setPageSize(initialPageSize ?? 1), [initialPageSize])
  useEffect(() => setCurrentPage(initialPage ?? 1), [initialPage])

  return (
    <ConditionalWrapper WrapEl={WrapEl} className={className}>
      {children(data)}
      <Paginator
        {...rest}
        disabled={loading || disabled}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={(_v: number) => {
          setCurrentPage(_v)
          onPageMetaChange?.(_v, pageSize)
        }}
        onPageSizeChange={(_v: number) => {
          setPageSize(_v)
          onPageMetaChange?.(currentPage, _v)
        }}
      />
    </ConditionalWrapper>
  )
}
