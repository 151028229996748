import {
  ITask,
  IResponse,
  IAvailableZone,
  IProcessTaskPayload,
  ICreateCalendarPayload,
  IUpdateCalendarPayload,
  IDeleteCalendarPayload,
  IUpdateMeetingLinkPayload,
} from '@/types'

import {
  usePut,
  usePost,
  useDelete,
  usePaginatedRequest,
} from '../utils/wrapper'
import endpoints from '../endpoints'

export const useCalendars = (enabled: boolean, refreshKey?: string) => {
  const res = usePaginatedRequest<IAvailableZone>({
    enabled,
    refreshKey,
    path: endpoints.calendars,
  })
  return res
}

export const useCreateCalendar = () => {
  return usePost<ICreateCalendarPayload, IResponse>(endpoints.createCalendar)
}

export const useUpdateCalendar = () => {
  return usePut<IUpdateCalendarPayload, IResponse>(endpoints.updateCalendar)
}

export const useDeleteCalendar = () => {
  return useDelete<IDeleteCalendarPayload, IResponse>(endpoints.deleteCalendar)
}

export const useAllTasks = (enabled: boolean, refreshKey?: string) => {
  const res = usePaginatedRequest<ITask>({
    enabled,
    refreshKey,
    paginated: true,
    path: endpoints.tasks,
  })
  return res
}

export const usePendingTasks = (enabled: boolean, refreshKey?: string) => {
  const res = usePaginatedRequest<ITask>({
    enabled,
    refreshKey,
    paginated: true,
    path: endpoints.pendingTasks,
  })
  return res
}

export const useTasksHistory = (enabled: boolean, refreshKey?: string) => {
  const res = usePaginatedRequest<ITask>({
    enabled,
    refreshKey,
    paginated: true,
    path: endpoints.tasksHistory,
  })
  return res
}

export const useProcessTask = () => {
  return usePost<IProcessTaskPayload, IResponse>(endpoints.processTask)
}

export const useAssignTask = () => {
  return usePut<IDeleteCalendarPayload, IResponse>(endpoints.assignTask)
}

export const useUpdateMeetingLink = () => {
  return usePut<IUpdateMeetingLinkPayload, IResponse>(endpoints.updateMeeting)
}
