import React from 'react'

import { manageQuizPath } from '@/utils'
import { useQuiz } from '@/hooks'

import { PlanDetailsSchema } from '../shared'

export const QuizDetailsPage: React.FC = () => {
  const { quiz, onGetTests } = useQuiz()

  return (
    <PlanDetailsSchema
      plan={quiz}
      onRefresh={onGetTests}
      path={manageQuizPath}
    />
  )
}
