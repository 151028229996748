import React, { useEffect } from 'react'

import { TProgress } from '@/types'
import { useQuiz } from '@/hooks'

import { ManagePlanSchema } from '../shared'

export const ManageQuizPage: React.FC = () => {
  const { quizzes, onEdit, onGetTests } = useQuiz()

  useEffect(() => {
    onGetTests()
  }, [onGetTests])

  return (
    <ManagePlanSchema
      plans={quizzes}
      onEdit={onEdit}
      type={TProgress.QUIZ}
      onRefresh={onGetTests}
    />
  )
}
