import React from 'react'

import { FormError } from '@/components'

import { IRadioButtonProps } from './types'

export const RadioButton = React.forwardRef<
  HTMLInputElement,
  IRadioButtonProps
>(({ label, error, options, value, onChange, ...rest }, ref) => {
  return (
    <div className="mb-4">
      {!!label && (
        <label className="block text-sm font-medium text-black">{label}</label>
      )}
      <div className="flex items-center space-x-4 border p-2 rounded border-dashed">
        {options.map((option, idx) => (
          <label key={idx}>
            <input
              {...rest}
              ref={ref}
              type="radio"
              className="mr-2"
              onChange={onChange}
              value={option.value}
              checked={value === option.value}
            />
            {option.label}
          </label>
        ))}
      </div>
      <FormError error={error} />
    </div>
  )
})
