import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'

import { useGetDiploma, useProcessTask } from '@/apis'
import { ITask, TFunc, TTaskStatus } from '@/types'
import { generateFilePath } from '@/utils'
import { DATE_FORMAT } from '@/constants'
import { Button } from '@/components'

import { Link } from './link'

export const TaskRow: React.FC<ITask & { onRefresh: TFunc }> = props => {
  const [task, setTask] = useState<ITask>()

  useEffect(() => {
    setTask(props)
  }, [props])

  const openFile = useCallback(
    (filePath?: string) => () => {
      if (!filePath) return
      window.open(generateFilePath(filePath) as string, '__blank')
    },
    [],
  )

  const onGetDiploma = useCallback((data: any) => {
    const blob = new Blob([data])

    // Create a Blob URL
    const blobUrl = URL.createObjectURL(blob)

    // Create a link element to trigger the download
    const link = document.createElement('a')
    link.href = blobUrl
    link.download = 'diploma.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [])

  const [getDiploma] = useGetDiploma(onGetDiploma)
  const { mutateAsync: processTask } = useProcessTask()

  const onDownloadDocument = useCallback(
    (documentId: string) => {
      getDiploma({
        queryParams: [
          {
            documentId,
          },
        ],
      })
    },
    [getDiploma],
  )

  const onProcessTask = useCallback(
    (status: TTaskStatus, id: string) => async () => {
      const response = await processTask({
        id,
        status,
      })

      if (response.data.success) {
        props.onRefresh?.()
      }
    },
    [processTask, props],
  )

  if (!task) return null

  return (
    <tr className="cursor-pointer hover:bg-gray-100">
      <td className="py-2 px-4 border-b text-start">{task?.createdBy?.name}</td>
      <td className="py-2 px-4 border-b text-start">
        {task?.path ? (
          <Button
            onClick={openFile(task?.path)}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Result
          </Button>
        ) : (
          '-'
        )}
      </td>
      <td className="py-2 px-4 border-b text-start">
        {task?.diplomaPath ? (
          <Button
            onClick={() => onDownloadDocument(task?._id)}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Download
          </Button>
        ) : (
          '-'
        )}
      </td>
      <td className="py-2 px-4 border-b text-start">
        {moment(task?.date).format(DATE_FORMAT)}
      </td>
      <td className="py-2 px-4 border-b text-start">
        <Link {...task} />
      </td>
      <td className="py-2 px-4 flex gap-1 justify-center">
        <Button
          type="button"
          onClick={onProcessTask(TTaskStatus.ACCEPTED, task?._id)}
          className="rounded-full bg-green-400 text-white font-medium font-lg px-6 py-2 hover:bg-green-500"
        >
          Accept
        </Button>
        <Button
          type="button"
          onClick={onProcessTask(TTaskStatus.REJECTED, task?._id)}
          className="rounded-full bg-red-400 text-white font-medium font-lg px-6 py-2 hover:bg-red-500"
        >
          Reject
        </Button>
        <Button
          type="button"
          onClick={onProcessTask(TTaskStatus.CANCELED, task?._id)}
          className="rounded-full bg-gray-400 text-white font-medium font-lg px-6 py-2 hover:bg-gray-500"
        >
          Cancel
        </Button>
      </td>
    </tr>
  )
}
