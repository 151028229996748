import React, { createContext, useCallback, useMemo, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { affiliatesPath, previewPath } from '@/utils'
import { IAffiliateDetails } from '@/types'

interface IAffiliateContext {
  affiliate?: IAffiliateDetails
  onPreview: (_payload: IAffiliateDetails) => void
}

export const AffiliateContext = createContext<IAffiliateContext>({
  onPreview: () => undefined,
})

export const AffiliateProvider: React.FC = () => {
  const navigate = useNavigate()
  const [affiliate, setAffiliates] = useState<IAffiliateDetails>()

  const onPreview = useCallback(
    (affiliate: IAffiliateDetails) => {
      setAffiliates(affiliate)
      navigate(`${affiliatesPath}/${previewPath}`)
    },
    [navigate],
  )

  const value = useMemo(
    () => ({ affiliate, onPreview }),
    [affiliate, onPreview],
  )

  return (
    <AffiliateContext.Provider value={value}>
      <Outlet />
    </AffiliateContext.Provider>
  )
}
