import ReactDOM from 'react-dom/client'

import '@fontsource/merriweather'
import '@fontsource/merriweather/400.css'
import '@fontsource/merriweather/700.css'
import '@fontsource/poppins'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/700.css'

import App from './App'

ReactDOM.createRoot(document.getElementById('root')!).render(<App />)
