import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { SectionTypes } from '@/types'
import { useBrand } from '@/hooks'
import { getFile } from '@/utils'

export const AdvantagesSection: React.FC = () => {
  const { template, color } = useBrand()

  const advantages = useMemo(
    () => template?.sections?.find(_v => _v.type === SectionTypes.ADVANTAGES),
    [template?.sections],
  )

  if (!advantages) return null

  return (
    <section className="advantages">
      <div className="container">
        <h2
          className="section-title"
          style={{
            color: color?.title,
          }}
        >
          {advantages?.title}
        </h2>
        <h3
          className="section-subtitle"
          style={{
            color: color?.description,
          }}
        >
          {advantages?.description}
        </h3>
        <div className="row mt-5 mb-5">
          {advantages?.blocks?.map((block, idx) => (
            <div className="col-lg-3 mb-3" key={idx}>
              <div className="card shadow border-0">
                <img className="card-img-top" src={getFile(block.image)} />
                <div className="card-body">
                  <h5
                    className="card-title mb-0"
                    style={{
                      color: color?.description,
                    }}
                  >
                    {block.title}
                  </h5>
                </div>
              </div>
            </div>
          ))}
        </div>
        {advantages?.videoURL && (
          <div className="d-flex justify-content-center">
            <Link
              to={advantages.videoURL}
              className="btn custom-btn gold"
              style={{
                backgroundColor: color?.activeButton,
              }}
            >
              Watch Video
            </Link>
          </div>
        )}
      </div>
    </section>
  )
}
