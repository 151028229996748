import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'

import { IContactForm, IContactsFormResponse, IPageMeta } from '@/types'
import { DataTable, WithPaginator } from '@/components'
import { useGetContactsForm } from '@/apis'
import { DATE_FORMAT } from '@/constants'

export const ContactsFromPage: React.FC = () => {
  const [contactsFormData, setContacts] = useState<IContactForm[]>([])
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    page: 1,
    pageSize: 10,
  })

  const onContactsSuccess = useCallback((_data: IContactsFormResponse) => {
    setContacts(_data.data)
  }, [])

  const [getContactsForm] = useGetContactsForm(onContactsSuccess)

  useEffect(() => {
    getContactsForm()
  }, [getContactsForm])

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const pageData = useMemo(() => {
    const firstPageIndex = (pageMeta.page - 1) * (pageMeta.pageSize || 10)
    const lastPageIndex = firstPageIndex + (pageMeta.pageSize || 10)
    return contactsFormData.slice(firstPageIndex, lastPageIndex)
  }, [contactsFormData, pageMeta.page, pageMeta.pageSize])

  return (
    <div className="p-4 w-full">
      <WithPaginator
        data={pageData}
        initialPageSize={10}
        onPageMetaChange={onPageMetaChange}
        totalItems={contactsFormData.length}
      >
        {pagedData => (
          <DataTable
            data={pagedData}
            headers={[
              { label: 'ID', key: 'id' },
              { label: 'Affiliate', key: 'affiliate' },
              { label: 'Email', key: 'email' },
              { label: 'Full name', key: 'fullName' },
              { label: 'Message', key: 'Message' },
              { label: 'Created At', key: 'createdAt' },
            ]}
          >
            {data =>
              data.map((item, idx) => (
                <tr className="hover:bg-gray-100" key={idx}>
                  <td className="py-2 px-4 border-b text-start">{item._id}</td>
                  <td className="py-2 px-4 border-b text-start">
                    {item.affiliate?.name ?? '-'}
                  </td>
                  <td className="py-2 px-4 border-b text-start">
                    {item.email}
                  </td>
                  <td className="py-2 px-4 border-b text-start">
                    {item.fullName}
                  </td>
                  <td className="py-2 px-4 border-b text-start">
                    {item.message}
                  </td>
                  <td className="py-2 px-4 border-b text-start">
                    {moment(item.createdAt).format(DATE_FORMAT)}
                  </td>
                </tr>
              ))
            }
          </DataTable>
        )}
      </WithPaginator>
    </div>
  )
}
