import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { SectionTypes } from '@/types'
import { signUpPath } from '@/utils'
import { useBrand } from '@/hooks'

import { joinTodayPage } from './constants'

export const DefaultJoinTodaySection: React.FC = () => {
  const { template } = useBrand()

  const joinTodaySection = useMemo(
    () => template?.sections.find(_v => _v.type === SectionTypes.JOIN_TODAY),
    [template?.sections],
  )

  const { title, description } = useMemo(
    () => ({
      title: joinTodaySection?.title ?? joinTodayPage.title,
      description: joinTodaySection?.description ?? joinTodayPage.description,
    }),
    [joinTodaySection],
  )

  return (
    <section className="join-today">
      <div className="bg-[#EAF0F5] py-12">
        <div className="container w-full mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div>
            <h1
              className="text-[48px] font-bold text-[#161C24]"
              dangerouslySetInnerHTML={{ __html: title ?? '' }}
            />
          </div>
          <div className="flex flex-col justify-center items-start">
            <p
              className="text-[#454F5B] text-[18px] mb-4"
              dangerouslySetInnerHTML={{ __html: description ?? '' }}
            />
            <Link
              to={signUpPath}
              className="px-6 py-2.5 rounded-lg bg-[#3168A0] text-white"
            >
              Join now
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
