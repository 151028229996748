import React from 'react'
import classNames from 'classnames'
import moment from 'moment'

import { IStartedProgress, IStatus, TFunc } from '@/types'
import { DATE_FORMAT } from '@/constants'

export const QuizRow: React.FC<IStartedProgress & { startQuiz: TFunc }> = ({
  item,
  status,
  startQuiz,
}) => {
  return (
    <tr className="cursor-pointer hover:bg-gray-100" onClick={startQuiz}>
      <td className="py-2 px-4 border-b text-start">{item._id}</td>
      <td className="py-2 px-4 border-b text-start">{item.title}</td>
      <td className="py-2 px-4 border-b text-start">{item.description}</td>
      <td className="py-2 px-4 border-b text-start">${item.price}</td>
      <td className="py-2 px-4 border-b text-start">
        <span
          className={classNames(
            'inline-block text-black text-sm px-3 py-1 rounded-full',
            {
              'bg-zinc-300': status === IStatus.Enrolled,
              'bg-green-400': status === IStatus.Completed,
            },
          )}
        >
          {status.toUpperCase()}
        </span>
      </td>
      <td className="py-2 px-4 border-b text-start">
        {moment(item.createdAt).format(DATE_FORMAT)}
      </td>
    </tr>
  )
}
