import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'

import {
  IPlan,
  TFunc,
  TProgress,
  CourseFilters,
  RequestStatuses,
  IUpdateCourseAccessibilityPayload,
} from '@/types'
import { Button, SwitchButton } from '@/components'
import { DATE_FORMAT } from '@/constants'
import { useAuth } from '@/hooks'
import {
  useAddQuizToAffiliate,
  useAddCourseToAffiliate,
  useUpdateQuizAccessibility,
  useRemoveQuizFromAffiliate,
  useUpdateCourseAccessibility,
  useRemoveCourseFromAffiliate,
} from '@/apis'

export const PlanRow: React.FC<
  IPlan & { activeFilter: CourseFilters; onEdit: TFunc; onRefresh: TFunc }
> = ({ activeFilter, onEdit, onRefresh, ...plan }) => {
  const [item, setItem] = useState<IPlan>()
  const { me, isAdmin, isSchoolOwner } = useAuth()
  const prevFilter = useRef<CourseFilters>(CourseFilters.AFFILIATE)

  useEffect(() => {
    if (!item || plan.visibleFor?.length !== item?.visibleFor?.length) {
      setItem(plan)
    }
  }, [plan, item])

  useEffect(() => {
    if (prevFilter.current !== activeFilter) {
      prevFilter.current = activeFilter
      setItem(plan)
    }
  }, [activeFilter, plan])

  const { mutateAsync: updateCourseAccessibility } =
    useUpdateCourseAccessibility()
  const { mutateAsync: addCourseToAffiliate } = useAddCourseToAffiliate()
  const { mutateAsync: removeCourseFromAffiliate } =
    useRemoveCourseFromAffiliate()

  const { mutateAsync: addQuizToAffiliate } = useAddQuizToAffiliate()
  const { mutateAsync: removeQuizFromAffiliate } = useRemoveQuizFromAffiliate()
  const { mutateAsync: updateQuizAccessibility } = useUpdateQuizAccessibility()

  const visibleForDetails = useMemo(
    () => item?.visibleFor?.find(_v => _v._id === me?.affiliate?._id),
    [item?.visibleFor, me?.affiliate?._id],
  )
  const isCourse = useMemo(() => item?.type === TProgress.COURSE, [item?.type])

  const onUpdate = useCallback(
    (payload: Omit<IUpdateCourseAccessibilityPayload, 'courseId'>) =>
      async () => {
        if (!item) return
        const request = isCourse
          ? updateCourseAccessibility
          : updateQuizAccessibility
        // @ts-ignore
        const response = await request({
          [isCourse ? 'courseId' : 'id']: item?._id,
          ...payload,
        })
        if (response.data.success) {
          // @ts-ignore
          setItem(prev => ({ ...(prev || {}), ...payload }))
        }
      },
    [isCourse, item, updateCourseAccessibility, updateQuizAccessibility],
  )

  const toggleAddRemove = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      if (!item?._id) return
      const removeRequest = isCourse
        ? removeCourseFromAffiliate
        : removeQuizFromAffiliate
      const addRequest = isCourse ? addCourseToAffiliate : addQuizToAffiliate
      const requests = visibleForDetails ? removeRequest : addRequest
      //  @ts-ignore
      const response = await requests({
        [isCourse ? 'courseId' : 'id']: item?._id,
      })

      if (response.data.success) {
        onRefresh()
      }
    },
    [
      isCourse,
      item?._id,
      onRefresh,
      visibleForDetails,
      addQuizToAffiliate,
      addCourseToAffiliate,
      removeQuizFromAffiliate,
      removeCourseFromAffiliate,
    ],
  )

  if (!item) return null

  return (
    <tr onClick={onEdit} className="cursor-pointer hover:bg-gray-100">
      <td className="py-2 px-4 border-b text-start">{item.title}</td>
      <td className="py-2 px-4 border-b text-start">{item.description}</td>
      <td className="py-2 px-4 border-b text-start">${item.price}</td>
      {isAdmin && (
        <>
          <td
            onClick={e => e.stopPropagation()}
            className="py-2 px-4 border-b text-center text-indigo-500"
          >
            {item.affiliate?.name ?? '-'}
          </td>
          <td
            onClick={e => e.stopPropagation()}
            className="py-2 px-4 border-b text-start"
          >
            <SwitchButton
              checked={item.canEdit}
              onChange={onUpdate({
                visibleForAffiliates: item.visibleForAffiliates,
                canEdit: !item.canEdit,
              })}
            />
          </td>
          <td
            onClick={e => e.stopPropagation()}
            className="py-2 px-4 border-b text-start"
          >
            <SwitchButton
              checked={item.visibleForAffiliates}
              onChange={onUpdate({
                canEdit: !!item.canEdit,
                visibleForAffiliates: !item.visibleForAffiliates,
              })}
            />
          </td>
          <td className="py-2 px-4 border-b text-start">
            <span
              className={classNames(
                'capitalize inline-block text-white text-sm px-3 py-1 rounded-full capitalize',
                {
                  'bg-red-500': item.status === RequestStatuses.REJECTED,
                  'bg-zinc-500': item.status === RequestStatuses.PENDING,
                  'bg-green-500': item.status === RequestStatuses.RESOLVED,
                },
              )}
            >
              {item.status}
            </span>
          </td>
        </>
      )}
      <td className="py-2 px-4 border-b text-start">
        <span
          className={classNames(
            'inline-block text-white text-sm px-3 py-1 rounded-full capitalize',
            {
              'bg-zinc-300': !item.visible,
              'bg-green-400': item.visible,
            },
          )}
        >
          {item.visible ? 'Published' : 'Draft'}
        </span>
      </td>
      <td className="py-2 px-4 border-b text-start">
        {moment(item.createdAt).format(DATE_FORMAT)}
      </td>
      {isSchoolOwner && activeFilter !== CourseFilters.AFFILIATE && (
        <td className="py-2 px-4 border-b text-start">
          <Button
            onClick={toggleAddRemove}
            className={classNames(
              'text-white py-2 px-4 rounded cursor-pointer bg-green-500 hover:bg-green-700',
              {
                'bg-red-500 hover:bg-red-700': visibleForDetails,
              },
            )}
          >
            {visibleForDetails ? 'Delete' : 'Add'}
          </Button>
        </td>
      )}
    </tr>
  )
}
