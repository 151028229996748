import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { DataTable, WithPaginator } from '@/components'
import { IStartedProgress, IPageMeta } from '@/types'
import { useAuth, useH5P } from '@/hooks'
import { useStartCourse } from '@/apis'
import { ENV } from '@/constants'

import { CheckoutForm, CourseWidget } from '@/pages/shared'

import { CourseRow } from './components'
import { headers } from '../constants'

const stripePromise = loadStripe(ENV.VITE_STRIPE_PUB_KEY)

export const MyCoursesPage: React.FC = () => {
  const { myCourses, getMyCourses } = useH5P()
  const { isAdmin, isSchoolOwner, isProctor } = useAuth()
  const [clientSecret, setClientSecret] = useState<string>()
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    page: 1,
    pageSize: 10,
  })
  const [tempTest, setTempTest] = useState<IStartedProgress>()
  const [selectedTest, setSelectedTest] = useState<IStartedProgress>()

  const { mutateAsync: startCourse } = useStartCourse()

  useEffect(() => {
    getMyCourses()
  }, [getMyCourses])

  const onStartCourse = useCallback(
    async (test?: IStartedProgress) => {
      if (test && !isAdmin && !isSchoolOwner && !isProctor) {
        const response = await startCourse({
          courseId: test.item._id,
        })
        if (response.data.clientSecret) {
          setClientSecret(response.data.clientSecret)
        } else if (response.data.data) {
          setSelectedTest(response.data.data)
        }
      }
    },
    [startCourse, isAdmin, isSchoolOwner, isProctor],
  )

  const onSelectTest = useCallback(
    (test: IStartedProgress) => () => {
      onStartCourse(test)
      setTempTest(test)
    },
    [onStartCourse],
  )

  const onClosePaymentForm = useCallback(() => {
    setClientSecret(undefined)
    setTempTest(undefined)
  }, [])

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const onClose = useCallback(() => {
    setSelectedTest(undefined)
  }, [])

  const pageData = useMemo(() => {
    const firstPageIndex = (pageMeta.page - 1) * (pageMeta.pageSize || 10)
    const lastPageIndex = firstPageIndex + (pageMeta.pageSize || 10)
    return myCourses.slice(firstPageIndex, lastPageIndex)
  }, [myCourses, pageMeta.page, pageMeta.pageSize])

  if (selectedTest) {
    return (
      <CourseWidget
        onClose={onClose}
        plan={selectedTest}
        onRefresh={getMyCourses}
      />
    )
  }

  return (
    <div className="p-4 w-full">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-3xl font-semibold mb-4">My Courses</h2>
      </div>
      {!!clientSecret && (
        <Elements stripe={stripePromise}>
          <CheckoutForm
            clientSecret={clientSecret}
            onClose={onClosePaymentForm}
            price={tempTest?.item?.price || 0}
          />
        </Elements>
      )}
      <WithPaginator
        data={pageData}
        initialPageSize={10}
        totalItems={myCourses.length}
        onPageMetaChange={onPageMetaChange}
      >
        {pagedData => (
          <DataTable data={pagedData} headers={headers}>
            {data => {
              return data.map((item, idx) => {
                return (
                  <CourseRow
                    key={idx}
                    {...item}
                    startCourse={onSelectTest(item)}
                  />
                )
              })
            }}
          </DataTable>
        )}
      </WithPaginator>
    </div>
  )
}
