import React, { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { omit } from 'lodash'
import moment from 'moment'

import {
  Form,
  Button,
  Avatar,
  TextArea,
  TextInput,
  RadioButton,
} from '@/components'
import { IEnrollmentPayload, TEditProfFormSchema } from '@/types'
import { editProfSchema, generateFilePath } from '@/utils'
import { useAuth, useHookForm } from '@/hooks'

export const ProfilePage: React.FC<{ creation?: boolean }> = ({ creation }) => {
  const { me, meLoading, onUpdateProfile, onUploadAvatar } = useAuth()
  if (!me && meLoading) return null
  const {
    handler: {
      trigger,
      register,
      setValue,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(editProfSchema, {
    defaultValues: {
      ...(me?.enrollment || {}),
      email: me?.email || '',
      name: me?.name || '',
      photo: {
        file: undefined,
        path: generateFilePath(me?.avatar),
      },
      dateOfBirth: moment(me?.enrollment?.dateOfBirth).format('YYYY-MM-DD'),
    },
  })

  const onCheckValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore
      setValue(e.target.name, e.target.value)
      // @ts-ignore
      trigger(e.target.name)
    },
    [setValue, trigger],
  )

  const onSubmit: SubmitHandler<TEditProfFormSchema> = useCallback(
    data => {
      // @ts-ignore
      const _data: IEnrollmentPayload = {
        ...omit(data, [
          'lastName',
          'contactPhone',
          'firstName',
          'photo',
          'contactEmail',
          'relationship',
        ]),
      }
      if (data?.photo?.file) {
        // @ts-ignore
        onUploadAvatar(data?.photo?.file)
      }
      onUpdateProfile(_data)
    },
    [onUpdateProfile, onUploadAvatar],
  )

  const onUpload = useCallback(
    (field: string) => (file: File, path?: string) => {
      // @ts-ignore
      setValue(`${field}.file`, file)
      // @ts-ignore
      setValue(`${field}.path`, path)
      // @ts-ignore
      trigger(`${field}`)
    },
    [setValue, trigger],
  )

  return (
    <div className="container mx-auto py-8">
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white p-8 rounded-md shadow w-full"
      >
        <h2 className="text-2xl font-semibold mb-4">
          {creation ? `Enrollment form` : `Profile Editor`}
        </h2>
        <Avatar
          inputId="avatar"
          className="mb-4"
          onSelect={onUpload('photo')}
          imagePath={getValues('photo.path')}
        />
        <TextInput
          label="Name"
          placeholder="Enter full name"
          error={errors.name?.message}
          {...register('name')}
        />
        <TextInput
          label="Date of Birth"
          type="date"
          error={errors.dateOfBirth?.message}
          {...register('dateOfBirth')}
        />

        <RadioButton
          label="Gender"
          options={[
            { label: 'Male', value: 'male' },
            { label: 'Female', value: 'female' },
          ]}
          value={getValues('gender')}
          error={errors.gender?.message}
          {...register('gender')}
          onChange={onCheckValue}
        />

        <TextInput
          label="Email"
          type="email"
          disabled={!!me?.email}
          placeholder="Enter email address"
          error={errors.email?.message}
          {...register('email')}
        />
        <TextInput
          type="tel"
          label="Phone number"
          placeholder="Enter phone number"
          error={errors.phoneNumber?.message}
          {...register('phoneNumber')}
        />
        <TextArea
          rows={3}
          label="Educational Background:"
          className="mt-1 p-2 w-full border rounded-md"
          placeholder="Previous schooling details, if applicable."
          error={errors.educationalBackground?.message}
          {...register('educationalBackground')}
        />
        <TextInput
          type="text"
          label="State"
          placeholder="State"
          error={errors.state?.message}
          {...register('state')}
        />
        <TextInput
          type="text"
          label="City"
          placeholder="City"
          error={errors.city?.message}
          {...register('city')}
        />
        <TextInput
          type="text"
          label="Address"
          placeholder="Address"
          error={errors.address?.message}
          {...register('address')}
        />
        <TextInput
          type="text"
          label="Zip code"
          placeholder="Zip code"
          error={errors.zipCode?.message}
          {...register('zipCode')}
        />
        <TextArea
          rows={3}
          label="Bio"
          className="mt-1 p-2 w-full border rounded-md"
          placeholder="write text"
          error={errors.bio?.message}
          {...register('bio')}
        />

        <Button
          type="submit"
          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
        >
          {creation ? `Next` : `Update Profile`}
        </Button>
      </Form>
    </div>
  )
}
