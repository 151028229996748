import React, { useMemo, useState, useCallback, createContext } from 'react'
import { NavigateFunction, Outlet, useNavigate } from 'react-router-dom'

import { ISubscriptionData } from '@/types'
import { useSubscriptions, useBuySubscription } from '@/apis'
import { useAuth } from '@/hooks'
import { createAffiliateFormPath } from '@/utils'

interface ISubscriptionContext {
  subscriptions: ISubscriptionData[]
  onGetSubscriptions: () => void
  onBuySubscription: (id: string) => void
}

export const SubscriptionContext = createContext<ISubscriptionContext>({
  subscriptions: [],
  onGetSubscriptions: () => null,
  onBuySubscription: async () => undefined,
})

export const SubscriptionContextProvider: React.FC = () => {
  const navigate: NavigateFunction = useNavigate()
  const [subscriptions, setSubscriptions] = useState<ISubscriptionData[]>([])
  const { onSetSubscription } = useAuth()

  const onSubscriptionsSuccess = useCallback((_data: ISubscriptionData[]) => {
    setSubscriptions(_data)
  }, [])

  const [getSubscriptions] = useSubscriptions(onSubscriptionsSuccess)
  const { mutateAsync: buySubscription } = useBuySubscription()

  const onGetSubscriptions = useCallback(() => {
    getSubscriptions()
  }, [getSubscriptions])

  const onBuySubscription = useCallback(
    async (id: string) => {
      const response = await buySubscription({ subscriptionId: id })
      if (response?.data) {
        onSetSubscription(response.data.subscription)
        navigate(createAffiliateFormPath)
      }
    },
    [buySubscription],
  )

  const value = useMemo(
    () => ({
      subscriptions,
      onGetSubscriptions,
      onBuySubscription,
    }),
    [subscriptions, onGetSubscriptions, onBuySubscription],
  )
  return (
    <SubscriptionContext.Provider value={value}>
      <Outlet />
    </SubscriptionContext.Provider>
  )
}
