import React, { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'
import moment from 'moment'

import { Button, Form, Modal, TextInput } from '@/components'
import { TChooseExamDateSchema, TFunc } from '@/types'
import { chooseExamDateForm } from '@/utils'
import { DATE_FORMAT } from '@/constants'
import { useHookForm } from '@/hooks'

interface IChooseDate {
  title: string
  onClose?: TFunc
  onChoose: (_proctor: string) => void
}

export const ChooseDate: React.FC<IChooseDate> = ({
  title,
  onClose,
  onChoose,
}) => {
  const {
    handler: {
      register,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(chooseExamDateForm)

  const onSubmit: SubmitHandler<TChooseExamDateSchema> = useCallback(
    async payload => {
      onChoose(moment(payload.date).utc().format(DATE_FORMAT))
    },
    [onChoose],
  )

  return (
    <Modal isOpen canClose={false} onClose={onClose}>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <p className="mb-1 text-sm text-gray-500">
          Congratulations on completing <strong>{title}!</strong> You can now
          schedule your proctored final exam. Please select a date and time when
          you will be available to take the test
        </p>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="sm:flex flex-col sm:items-start"
        >
          <TextInput
            label="Select date"
            type="datetime-local"
            {...register('date')}
            error={errors.date?.message}
            min={moment()
              .add(1, 'day')
              .startOf('day')
              .format('YYYY-MM-DDTHH:mm')}
          />
          <div className="flex w-full justify-end">
            <Button
              type="submit"
              className="bg-green-300 text-black py-2 px-4 mb-5 rounded cursor-pointer hover:bg-green-500"
            >
              Select
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}
