import { IInviteesResponse } from '@/types'

import { useLazyGet } from '../utils/wrapper'
import endpoints from '../endpoints'

export const useInvitees = (onSuccess: (_data: IInviteesResponse) => void) => {
  return useLazyGet(endpoints.invitees, undefined, {
    onSuccess,
  })
}
