import React, { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'

import { Button, Form, Modal, TextInput } from '@/components'
import { TCreateConsultationFormSchema, TFunc } from '@/types'
import { createConsultationSchema } from '@/utils'
import { useCreateConsultation } from '@/apis'
import { useHookForm } from '@/hooks'

interface ICreateConsultationProps {
  onClose?: TFunc
}

export const CreateConsultation: React.FC<ICreateConsultationProps> = ({
  onClose,
}) => {
  const {
    handler: {
      register,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(createConsultationSchema)

  const { mutateAsync: createConsultation } = useCreateConsultation()

  const onSubmit: SubmitHandler<TCreateConsultationFormSchema> = useCallback(
    async data => {
      const response = await createConsultation(data)
      if (response.data.success) {
        onClose?.()
      }
    },
    [createConsultation, onClose],
  )

  return (
    <Modal isOpen canClose={true} onClose={onClose}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white p-8 rounded-md shadow w-full"
      >
        <TextInput
          label="First Name"
          type="text"
          placeholder="Name"
          error={errors.firstName?.message}
          {...register('firstName')}
        />
        <TextInput
          label="Last Name"
          type="text"
          placeholder="Name"
          error={errors.lastName?.message}
          {...register('lastName')}
        />
        <TextInput
          label="E-mail"
          type="email"
          placeholder="E-mail"
          error={errors.email?.message}
          {...register('email')}
        />
        <TextInput
          label="Phone Number"
          type="text"
          placeholder="+ 1"
          error={errors.phoneNumber?.message}
          {...register('phoneNumber')}
        />
        <Button
          type="submit"
          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
        >
          Send an application
        </Button>
      </Form>
    </Modal>
  )
}
