import React, { useCallback, useEffect, useState } from 'react'
import {
  FieldErrors,
  UseFormTrigger,
  UseFormSetValue,
  UseFormGetValues,
} from 'react-hook-form'

import { IOption, IPlan, IPlanResponse, TCreatePlanFormSchema } from '@/types'
import { Select } from '@/components'
import { useQuizzes } from '@/apis'
import { useAuth } from '@/hooks'

export interface ISelectQuizProps {
  errors: FieldErrors<TCreatePlanFormSchema>
  trigger: UseFormTrigger<TCreatePlanFormSchema>
  setValue: UseFormSetValue<TCreatePlanFormSchema>
  getValues: UseFormGetValues<TCreatePlanFormSchema>
}

export const SelectQuiz: React.FC<ISelectQuizProps> = ({
  errors,
  trigger,
  setValue,
  getValues,
}) => {
  const { isSchoolOwner } = useAuth()
  const [quizzes, setQuizzes] = useState<IPlan[]>([])

  const onQuizzesSuccess = useCallback((_data: IPlanResponse) => {
    setQuizzes(_data.data)
  }, [])

  const [getTests] = useQuizzes(onQuizzesSuccess)

  useEffect(() => {
    getTests()
  }, [getTests])

  const onSelect = useCallback(
    (_quiz: IOption<string>) => {
      setValue('quiz', _quiz.value)
      trigger('quiz')
    },
    [setValue, trigger],
  )

  const onFilter = useCallback(
    (plan: IPlan) => {
      return isSchoolOwner ? !!plan?.affiliate?._id : true
    },
    [isSchoolOwner],
  )

  return (
    <Select
      label="Quiz"
      className="mb-2"
      onChange={onSelect}
      value={getValues('quiz')}
      placeHolder="Select a quiz"
      error={errors.quiz?.message}
      options={quizzes
        .filter(onFilter)
        .map(_v => ({ value: _v._id, label: _v.title }))}
    />
  )
}
