import React from 'react'
import classNames from 'classnames'

import { IPageButtonsProps } from './types'
import { Button } from '../Button'

const PageButtons: React.FC<IPageButtonsProps> = ({
  range,
  currentPage,
  disabled,
  onPageChange,
}) => {
  return (
    <>
      {range.map((page: string | number, idx: number) => {
        const previousPage = range?.[idx - 1] || 0

        return (
          <React.Fragment key={idx}>
            {+page - +previousPage > 1 && (
              <Button
                disabled
                className="text-black py-2 px-4 rounded cursor-pointer hover:bg-green-500"
              >
                ...
              </Button>
            )}
            <Button
              key={idx}
              onClick={() =>
                !isNaN(+page) && onPageChange && onPageChange(+page)
              }
              className={classNames(
                'text-black py-2 px-4 rounded cursor-pointer hover:bg-green-500',
                {
                  disabled,
                  'bg-green-50': currentPage !== page,
                  'bg-green-300': currentPage === page,
                },
              )}
            >
              {page}
            </Button>
          </React.Fragment>
        )
        return (
          <React.Fragment key={idx}>
            {+page - +previousPage > 1 && <li>...</li>}
            <li
              key={idx}
              className={classNames('', {
                disabled,
                active: currentPage === page,
              })}
              onClick={() =>
                !isNaN(+page) && onPageChange && onPageChange(+page)
              }
            >
              <a>{page}</a>
            </li>
          </React.Fragment>
        )
      })}
    </>
  )
}

export default PageButtons
