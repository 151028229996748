import React, { useCallback, useMemo } from 'react'
import moment from 'moment'

import { useAuth, useDocuments } from '@/hooks'
import { ITask, TTaskStatus } from '@/types'
import { generateFilePath } from '@/utils'
import { DATE_FORMAT } from '@/constants'
import { Button } from '@/components'

export const DocumentRow: React.FC<ITask> = ({ ...item }) => {
  const { me, isAdmin, isSchoolOwner } = useAuth()
  const { onUpdateStatus, onDownloadDocument } = useDocuments()

  const openFile = useCallback(
    (filePath?: string) => () => {
      if (!filePath) return
      window.open(generateFilePath(filePath) as string, '__blank')
    },
    [],
  )

  const isStudent = useMemo(
    () => !!me && !isAdmin && !isSchoolOwner,
    [isAdmin, me, isSchoolOwner],
  )

  return (
    <tr className="hover:bg-gray-100">
      <td className="py-2 px-4 border-b text-start">
        <a
          target="_blank"
          href={item?.meetingLink ?? '#'}
          className="text-indigo-500 font-bold"
        >
          {item?.meetingLink?.substring(0, 15) ?? '-'}
        </a>
      </td>
      <td className="py-2 px-4 border-b text-start">{item?.plan?.title}</td>
      <td className="py-2 px-4 border-b text-start">{item?.type}</td>
      <td className="py-2 px-4 border-b text-start">
        {item?.path ? (
          <Button
            onClick={openFile(item?.path)}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Result
          </Button>
        ) : (
          '-'
        )}
      </td>
      <td className="py-2 px-4 border-b text-start">
        {item?.diplomaPath ? (
          <Button
            onClick={() => onDownloadDocument(item._id)}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Download
          </Button>
        ) : (
          '-'
        )}
      </td>
      <td className="py-2 px-4 border-b text-start">
        {moment(item?.date).format(DATE_FORMAT)}
      </td>
      {!isStudent && (
        <>
          <td className="py-2 px-4 border-b text-start">
            {item?.createdBy?.name}
          </td>
          <td className="py-2 px-4 border-b text-start">
            {item?.createdBy?.email}
          </td>
          <td className="py-2 px-4 border-b text-start">
            {item?.ready ? (
              <span className="'inline-block text-black text-sm px-3 py-1 rounded-full bg-green-400'">
                Accepted
              </span>
            ) : (
              <>
                <Button
                  onClick={() => onUpdateStatus(item._id, TTaskStatus.REJECTED)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Reject
                </Button>
                <Button
                  onClick={() => onUpdateStatus(item._id, TTaskStatus.ACCEPTED)}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                >
                  Apply
                </Button>
              </>
            )}
          </td>
        </>
      )}
    </tr>
  )
}
