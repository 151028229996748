import React, { useMemo } from 'react'

import { Button, FieldArray, TextInput } from '@/components'

import { ISectionProps } from '../types'
import { UploadImage } from './uploadImg'

import advanceImg from '@/assets/images/advance.png'

export const EditAdvanceSection: React.FC<ISectionProps> = ({
  errors,
  control,
  onUpload,
  register,
  getValues,
  sectionIndex,
}) => {
  const _errors = useMemo(
    () => errors.sections?.[sectionIndex],
    [sectionIndex, errors],
  )
  return (
    <div className="flex flex-col gap-2">
      <img src={advanceImg} alt="" className="w-full object-contain" />
      <div>
        <FieldArray
          control={control}
          name={`sections.${sectionIndex}.blocks`}
          children={(_fields, _u, append, _remove) => (
            <>
              <div className="flex flex-wrap gap-4">
                {_fields.map((field, index) => (
                  <div
                    key={field.id}
                    className="flex space-x-4 pt-2 pb-2 items-center"
                  >
                    <UploadImage
                      onSelect={onUpload(
                        `sections.${sectionIndex}.blocks.${index}.image`,
                      )}
                      imagePath={getValues(
                        `sections.${sectionIndex}.blocks.${index}.image.path`,
                      )}
                      error={_errors?.blocks?.[index]?.image?.file?.message}
                    />
                    <div className="flex flex-col gap-2">
                      <TextInput
                        label={`Block ${index + 1} Title`}
                        type="text"
                        placeholder={`Enter block ${index + 1} title`}
                        error={_errors?.blocks?.[index]?.title?.message}
                        {...register(
                          `sections.${sectionIndex}.blocks.${index}.title`,
                        )}
                      />
                      <TextInput
                        type="text"
                        label={`Block ${index + 1} Description`}
                        placeholder={`Enter block ${index + 1} description`}
                        error={_errors?.blocks?.[index]?.title?.message}
                        {...register(
                          `sections.${sectionIndex}.blocks.${index}.description`,
                        )}
                      />
                    </div>
                    {_fields.length > 0 && (
                      <Button
                        type="button"
                        onClick={() => _remove(index)}
                        style={{ height: 'fit-content' }}
                        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                ))}
              </div>
              <Button
                type="button"
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                onClick={() =>
                  append({
                    title: '',
                    image: {
                      file: undefined,
                      path: '',
                    },
                  })
                }
              >
                Add block
              </Button>
            </>
          )}
        />
      </div>
    </div>
  )
}
