import React from 'react'
import {
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js'

import { FormError } from '@/components'

interface Props {
  onChange: (..._ev: any[]) => void
  error?: string
}

export const CardNumberEl: React.FC<Props> = ({ onChange, error }) => {
  return (
    <div className="mb-4 w-1/2">
      <label
        htmlFor="cardNumber"
        className="block text-black text-sm font-semibold mb-2"
      >
        Card Number
      </label>
      <CardNumberElement
        onChange={onChange}
        className="w-full border border-gray-300 rounded-md p-2"
      />
      {!!error && <FormError error={error} />}
    </div>
  )
}

export const CardExpiryEl: React.FC<Props> = ({ onChange, error }) => {
  return (
    <div className="mb-4 w-3/12">
      <label
        htmlFor="cardNumber"
        className="block text-black text-sm font-semibold mb-2"
      >
        Expiry
      </label>
      <CardExpiryElement
        onChange={onChange}
        className="w-full border border-gray-300 rounded-md p-2"
      />
      {!!error && <FormError error={error} />}
    </div>
  )
}

export const CardCvcEl: React.FC<Props> = ({ onChange, error }) => {
  return (
    <div className="mb-4 w-3/12">
      <label
        htmlFor="cardNumber"
        className="block text-black text-sm font-semibold mb-2"
      >
        CVC
      </label>
      <CardCvcElement
        onChange={onChange}
        className="w-full border border-gray-300 rounded-md p-2"
      />
      {!!error && <FormError error={error} />}
    </div>
  )
}
