import React, { CSSProperties, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import _ from 'lodash'

import { Placement } from '@/types'

const UNMOUNTED = 'unmounted'
const EXITED = 'exited'
const ENTERING = 'entering'
const ENTERED = 'entered'
const EXITING = 'exiting'

const transitionStyles = {
  entering: { opacity: 0, transform: 'translateY(-10px)' },
  entered: { opacity: 1, transform: 'translateY(0px)' },
  exiting: { opacity: 0, transform: 'translateY(-10px)' },
  exited: { opacity: 0, transform: 'translateY(-10px)' },
}

interface IProps {
  show?: boolean
  duration: number
  placement?: Placement
  className?: string
  style?: CSSProperties
  children?: React.ReactNode
}

export const SlideInOut: React.FC<IProps> = ({
  show,
  duration,
  placement,
  className,
  style,
  children,
}) => {
  const [status, setStatus] = useState<
    keyof typeof transitionStyles | 'unmounted'
  >(UNMOUNTED)

  const performEnter = useCallback(() => {
    setStatus(ENTERING)
    _.delay(setStatus, 0, ENTERED)
  }, [])

  const performExit = useCallback(() => {
    setStatus(EXITING)
    _.delay(setStatus, duration, EXITED)
  }, [duration])

  const updateStatus = useCallback(
    (nextStatus: typeof status | null) => {
      if (nextStatus !== null) {
        if (nextStatus === ENTERING) {
          performEnter()
        } else {
          performExit()
        }
      } else if (status === EXITED) {
        setStatus(UNMOUNTED)
      }
    },
    [performEnter, performExit, status],
  )

  useEffect(() => {
    let nextStatus: typeof status | null = null
    if (show) {
      if (status !== ENTERING && status !== ENTERED) {
        nextStatus = ENTERING
      }
    } else {
      if (status === ENTERING || status === ENTERED) {
        nextStatus = EXITING
      }
    }
    updateStatus(nextStatus)
  }, [show, status, updateStatus])

  if (status === UNMOUNTED) return null

  return (
    <div
      className={classNames(placement, 'transition-container', className)}
      style={{
        ...style,
        transition: `opacity ${duration}ms, transform ${duration}ms`,
        ...transitionStyles[status],
      }}
    >
      {children}
    </div>
  )
}
