import React from 'react'
import classNames from 'classnames'
import { IIconProps } from '@/components/Icon/types'

export const Icon = React.forwardRef<HTMLElement, IIconProps>(
  ({ El = 'span', name, className, ...rest }, ref) => {
    if (!name) return null

    return (
      <El className={classNames(`ic-${name}`, className)} ref={ref} {...rest} />
    )
  },
)
