import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { IModule, IPlan, TCreatePlanFormSchema, TProgress } from '@/types'
import { Button, Form, RadioButton, TextInput } from '@/components'
import { useAuth, useH5P, useHookForm } from '@/hooks'
import { createPlanForm } from '@/utils'

import { AccordionItem, SelectQuiz } from './components'

interface ICreatePlanSchemaProps {
  plan?: IPlan
  type: TProgress
  onUpdate?: (_data: any, _bool: boolean) => Promise<void>
}

const defaultModule: IModule[] = [{ id: Date.now(), name: 'New', contents: [] }]

export const CreatePlanSchema: React.FC<ICreatePlanSchemaProps> = ({
  plan,
  type,
  onUpdate,
}) => {
  const { isAdmin } = useAuth()
  const navigate = useNavigate()
  const { contentService } = useH5P()
  const [deletedItems, setDeletedItems] = useState<string[]>([])
  const [modules, setModules] = useState<IModule[]>(defaultModule)

  useEffect(() => {
    if (!plan) return
    setModules(plan.modules)
  }, [plan])

  useEffect(() => {
    if (!plan && deletedItems.length) {
      ;(async () => {
        await Promise.all(
          deletedItems
            .filter(_v => _v !== 'new')
            .map(async _id => {
              await contentService.delete(_id)
            }),
        )
        setDeletedItems([])
      })()
    }
  }, [contentService, plan, deletedItems])

  useEffect(() => {
    return () => {
      setModules(defaultModule)
    }
  }, [])

  const {
    handler: {
      trigger,
      register,
      setValue,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(createPlanForm, {
    defaultValues: {
      price: plan?.price || 0,
      title: plan?.title || '',
      // @ts-ignore
      quiz: plan?.quiz || undefined,
      description: plan?.description || '',
      visible: plan?.visible ? 'true' : 'false',
    },
  })

  const onSubmit: SubmitHandler<TCreatePlanFormSchema> = useCallback(
    async ({ quiz, ...data }) => {
      await onUpdate?.(
        {
          ...data,
          [quiz ? 'quiz' : '']: quiz,
          _id: plan?._id,
          visible: data.visible === 'true',
          modules: modules
            .filter(module => !!module.contents.length)
            .map(_v => ({
              ..._v,
              contents: _v.contents.map(content => ({
                ...content,
                id: `${content.id}`,
              })),
            })),
        },
        !plan,
      )
      navigate(-1)
    },
    [plan, modules, navigate, onUpdate],
  )

  const addModule = useCallback(() => {
    let item: number
    setModules(prev => {
      item = prev.length
      const updatedModules = [
        ...prev,
        { id: Date.now(), contents: [], name: 'New' },
      ]
      return [...updatedModules]
    })
    const timer = setTimeout(() => {
      document
        .getElementById(`Module${item}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      clearTimeout(timer)
    }, 200)
  }, [])

  const onAddLesson = useCallback(
    (idx: number) => () => {
      modules[idx].contents.push({ id: 'new', title: '' })
      setModules([...modules])
    },
    [modules],
  )

  const onChangeModuleName = useCallback(
    (idx: number) => (name: string) => {
      modules[idx].name = name
      setModules([...modules])
    },
    [modules],
  )

  const onDeleteLesson = useCallback(
    (idx: number) => (contentIndex: number) => {
      let contentId: string
      const contents = modules[idx].contents.filter((_v, id) => {
        if (id === contentIndex) {
          contentId = _v.id
        }
        return id !== contentIndex
      })
      modules[idx].contents = contents
      setModules([...modules])
      setDeletedItems(prev => {
        if (!prev.includes(contentId) && contentId !== 'new') {
          return [...prev, contentId]
        }
        return prev
      })
    },
    [modules],
  )

  const onSaveLesson = useCallback(
    (idx: number) =>
      ({
        id,
        title,
        contentIndex,
      }: {
        id: string
        title: string
        contentIndex: number
      }) => {
        setModules(prev => {
          prev[idx].contents.map((_v, index) => {
            if (index === contentIndex && _v.id !== id) {
              _v.id = id
              _v.title = title
            }
            return _v
          })
          return [...prev]
        })
      },
    [],
  )

  const onCheckValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore
      setValue(e.target.name, e.target.value)
      // @ts-ignore
      trigger(e.target.name)
    },
    [setValue, trigger],
  )

  const isUpdate = useMemo(() => !!plan, [plan])

  const label = useMemo(
    () => (type === TProgress.COURSE ? 'Course' : 'Quiz'),
    [type],
  )

  return (
    <div
      className="p-4 w-full h-full overflow-auto"
      style={{ height: 'calc(100% - 72px)' }}
    >
      <div className="w-full bg-white p-8 rounded-md shadow-md">
        <Button
          onClick={() => navigate(-1)}
          className="text-gray-500 hover:text-gray-800 mb-4 inline-flex items-center"
        >
          <i className="fi fi-br-arrow-left h-5 mr-3"></i>
          Back
        </Button>
        <h2 className="text-2xl font-semibold mb-6">
          {isUpdate ? `Update ` : `Create `}
          {label}
        </h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Title"
            placeholder={`${label} title`}
            value={getValues('title')}
            error={errors.title?.message}
            {...register('title', {
              onChange: () => {
                trigger('title')
              },
            })}
          />
          <TextInput
            label="Description"
            value={getValues('description')}
            placeholder={`${label} description`}
            error={errors.description?.message}
            {...register('description', {
              onChange: () => {
                trigger('description')
              },
            })}
          />
          <TextInput
            min={0}
            label="Price"
            type="number"
            placeholder={`${label} price`}
            error={errors.price?.message}
            value={getValues('price')}
            {...register('price', {
              onChange: () => {
                trigger('price')
              },
            })}
          />
          {isAdmin && (
            <RadioButton
              label="Publish"
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              value={getValues('visible')}
              error={errors.visible?.message}
              {...register('visible')}
              onChange={onCheckValue}
            />
          )}
          {type === TProgress.COURSE && (
            <SelectQuiz
              errors={errors}
              // @ts-ignore
              trigger={trigger}
              // @ts-ignore
              setValue={setValue}
              // @ts-ignore
              getValues={getValues}
            />
          )}
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-semibold">Modules</h2>
            <Button
              type="button"
              onClick={addModule}
              className="bg-green-600 hover:bg-gray-800 text-white py-2 px-4 rounded border"
            >
              Add Module
            </Button>
          </div>
          <div className="rounded border mb-4">
            {modules.map((module, idx) => (
              <AccordionItem
                id={module.id}
                key={module.id}
                name={module.name}
                onAdd={onAddLesson(idx)}
                onSave={onSaveLesson(idx)}
                contents={module.contents}
                onDelete={onDeleteLesson(idx)}
                onChangeName={onChangeModuleName(idx)}
              />
            ))}
          </div>
          <Button
            type="submit"
            className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-700"
          >
            {isUpdate ? `Update ` : `Create `}
            {label}
          </Button>
        </Form>
      </div>
    </div>
  )
}
