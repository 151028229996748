import {
  homePath,
  whatWeDo,
  signInPath,
  signUpPath,
  ourMission,
  testimonials,
  honoraryDoctoratePath,
} from '@/utils'

interface ITab {
  path: string
  element?: string
  isButton?: boolean
  className?: string
  defaultTitle: string
}

export const navItems: ITab[] = [
  {
    path: homePath,
    element: whatWeDo,
    defaultTitle: 'What We Do',
    className: '"block text-lg md:text-[#161C24]"',
  },
  {
    path: homePath,
    element: ourMission,
    defaultTitle: 'Our Mission',
    className:
      'block text-lg text-[#161C24] hover:bg-gray-100 md:hover:bg-transparent',
  },
  {
    path: homePath,
    element: testimonials,
    defaultTitle: 'Testimonials',
    className:
      'block text-lg text-[#161C24] hover:bg-gray-100 md:hover:bg-transparent',
  },
  {
    path: honoraryDoctoratePath,
    defaultTitle: 'Honorary Doctorate',
    className:
      'block text-lg text-[#161C24] hover:bg-gray-100 md:hover:bg-transparent',
  },
  {
    isButton: true,
    path: signUpPath,
    defaultTitle: 'Sign up',
    className: 'px-6 py-2.5 rounded-lg bg-[#3168A0] text-white',
  },
  {
    isButton: true,
    path: signInPath,
    defaultTitle: 'Log in',
    className: 'border border-[#224970] rounded-lg px-6 py-2.5 text-[#224970]',
  },
]
