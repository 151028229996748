import React from 'react'
import classNames from 'classnames'
import moment from 'moment'

import { DATE_FORMAT } from '@/constants'
import { useUsers } from '@/hooks'
import { IUser } from '@/types'

export const UserRow: React.FC<IUser> = ({ ...item }) => {
  const { onEdit } = useUsers()
  return (
    <tr
      onClick={() => onEdit(item)}
      className="hover:bg-gray-100 cursor-pointer"
    >
      <td className="py-2 px-4 border-b text-start">{item.name}</td>
      <td className="py-2 px-4 border-b text-start">{item.email}</td>
      <td className="py-2 px-4 border-b text-start">
        {item.affiliate?.name ?? '_'}
      </td>
      <td className="py-2 px-4 border-b text-start">
        <span
          className={classNames(
            'inline-block text-black text-sm px-3 py-1 rounded-full',
            {
              'bg-zinc-300': !item.emailVerified,
              'bg-green-400': item.emailVerified,
            },
          )}
        >
          {item.emailVerified ? 'Verified' : 'Unverified'}
        </span>
      </td>
      <td className="py-2 px-4 border-b text-start">
        {moment(item?.createdAt).format(DATE_FORMAT)}
      </td>
    </tr>
  )
}
