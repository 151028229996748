import React, { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'
import moment from 'moment'

import { IAvailableZone, TCreateCalendarFormSchema, TFunc } from '@/types'
import { useCreateCalendar, useUpdateCalendar } from '@/apis'
import { Button, Form, Modal, TextInput } from '@/components'
import { createCalendarForm } from '@/utils'
import { useHookForm } from '@/hooks'

interface ICalendarForm {
  onClose: TFunc
  onRefresh: TFunc
  zone?: IAvailableZone | null
}

export const CalendarForm: React.FC<ICalendarForm> = ({
  zone,
  onClose,
  onRefresh,
}) => {
  const {
    handler: {
      trigger,
      register,
      getValues,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(createCalendarForm, {
    defaultValues: {
      location: zone?.location ?? '',
      to: zone ? moment(zone?.to).toDate() : moment().add(1, 'hour').toDate(),
      from: zone
        ? moment(zone?.from).toDate()
        : moment().add(15, 'minute').toDate(),
    },
  })

  const { mutateAsync: createCalendar } = useCreateCalendar()
  const { mutateAsync: updateCalendar } = useUpdateCalendar()

  const onSubmit: SubmitHandler<TCreateCalendarFormSchema> = useCallback(
    async data => {
      const request = zone?._id ? updateCalendar : createCalendar

      const response = await request({
        ...data,
        // @ts-ignore
        id: zone?._id,
      })

      if (response.data.success) {
        onRefresh()
        onClose()
      }
    },
    [createCalendar, onClose, onRefresh, updateCalendar, zone?._id],
  )

  return (
    <Modal isOpen onClose={onClose} canClose={true}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="w-full">
              <TextInput
                label="From"
                value={moment(getValues('from')).format('YYYY-MM-DDTHH:mm')}
                type="datetime-local"
                error={errors.from?.message}
                {...register('from', {
                  onChange: () => {
                    trigger('from')
                  },
                })}
              />
              <TextInput
                label="To"
                value={moment(getValues('to')).format('YYYY-MM-DDTHH:mm')}
                type="datetime-local"
                error={errors.from?.message}
                {...register('to', {
                  onChange: () => {
                    trigger('to')
                  },
                })}
              />
              <TextInput
                label="Location"
                error={errors.location?.message}
                value={getValues('location')}
                {...register('location', {
                  onChange: () => {
                    trigger('location')
                  },
                })}
              />
            </div>
          </div>
        </div>
        <div className="bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <Button
            type="submit"
            // eslint-disable-next-line max-len
            className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
