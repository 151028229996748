import React from 'react'
import { Outlet } from 'react-router-dom'

import { AppFooter, AppHeader } from '@/layout'

export const AppLayout: React.FC<{
  isDefault?: boolean
  children?: React.ReactNode
}> = ({ children, isDefault = true }) => {
  return (
    <>
      {isDefault && <AppHeader />}
      <main>{children || <Outlet />}</main>
      {isDefault && <AppFooter />}
    </>
  )
}
