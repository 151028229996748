import classNames from 'classnames'

import { FormError } from '../'

import { ISelectProps } from './types'

import './styles.scss'

export const Select = <
  T extends React.SelectHTMLAttributes<HTMLSelectElement>['value'],
>({
  value,
  error,
  options,
  disabled,
  onChange,
  className = 'w-100',
  label,
  required,
  placeHolder,
  renderOption,
}: ISelectProps<T>) => {
  return (
    <label className={classNames('select-input', className)}>
      {!!label && (
        <span className="name form-label">
          {label} {required && <sup>*</sup>}
        </span>
      )}
      <span className="select">
        <select
          className="border border-1 px-2 rounded-md w-full py-1"
          disabled={disabled}
          value={value ?? ''}
          onChange={_e =>
            onChange?.(
              options[_e.currentTarget.selectedIndex - (placeHolder ? 1 : 0)],
            )
          }
        >
          {placeHolder && (
            <option value="" disabled>
              {placeHolder}
            </option>
          )}

          {options.map((option, index) => (
            <option value={option.value} key={index}>
              {renderOption ? renderOption(option, index) : option.label}
            </option>
          ))}
        </select>
      </span>
      {!!error && <FormError error={error} />}
    </label>
  )
}
