import React, { useCallback, useState } from 'react'
import {
  Avatar,
  Message,
  Sidebar,
  MessageList,
  MessageInput,
  Conversation,
  MainContainer,
  ChatContainer,
  ConversationList,
  ConversationHeader,
} from '@chatscope/chat-ui-kit-react'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
import moment from 'moment'

import { generateFilePath, selectUser } from '@/utils'
import { useAuth, useSupport } from '@/hooks'

export const SupportPage: React.FC = () => {
  const { me, isAdmin, isSchoolOwner } = useAuth()
  const [message, setMessage] = useState<string>('')
  const { currentChat, chats, messages, sendMessage, joinChat } = useSupport()

  const onSend = useCallback(() => {
    if (message.trim()) {
      sendMessage(message)
      setMessage('')
    }
  }, [sendMessage, message])

  const _onJoin = useCallback(
    (chatId: string) => () => {
      if (chatId !== currentChat?._id) {
        joinChat(chatId)
      }
    },
    [joinChat, currentChat],
  )

  return (
    <div style={{ position: 'relative', height: '600px' }}>
      <MainContainer>
        {(isAdmin || isSchoolOwner) && me && (
          <Sidebar position="left" scrollable={false}>
            <ConversationList>
              {chats.map(chat => (
                <Conversation
                  key={chat._id}
                  info={chat.lastMessage}
                  onClick={_onJoin(chat._id)}
                  unreadCnt={chat.unreadCounts}
                  name={selectUser(chat, me)?.name}
                  lastSenderName={selectUser(chat, me)?.name}
                >
                  <Avatar
                    src={
                      generateFilePath(selectUser(chat, me)?.avatar) ||
                      'https://picsum.photos/200'
                    }
                  />
                </Conversation>
              ))}
            </ConversationList>
          </Sidebar>
        )}
        <ChatContainer>
          {!!currentChat?._id && !!me && (
            <ConversationHeader>
              <Avatar
                src={
                  generateFilePath(selectUser(currentChat, me)?.avatar) ||
                  'https://picsum.photos/200'
                }
                name={
                  selectUser(currentChat, me)?.enrollment?.name ||
                  selectUser(currentChat, me)?.name
                }
              />
              <ConversationHeader.Content
                userName={
                  selectUser(currentChat, me)?.enrollment?.name ||
                  selectUser(currentChat, me)?.name
                }
              />
            </ConversationHeader>
          )}
          <MessageList>
            {messages.map(message => (
              <Message
                model={{
                  message: message.text,
                  sender: message.user.name,
                  direction:
                    message.user._id === me?._id ? 'outgoing' : 'incoming',
                  position: 'normal',
                  sentTime: moment(message.createdAt).fromNow(),
                }}
                avatarSpacer
                key={message._id}
              >
                <Avatar
                  name={message.user.name}
                  src={
                    generateFilePath(message.user.avatar) ||
                    'https://picsum.photos/200'
                  }
                />
              </Message>
            ))}
          </MessageList>
          {!!currentChat?._id && (
            <MessageInput
              value={message}
              onSend={onSend}
              sendButton={true}
              attachButton={false}
              onChange={setMessage}
              placeholder="Type message here"
            />
          )}
        </ChatContainer>
      </MainContainer>
    </div>
  )
}
