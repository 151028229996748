import React, { useRef } from 'react'
import classNames from 'classnames'

import { useOnClickOutside } from '@/hooks'

import { IModalProps } from './types'
import { Button } from '../Button'

export const Modal: React.FC<IModalProps> = ({
  isOpen,
  onClose,
  children,
  isFull = false,
  canClose = true,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, canClose, onClose)

  return (
    <div
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      className={classNames('relative z-10', {
        hidden: !isOpen,
      })}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div
        className={classNames('fixed inset-0 z-10 overflow-y-auto', {
          'w-full': isFull,
          'w-screen': !isFull,
        })}
      >
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            ref={ref}
            className={classNames(
              'relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full',
              {
                'm-4': isFull,
                'sm:max-full': isFull,
                'sm:max-w-lg': !isFull,
              },
            )}
          >
            <div className="bg-green-300 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              {canClose && (
                <div className="flex justify-end">
                  <Button className="flex justify-center" onClick={onClose}>
                    <i className="fi fi-br-cross h-4"></i>
                  </Button>
                </div>
              )}
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
