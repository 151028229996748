import React, { useCallback, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { Navigate, useNavigate } from 'react-router-dom'

import { useHookForm, useShareAffiliate, useToast } from '@/hooks'
import { affiliatesPath, createAffiliateSchema } from '@/utils'
import { Button, Form, Tabs, TextInput } from '@/components'
import { TCreateAffiliateFormSchema } from '@/types'
import { useUpdateAffiliate } from '@/apis'

export const AffiliatePreviewPage: React.FC = () => {
  const navigate = useNavigate()
  const { addToast } = useToast()
  const { affiliate } = useShareAffiliate()
  const [activeFilter, setFilter] = useState<'details' | 'school'>('school')
  const {
    handler: {
      register,
      handleSubmit,
      formState: { errors },
    },
  } = useHookForm(createAffiliateSchema, {
    defaultValues: {
      bankAddress: affiliate?.bankAddress,
      bankBranchAddress: affiliate?.bankBranchAddress,
      bankName: affiliate?.bankName,
      companyName: affiliate?.companyName,
      companyNumber: affiliate?.companyNumber,
      contact: affiliate?.contact,
      name: affiliate?.name,
      postCode: affiliate?.postCode,
      subDomain: affiliate?.subDomain,
      websiteUrl: affiliate?.websiteUrl,
    },
  })

  const { mutateAsync: updateAffiliate } = useUpdateAffiliate()

  const onSubmit: SubmitHandler<TCreateAffiliateFormSchema> = useCallback(
    async data => {
      if (!affiliate?._id) return
      const response = await updateAffiliate({
        ...data,
        id: affiliate?._id,
      })
      if (response.data.success) {
        addToast('success', 'The school was updated successfully.')
        navigate(affiliatesPath)
      }
    },
    [affiliate?._id, updateAffiliate, navigate, addToast],
  )

  if (!affiliate) {
    return <Navigate to={affiliatesPath} />
  }

  return (
    <div className="p-4 w-full overflow-auto">
      <Tabs
        onChange={setFilter}
        activeTab={activeFilter}
        tabs={[
          { label: 'School', key: 'school' },
          { label: 'Details', key: 'details' },
        ]}
      />
      {activeFilter === 'school' && (
        <Form
          // @ts-ignore
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white p-8 rounded-md shadow w-full"
        >
          <div className="pl-4">
            <TextInput
              label="Name of school"
              type="text"
              placeholder="Enter the name"
              error={errors.name?.message}
              {...register('name')}
            />
            <div className="flex flex-wrap items-center gap-2">
              <TextInput
                label="Domain url"
                type="text"
                placeholder="Enter the domain url"
                error={errors.subDomain?.message}
                {...register('subDomain')}
              />
              <p>.unitylearn.org</p>
            </div>
          </div>
          <h6 className="font-bold tracki mt-4 mb-4">Company contact info</h6>
          <div className="pl-4">
            <TextInput
              label="Company Name"
              type="text"
              placeholder="Enter the company name"
              error={errors.companyName?.message}
              {...register('companyName')}
            />
            <TextInput
              label="Company Phone Number"
              type="text"
              placeholder="+1 000 0000000"
              error={errors.companyNumber?.message}
              {...register('companyNumber')}
            />
            <TextInput
              label="Postcode"
              type="number"
              placeholder="Enter the postal code"
              error={errors.postCode?.message}
              {...register('postCode')}
            />
            <TextInput
              label="Website URL"
              type="text"
              placeholder="Enter the link"
              error={errors.websiteUrl?.message}
              {...register('websiteUrl')}
            />
          </div>
          <h6 className="font-bold track mt-4 mb-4">
            Administrator Information
          </h6>
          <div className="pl-4">
            <TextInput
              label="First Name"
              type="text"
              placeholder="Enter (BRN)"
              error={errors.contact?.firstName?.message}
              {...register('contact.firstName')}
            />
            <TextInput
              label="Second Name"
              type="text"
              placeholder="Enter second name"
              error={errors.contact?.lastName?.message}
              {...register('contact.lastName')}
            />
            <TextInput
              label="Phone Number"
              type="text"
              placeholder="+1 000 0000000"
              error={errors.contact?.phoneNumber?.message}
              {...register('contact.phoneNumber')}
            />
            <TextInput
              label="Email"
              type="email"
              placeholder="Enter the email"
              error={errors.contact?.email?.message}
              {...register('contact.email')}
            />
            <TextInput
              label="Mailing address"
              type="text"
              placeholder="Enter the address"
              error={errors.contact?.mailingAddress?.message}
              {...register('contact.mailingAddress')}
            />
          </div>
          <h6 className="font-bold tracki mt-4 mb-4">Financial information</h6>
          <div className="pl-4">
            <TextInput
              label="Bank name"
              type="text"
              placeholder="Enter the bank name"
              error={errors.bankName?.message}
              {...register('bankName')}
            />
            <TextInput
              label="Billing address"
              type="text"
              placeholder="Enter the address"
              error={errors.bankAddress?.message}
              {...register('bankAddress')}
            />
            <TextInput
              label="Branch address"
              type="text"
              placeholder="Enter the address"
              error={errors.bankBranchAddress?.message}
              {...register('bankBranchAddress')}
            />
          </div>
          <Button
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
          >
            Save
          </Button>
        </Form>
      )}
      {activeFilter === 'details' && (
        <div className="flex justify-center gap-2 pt-3">
          <div className="w-1/4">
            <TextInput
              disabled
              readOnly
              label="Users"
              value={affiliate.users}
            />
            <TextInput
              disabled
              readOnly
              label="Courses"
              value={affiliate.courses}
            />
            <TextInput
              disabled
              readOnly
              label="Quizzes"
              value={affiliate.quizzes}
            />
            <TextInput
              disabled
              readOnly
              label="Payments"
              value={affiliate.payments}
            />
          </div>
        </div>
      )}
    </div>
  )
}
