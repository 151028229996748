import React, { useCallback, useEffect, useState } from 'react'

import { useGenerateAccountLink, usePaymentMethods } from '@/apis'
import { Button, DataTable } from '@/components'
import { IPaymentMethod } from '@/types'

import { methodTabs } from '../constant'
import { MethodRow } from './methodRow'

export const MethodsTab: React.FC = () => {
  const [methods, setMethods] = useState<IPaymentMethod[]>([])

  const { mutateAsync: generateAccountLink } = useGenerateAccountLink()

  const onGenerateUrl = useCallback(async () => {
    const response = await generateAccountLink({})
    // @ts-ignore
    window.open(response.data)
  }, [generateAccountLink])

  const onPaymentMethodsSuccess = useCallback((data: IPaymentMethod[]) => {
    setMethods(data)
  }, [])

  const [getMethods] = usePaymentMethods(onPaymentMethodsSuccess)

  useEffect(() => {
    getMethods()
  }, [getMethods])

  return (
    <React.Fragment>
      <div className="flex justify-end items-center p-4">
        <Button
          onClick={onGenerateUrl}
          className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded border"
        >
          Connect stripe
        </Button>
      </div>
      <DataTable data={methods} headers={methodTabs}>
        {data => {
          return data.map((item, idx) => (
            <MethodRow
              key={idx}
              {...item}
              index={idx + 1}
              onRefresh={getMethods}
            />
          ))
        }}
      </DataTable>
    </React.Fragment>
  )
}
