import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components'
import { IPlan } from '@/types'

import { Player } from './components'

interface IPlanPreviewSchemaProps {
  plan?: IPlan
}

export const PlanPreviewSchema: React.FC<IPlanPreviewSchemaProps> = ({
  plan,
}) => {
  const navigate = useNavigate()

  return (
    <div
      className="p-4 w-full h-full overflow-auto"
      style={{ height: 'calc(100% - 4.5rem)' }}
    >
      <div className="w-full bg-white p-8 rounded-md shadow-md">
        <Button
          onClick={() => navigate(-1)}
          className="text-gray-500 hover:text-gray-800 mb-4 inline-flex items-center"
        >
          <i className="fi fi-br-arrow-left h-5 mr-3"></i>
          Back
        </Button>
        <h2 className="text-2xl font-semibold mb-6">{plan?.title}</h2>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">Modules</h2>
        </div>
        <div className="rounded border mb-4">
          {plan?.modules.map(module => {
            return module.contents.map(content => (
              <div key={content.id} className="p-4 shadow-sm">
                <div className="px-5 rounded">
                  <div className="accordion-content pt-0 overflow-hidden transition">
                    <div className="accordion-header transition flex space-x-5 items-center h-16 items-center justify-between">
                      <div className="flex gap-4">
                        <h3>{content.title}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <Player contentId={content.id} />
              </div>
            ))
          })}
        </div>
      </div>
    </div>
  )
}
