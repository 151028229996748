import {
  IResetPayload,
  IForgotPayload,
  ISignInPayload,
  ISignUpPayload,
  IResetResponse,
  ISignUpResponse,
  ISignInResponse,
  IForgotResponse,
} from '@/types'

import { usePost } from '../utils/wrapper'
import endpoints from '../endpoints'

export const useSignUp = () => {
  return usePost<ISignUpPayload, ISignUpResponse>(endpoints.signUp)
}

export const useSignIn = () => {
  return usePost<ISignInPayload, ISignInResponse>(endpoints.signIn)
}

export const useForgotPassword = () => {
  return usePost<IForgotPayload, IForgotResponse>(endpoints.forgot)
}

export const useResetPassword = () => {
  return usePost<IResetPayload, IResetResponse>(endpoints.reset)
}
