import { MutableRefObject, useEffect, useState } from 'react'

export const useOnScreen = (
  ref: MutableRefObject<HTMLDivElement | null>,
  rootMargin = '0px',
) => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    let currentRef: any = null
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry?.isIntersecting) setIntersecting(entry?.isIntersecting)
      },
      {
        rootMargin,
      },
    )
    if (ref && ref?.current) {
      currentRef = ref.current
      observer.observe(currentRef)
    }
    return () => {
      observer.unobserve(currentRef)
    }
  }, [ref, rootMargin])

  return isIntersecting
}
