import React, { useMemo } from 'react'
import { capitalize } from 'lodash'

import { FieldArray, TextInput } from '@/components'
import { Templates } from '@/types'

import { ISectionProps } from '../types'

import { UploadImage } from './uploadImg'

import defaultTemplate from '@/assets/images/defaultTemplate.png'
import heroImg from '@/assets/images/template1.png'

export const EditHeroSection: React.FC<ISectionProps> = ({
  errors,
  control,
  register,
  onUpload,
  getValues,
}) => {
  const templateName = getValues('name')
  const isDefault = useMemo(
    () => templateName === Templates.DEFAULT_TEMPLATE,
    [templateName],
  )
  return (
    <div className="flex flex-col gap-2">
      <img
        alt=""
        className="w-full object-contain"
        src={isDefault ? defaultTemplate : heroImg}
      />
      <div className="flex flex-wrap w-full justify-between items-center">
        <div className="w-1/2 pr-2">
          <TextInput
            type="text"
            label="Title"
            {...register('title')}
            placeholder="Enter the title"
            error={errors.title?.message}
          />
          <TextInput
            type="text"
            label="Description"
            {...register('description')}
            placeholder="Enter the description"
            error={errors.description?.message}
          />
          {!isDefault && (
            <TextInput
              type="text"
              label="Watch video"
              {...register('mainVideo')}
              placeholder="Enter the video url"
              error={errors.mainVideo?.message}
            />
          )}
        </div>
        <div className="w-1/2">
          <UploadImage
            maxWidth="100%"
            className="mb-4"
            onSelect={onUpload('image')}
            imagePath={getValues('image.path')}
            error={errors.image?.file?.message}
          />
        </div>
      </div>
      {!isDefault && (
        <div className="flex gap-2">
          <FieldArray name="socials" control={control}>
            {_fields =>
              _fields.map((_field, idx) => (
                <TextInput
                  type="text"
                  key={_field.id}
                  // @ts-ignore
                  label={capitalize(_field.type)}
                  {...register(`socials.${idx}.link`)}
                  // @ts-ignore
                  error={errors?.socials?.[idx]?.link?.message}
                  // @ts-ignore
                  placeholder={`Enter ${_field.type} url`}
                />
              ))
            }
          </FieldArray>
        </div>
      )}
    </div>
  )
}
