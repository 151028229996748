import React, { useCallback, useMemo, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import {
  IPlan,
  IRole,
  TFunc,
  IPageMeta,
  TProgress,
  RequestStatuses,
} from '@/types'
import { useMergeAffiliateCourse, useMergeAffiliateQuiz } from '@/apis'
import { Button, DataTable, WithPaginator } from '@/components'
import { ConfirmationModal } from '@/pages/shared'
import { previewPath, newPath } from '@/utils'
import { useAuth } from '@/hooks'

interface IPlanDetailsSchemaProps {
  plan?: IPlan
  path: string
  onRefresh?: TFunc
}

export const PlanDetailsSchema: React.FC<IPlanDetailsSchemaProps> = ({
  plan,
  path,
  onRefresh,
}) => {
  const navigate = useNavigate()
  const { me, isAdmin } = useAuth()
  const [isMerging, setIsMerging] = useState(false)
  const [pageMeta, setPageMeta] = useState<IPageMeta>({
    page: 1,
    pageSize: 10,
  })

  const { mutateAsync: mergeQuizContent } = useMergeAffiliateQuiz()
  const { mutateAsync: mergeCourseContent } = useMergeAffiliateCourse()

  const onPageMetaChange = useCallback((_page: number, _pageSize: number) => {
    setPageMeta({
      page: _page,
      pageSize: _pageSize,
    })
  }, [])

  const pageData = useMemo(() => {
    const firstPageIndex = (pageMeta.page - 1) * (pageMeta.pageSize || 10)
    const lastPageIndex = firstPageIndex + (pageMeta.pageSize || 10)
    return plan?.modules?.slice(firstPageIndex, lastPageIndex) || []
  }, [plan?.modules, pageMeta.page, pageMeta.pageSize])

  const disabled = useMemo(() => !plan?.modules.length, [plan?.modules.length])

  const disableEdit = useMemo(
    () =>
      me?.role === IRole.AffiliateOwner && !(plan?.affiliate || plan?.canEdit),
    [plan?.affiliate, plan?.canEdit, me?.role],
  )

  const onToggleMerge = useCallback(() => {
    setIsMerging(prev => !prev)
  }, [])

  const onConfirmMerge = useCallback(async () => {
    if (!plan?._id) return
    const isCourse = plan.type === TProgress.COURSE
    const request = isCourse ? mergeCourseContent : mergeQuizContent
    //   @ts-ignore
    const response = await request({
      [isCourse ? 'courseId' : 'id']: plan?._id,
    })

    if (response.data.success) {
      onRefresh?.()
      onToggleMerge()
      navigate(-1)
    }
  }, [
    navigate,
    plan?._id,
    plan?.type,
    onRefresh,
    onToggleMerge,
    mergeQuizContent,
    mergeCourseContent,
  ])

  if (!plan) {
    return <Navigate to={path} />
  }

  return (
    <>
      <div
        style={{ height: 'calc(100% - 72px)' }}
        className="p-4 w-full h-full overflow-auto"
      >
        {isMerging && (
          <ConfirmationModal
            buttonText="Merge"
            onClose={onToggleMerge}
            onConfirm={onConfirmMerge}
            description="Are you sure you need to merge all the content?"
          />
        )}
        <div className="w-full bg-white p-8 rounded-md shadow-md">
          <Button
            onClick={() => navigate(-1)}
            className="text-gray-500 hover:text-gray-800 mb-4 inline-flex items-center"
          >
            <i className="fi fi-br-arrow-left h-5 mr-3"></i>
            Back
          </Button>
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-2xl font-semibold">{plan?.title}</h2>
            <div className="flex gap-4">
              {plan.status === RequestStatuses.PENDING && isAdmin && (
                <Button
                  onClick={onToggleMerge}
                  className={classNames(
                    'bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded border',
                  )}
                >
                  Merge
                </Button>
              )}
              <Button
                disabled={disabled}
                onClick={() => navigate(`${path}/${previewPath}`)}
                className={classNames(
                  'bg-gray-100 text-black py-2 px-4 rounded border',
                  {
                    'opacity-50': disabled,
                    'hover:bg-gray-200': !disabled,
                    'cursor-not-allowed': disabled,
                  },
                )}
              >
                Preview
              </Button>
              <Button
                disabled={disableEdit}
                className={classNames(
                  'bg-black text-white py-2 px-4 rounded border',
                  {
                    'opacity-60': disableEdit,
                    'cursor-not-allowed': disableEdit,
                  },
                )}
                onClick={() => navigate(`${path}/${newPath}`)}
              >
                Customize
              </Button>
            </div>
          </div>
          <div className="w-full flex gap-8 mb-8">
            <div className="w-full">
              <h2 className="text-l font-semibold mb-2">{plan?.description}</h2>
            </div>
          </div>
          <div className="flex mb-4 justify-between items-center">
            <h2 className="text-2xl font-semibold">Modules</h2>
          </div>
          <WithPaginator
            data={pageData}
            initialPageSize={10}
            onPageMetaChange={onPageMetaChange}
            totalItems={plan?.modules?.length || 0}
          >
            {pagedData => (
              <DataTable
                data={pagedData}
                headers={[
                  { label: 'ID', key: 'id' },
                  { label: 'Name', key: 'name' },
                ]}
              >
                {data =>
                  data.map((item, idx) => (
                    <tr key={idx} className="hover:bg-gray-100">
                      <td className="py-2 px-4 border-b text-start">
                        {item.id}
                      </td>
                      <td className="py-2 px-4 border-b text-start">
                        {item.name}
                      </td>
                    </tr>
                  ))
                }
              </DataTable>
            )}
          </WithPaginator>
        </div>
      </div>
    </>
  )
}
