import React, {
  useRef,
  useMemo,
  useState,
  useCallback,
  createContext,
} from 'react'

import {
  IPlan,
  IPlanResponse,
  IStartedProgress,
  ICreateCoursePayload,
  IStartedItemsResponse,
} from '@/types'
import {
  useMyCourses,
  useGetCourses,
  useCreateCourse,
  useUpdateCourse,
} from '@/apis'
import { ContentService, IContentService } from '@/services'

interface IH5PContext {
  courses: IPlan[]
  course: IPlan | null
  courseLoading: boolean
  getCourses: () => void
  getMyCourses: () => void
  myCourses: IStartedProgress[]
  contentService: IContentService
  onUpdateCourse: (
    _payload: ICreateCoursePayload,
    _bool: boolean,
  ) => Promise<void>
}

export const H5PContext = createContext<IH5PContext>({
  courses: [],
  course: null,
  myCourses: [],
  courseLoading: false,
  getCourses: () => undefined,
  getMyCourses: () => undefined,
  onUpdateCourse: async () => undefined,
  contentService: new ContentService(),
})

export const H5PContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [courses, setCourses] = useState<IPlan[]>([])
  const contentService = useRef(new ContentService()).current
  const [openedCourse, setOpenedCourse] = useState<IPlan | null>(null)
  const [boughtCourses, setBoughtCourses] = useState<IStartedProgress[]>([])

  const onCoursesSuccess = useCallback((_data: IPlanResponse) => {
    setCourses(_data.data)
  }, [])

  const onMyCoursesSuccess = useCallback((_data: IStartedItemsResponse) => {
    setBoughtCourses(_data.data)
  }, [])

  const [getCourses, { isLoading }] = useGetCourses(onCoursesSuccess)
  const [getBoughtCourses] = useMyCourses(onMyCoursesSuccess)
  const { mutateAsync: createCourse } = useCreateCourse()
  const { mutateAsync: updateCourse } = useUpdateCourse()

  const onUpdateCourse = useCallback(
    async (_payload: ICreateCoursePayload, isCreating: boolean) => {
      const request = isCreating ? createCourse : updateCourse
      const response = await request(_payload)
      if (response.data.success) {
        getCourses()
      }
      setOpenedCourse(null)
    },
    [createCourse, getCourses, updateCourse],
  )

  const getMyCourses = useCallback(() => {
    getBoughtCourses()
  }, [getBoughtCourses])

  const value = useMemo(
    () => ({
      courses,
      getCourses,
      getMyCourses,
      contentService,
      onUpdateCourse,
      course: openedCourse,
      courseLoading: isLoading,
      myCourses: boughtCourses,
    }),
    [
      courses,
      getCourses,
      getMyCourses,
      contentService,
      onUpdateCourse,
      openedCourse,
      isLoading,
      boughtCourses,
    ],
  )

  return <H5PContext.Provider value={value}>{children}</H5PContext.Provider>
}
