import React, { useCallback, useEffect, useState } from 'react'
import { useMemo } from 'react'

import { usePagination } from '@/hooks'

import PageJumpButton from './pageJumpButton'
import { IPaginatorProps } from './types'
import PageButtons from './pageButtons'

export const Paginator: React.FC<IPaginatorProps> = ({
  currentPage = 1,
  disabled = false,
  pageSize = 10,
  renderOnOnePageCount = false,
  siblingCount = 2,
  totalItems,
  nextPageButton,
  prevPageButton,
  onPageChange,
}) => {
  const [perPage, setPerPage] = useState(1)

  useEffect(() => setPerPage(pageSize), [pageSize])

  const paginationRange = usePagination({
    currentPage,
    totalItems,
    siblingCount,
    pageSize: perPage,
  })

  const totalPages = useMemo(
    () => Math.ceil(totalItems / (perPage || 1)),
    [perPage, totalItems],
  )

  const lastPage = useMemo(
    () =>
      paginationRange.length > 1
        ? paginationRange[paginationRange.length - 1]
        : totalPages,
    [paginationRange, totalPages],
  )
  const onNext = useCallback(() => {
    if (onPageChange) onPageChange(Math.min(currentPage + 1, totalPages))
  }, [currentPage, onPageChange, totalPages])

  const onPrevious = useCallback(() => {
    if (onPageChange) onPageChange(Math.max(currentPage - 1, 1))
  }, [currentPage, onPageChange])

  if (!paginationRange.length) return null
  if (paginationRange.length < 2 && !renderOnOnePageCount) return null

  return (
    <nav className="flex items-center justify-between">
      <PageJumpButton
        toNext={false}
        onClick={onPrevious}
        defaultBtn={prevPageButton}
        disabled={currentPage === 1 || disabled}
      />
      <div className="flex space-x-2">
        <PageButtons
          disabled={disabled}
          range={paginationRange}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      </div>
      <PageJumpButton
        toNext
        onClick={onNext}
        defaultBtn={nextPageButton}
        disabled={currentPage === lastPage || disabled}
      />
    </nav>
  )
}
