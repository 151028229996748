import React from 'react'
import classNames from 'classnames'

import { getPaymentTypeName } from '@/utils'
import { IPayment } from '@/types'
import moment from 'moment'

export const PaymentRow: React.FC<IPayment & { index: number }> = ({
  user,
  type,
  index,
  amount,
  charged,
  product,
  createdAt,
}) => {
  return (
    <tr className="cursor-pointer hover:bg-gray-100">
      <td className="py-2 px-4 border-b text-start">{index}</td>
      <td className="py-2 px-4 border-b text-start">{user.name}</td>
      <td className="py-2 px-4 border-b text-start">
        {getPaymentTypeName(type)}
      </td>
      <td className="py-2 px-4 border-b text-start">{product._id}</td>
      <td className="py-2 px-4 border-b text-start">${amount}</td>
      <td className="py-2 px-4 border-b text-start">
        <span
          className={classNames(
            'capitalize inline-block text-white text-sm px-3 py-1 rounded-full capitalize',
            {
              'bg-red-500': !charged,
              'bg-green-500': charged,
            },
          )}
        >
          {charged ? 'Paid' : 'Not paid'}
        </span>
      </td>
      <td className="py-2 px-4 border-b text-start">
        {moment(createdAt).fromNow()}
      </td>
    </tr>
  )
}
