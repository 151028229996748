import React, { useMemo } from 'react'

import { SectionTypes } from '@/types'
import { useBrand } from '@/hooks'
import { getFile } from '@/utils'

import { whatWeOfferPage } from './constants'

export const DefaultWhatWeOfferSection: React.FC = () => {
  const { template } = useBrand()

  const whatWeOffer = useMemo(
    () => template?.sections.find(_v => _v.type === SectionTypes.WHAT_WE_OFFER),
    [template?.sections],
  )

  const { title, description, blocks } = useMemo(
    () => ({
      title: whatWeOffer?.title ?? whatWeOfferPage.title,
      blocks: whatWeOffer?.blocks
        ? whatWeOffer.blocks.map(_v => ({ ..._v, image: getFile(_v.image) }))
        : whatWeOfferPage.blocks,
      description: whatWeOffer?.description ?? whatWeOfferPage.description,
    }),
    [whatWeOffer],
  )

  return (
    <section className="unlock-learn">
      <div className="w-full container mx-auto px-4 py-12">
        <div className="mb-12">
          <span className="inline-block bg-[#EAF0F5] text-[#193450] text-[16px] font-bold px-3 py-1 rounded-full">
            What we offer
          </span>
          <h1 className="mt-4 w-full text-[48px] w-[768px] md:text-4xl font-bold text-[#161C24]">
            {title}
          </h1>
          <p className="mt-4 text-gray-600 max-w-2xl">{description}</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {blocks.map((block, idx) => (
            <div key={idx} className="bg-white rounded-lg overflow-hidden">
              <img
                src={block.image}
                alt={block.title}
                className="w-full h-48 object-cover"
              />
              <div className="pt-6">
                <h2 className="text-lg font-bold text-gray-900">
                  {block.title}
                </h2>
                <p className="mt-2 text-gray-600">{block.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
